import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListSubheader from '@mui/material/ListSubheader';

export default function Stepper() {
  return (
    <List
      sx={{
        width: '100%',
        maxWidth: 500,
        bgcolor: 'background.paper',
        position: 'relative',
        overflow: 'auto',
        maxHeight: 500,
        '& ul': { padding: 0 },
    
      }}
      subheader={<li />}
    >
      {[0, 1, 2, 3, 4].map((sectionId) => (
        <li key={`section-${sectionId}`}>
          <ul>
            <ListSubheader>{`I'm sticky ${sectionId}`}</ListSubheader>
           
              <ListItem>
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Cupiditate iure quos repellendus accusantium, eius quasi nesciunt excepturi rem minus obcaecati harum blanditiis quae magni natus praesentium vel! Commodi possimus provident voluptatum atque modi cum corrupti dolorum excepturi cumque incidunt aliquid nemo, nesciunt dolore maxime voluptas, eligendi velit impedit maiores odit quasi nihil laudantium. Minima sint exercitationem atque animi illum quisquam rerum perferendis nulla doloremque quaerat fuga, eius possimus laboriosam dolor nesciunt debitis recusandae, officia libero, ratione fugiat nisi. Et perspiciatis inventore aperiam, numquam impedit hic, quidem, magni itaque earum voluptatem soluta similique iste dolorum animi sed delectus alias adipisci. Reiciendis pariatur id harum tempora minima commodi esse, asperiores impedit at doloribus amet nemo! Perspiciatis recusandae totam numquam odio rem, qui doloribus tempora eos esse eaque distinctio! Inventore fuga molestiae nemo soluta nam incidunt unde laboriosam fugiat ducimus. Quaerat, labore. Eveniet placeat quasi alias modi maiores est sunt facilis, assumenda debitis?
              </ListItem>
          
          </ul>
        </li>
      ))}
    </List>
  );
}