import React from 'react'
import GetProgress from '../sections/@dashboard/Progress/GetProgress'

function Progress() {
  return (
    <div className='post_product-con'>
      <GetProgress/>
    </div>
  )
}

export default Progress 