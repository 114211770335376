/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import { Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Typography, IconButton,Button} from '@mui/material';
import { Search as SearchIcon, ArrowBack as ArrowBackIcon, ArrowForward as ArrowForwardIcon } from '@mui/icons-material';
import axios from 'axios';
import baseUrl from '../../../../BaseURL';
import { ContextData } from '../../../../Context/Context';
import ReportDialog from '../Progress/ReportDialog';
import ReadyForCertificate from './ReadyForCertificate';
import AlertPOP from '../../../../AlertPOP';


const GetApprovedStudent = () => {
  
  const [searchText, setSearchText] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [StudentData,setStudentData] = useState(null)
  const [CountData,setCountData] = useState(0)
  const [Update,setUpdate] = useState(true)
  const rowsPerPage = 10;
  const {setLoader,AdministratorTokens} = useContext(ContextData)




  useEffect(()=>{
      

      async function FetchData(){

      
                setLoader(true)  

                // Get all progress data

            try {
              const response = await axios.get(`${baseUrl}/auth/get_all_given_certificates`,{
                withCredentials: true,
                headers: {
                  'Accept' : 'application/json',
                  'authorization': `Bearer ${AdministratorTokens !== null && AdministratorTokens}`
                }}) 
              setCountData(Object.keys(response.data))
              setStudentData(response.data)
              setLoader(false)
             
            } catch (err) {
               if(err) console.log(err.message)
               setLoader(false)
            }
 
      }FetchData()


  },[Update])


  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };
  
     const List =  Object.keys(StudentData !== null && StudentData).map(data=> StudentData[data])
     const filteredUsers = List.filter(user => {
     const lowerCaseSearchText = searchText.toLowerCase().trim();
    return (
     
      user.email.toLowerCase().includes(lowerCaseSearchText) ||
      user.productName.toLowerCase().includes(lowerCaseSearchText) ||
      user.ProductType.toLowerCase().includes(lowerCaseSearchText)  ||
      user.program_duration.toLowerCase().includes(lowerCaseSearchText) ||
      user.startDate.toLowerCase().includes(lowerCaseSearchText)
      // Add more fields for search if needed (e.g., state, phone number)
    );
  });
  
  
  

  const totalPages = Math.ceil(filteredUsers.length / rowsPerPage);
  const indexOfLastUser = currentPage * rowsPerPage;
  const indexOfFirstUser = indexOfLastUser - rowsPerPage;
  const usersToShow = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

  const handleNextPage = () => {
    setCurrentPage(prevPage => prevPage + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage(prevPage => prevPage - 1);
  };
  const [message,setmessage] = React.useState("")
  const [OpenAlert, setOpenAlert] = React.useState(false);
  const [transition, setTransition] = React.useState(undefined);


  // Handle the alert transition and function

  const handleClick = (Transition) => {
   setTransition(() => "TransitionLeft")
    setOpenAlert(true);
  };
   // Handle the close alert box
  const handleClose = () => {
    setOpenAlert(false);
  };


  // Handle Approve Completion button

  const HandleApproveCompletion = (a,b) =>{

    handleClick("left")
  

         
     const data = {
        productToken: b,
        st_token: a,
        value: "2"
      } 
      
     axios.put(`${baseUrl}/auth/approved_completion`,data,{
      withCredentials: true,
      headers: {
        'Accept' : 'application/json',
         'authorization': `Bearer ${AdministratorTokens !== null && AdministratorTokens}`
      },
     }).then(res=>{

           if(res.data.status){

            setmessage(res.data.textStatus)

           }
    
        
     }).catch(err=>{

           if(err) console.log(err)
           setmessage(err.message)
          
     })
    


  }



  return (
    <Container>
  
   <div style={{position:"absolute"}}>
      <AlertPOP
       Open={OpenAlert}
       handleClose={handleClose}
       transition={transition}
       message={message}
      />
    </div>
      <div style={{display:"flex",flexDirection:"row", justifyContent:"space-between"}} className=''>
      <Typography variant="h4" gutterBottom>
        Certified List
      </Typography>

         <ReadyForCertificate/>

      </div>

      <TextField
        label="Search"
        variant="outlined"
        value={searchText}
        onChange={handleSearchChange}
        InputProps={{
          endAdornment: (
            <IconButton>
              <SearchIcon />
            </IconButton>
          ),
        }}
        fullWidth
        margin="normal"
      />
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>

              <TableCell>Email</TableCell>
              <TableCell>Product Name</TableCell>
              <TableCell>Product Type</TableCell>
              <TableCell>Duration</TableCell>
              <TableCell>Start Date </TableCell>
              <TableCell>Progress Report</TableCell>
              <TableCell>Validate</TableCell>

            </TableRow>
          </TableHead>
          <TableBody>
            {usersToShow.map((user) => (
              <TableRow className={user.click === 0 ? 'newpay' : ""} key={user.id}>
                
                <TableCell className={user.click === 0 ? 'newpaycolor' : ""}>{user.email}</TableCell>
                <TableCell className={user.click === 0 ? 'newpaycolor' : ""}>{user.productName}</TableCell>
                <TableCell className={user.click === 0 ? 'newpaycolor' : ""}>{user.ProductType}</TableCell>
                <TableCell className={user.click === 0 ? 'newpaycolor' : ""}>{user.program_duration} week{user.program_duration !== "1" ? "s" : ""}</TableCell>
                <TableCell className={user.click === 0 ? 'newpaycolor' : ""}>{user.startDate}</TableCell>
                <TableCell>
                <ReportDialog
                 data={user}
                />
                 </TableCell>

                 <TableCell>
                 <Button variant="outlined" sx={{background:`linear-gradient(20deg,rgb(8, 3, 90),rgb(14, 54, 13),rgb(38, 21, 54))` , color:"white"}} onClick={()=>HandleApproveCompletion(user.st_token,user.product_token)}>
                       Validate
                      </Button>
                 </TableCell>

              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {totalPages > 1 && (

        <div>
          {currentPage > 1 && (
            <IconButton onClick={handlePrevPage}>
              <ArrowBackIcon />
            </IconButton>
          )}
          <span>Page {currentPage} of {totalPages}</span>
          {currentPage < totalPages && (
            <IconButton onClick={handleNextPage}>
              <ArrowForwardIcon />
            </IconButton>
          )}
        </div>

      )}

    </Container>
  );
};

export default GetApprovedStudent;
