import PropTypes from 'prop-types';
// @mui
import { Link } from 'react-router-dom';
import { Box, Card, Typography, Stack,Button } from '@mui/material';
import { styled } from '@mui/material/styles';
// components
import Label from '../../../components/label';
import baseUrl from '../../../BaseURL';


// ----------------------------------------------------------------------

const StyledProductImg = styled('img')({
  top: 10,
  width: '100%',
  height: '100%',
  objectFit: 'contain',
  position: 'absolute',
  padding: 20
});

// ----------------------------------------------------------------------

ProgramCard.propTypes = {
  product: PropTypes.object,
};

export default function ProgramCard({ product }) {
  const { status } = product;

  return (
    <Card elevation={12} sx={{boxShadow:'3px 2px 10px rgba(0, 10, 53,0.6)'}}>
      <Box sx={{ pt: '100%', position: 'relative' }}>
        {status && (
          <Label
            variant="filled"
            color={(status === 'sale' && 'error') || 'info'}
            sx={{
              zIndex: 9,
              top: 16,
              right: 16,
              position: 'absolute',
              textTransform: 'uppercase',
            }}
          >
            {status}
          </Label>

        )}
        <StyledProductImg alt={product.product_name} src={`${baseUrl}/product_image/assets/ProductImages/${product.product_picture}`} />
      </Box>

      <Stack spacing={2} sx={{ p: 3 }}>
        <Link sx={{color:'rgb(92, 12, 12)'}} to={`/dashboard/course_page_displayer/${product.product_token}/${product.product_name}/${product.product_location}`} color="inherit" underline="hover">
          <Typography sx={{color:'rgb(92, 12, 12)'}} variant="subtitle2" noWrap>
            Learn <span style={{color:"rgb(92, 12, 12)"}}>{product.product_name}</span>
          </Typography>
        </Link>
        
   
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Link to={`/dashboard/course_page_displayer/${product.product_token}/${product.product_name}/${product.product_location}`}>
        <Button className='Hover-elemnt' elevation={10} sx={{whiteSpace: 'nowrap' , fontSize: '10px', backgroundColor:'rgb(92, 12, 12)'}}  size="large" variant="contained" endIcon={''}>
          Enroll Now
        </Button>
          </Link>
    

            
        </Stack>
      </Stack>
    </Card>
  );
}
