/* eslint-disable react/prop-types */
import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';



const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {


  const { children, onClose, ...other } = props;


  
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function MoreInfo(props) {

  const [open, setOpen] = React.useState(false)
  const {comstatus,duration,uploaddate,endAt} = props

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className=''>

       
        <Button onClick={handleClickOpen} autoFocus style={{color:"white",whiteSpace:"nowrap",marginBottom:"10px",backgroundColor:"rgb(12, 12, 38)"}} >
        Details <span style={{marginLeft:"3px"}}> &#9658;</span> 
          </Button>

        <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
          >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          Details
        </BootstrapDialogTitle>

        <div className='more-inner-con'>
         
         {
           comstatus !== null &&
         <p> 
            <span style={{whiteSpace:"nowrap"}}> <b>Status:</b> {comstatus === 0 ? <i>In progress...</i> : <i>Completed</i>}</span>
          </p>
         }
           
          {
            duration !== null &&
          <p>
       <span style={{whiteSpace:"nowrap"}}> <b>Duration:</b> <i>{duration}</i></span>
          </p>
          }
    
           {
             uploaddate !== null && 
           <p>
           <span style={{whiteSpace:"nowrap"}}><b>Start-Date:</b> <i>{uploaddate.slice(0,uploaddate.indexOf("T"))}</i></span>
           </p>
           }
          <p>
            {
              endAt !== null && 
          <span style={{whiteSpace:"nowrap"}}><b>End-Date:</b> {endAt !== null &&  <i>{endAt.slice(0,endAt.indexOf("T"))}</i>}</span>
            }
          </p>
        
        </div>

        <DialogActions>

          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>

        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}

