import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import axios from 'axios';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ContextData } from '../../Context/Context';
import baseUrl from '../../BaseURL';
import PreloaderFunc from '../../PreloaderFunc';

export default function Verify() {

  const {Person,Refresh,setRefresh,PersonKeys} = React.useContext(ContextData)
  const [open, setOpen] = React.useState(false);
  const [Code,setCode] = React.useState("")
  const [Err,setErr] = React.useState("")
  const [Msg,setMsg] = React.useState("")
  const [MuteBtn,setMuteBtn] = React.useState(false)
  const [Preload,setPreload] = React.useState(false)



 React.useEffect(()=>{


            if(PersonKeys !== null && Person !== null){


                  if(Person.varify !== 0) {
                    setOpen(false)
                  }else{
                      setOpen(true)
                  }
              

            }

  },[Person,PersonKeys])   


  const handleVarify = () => {
         setErr("")
         setMsg("")
         setPreload(true)
      if(Code.trim() === ""){
          setErr("Code input required")
          return
      }

       axios.put(`${baseUrl}/auth/VerifyAcct`,{
        code: Code,
        token: Person !== null && Person.st_tokens
      },
      {
           withCredentials: true,
            headers: {
              'Accept' : 'application/json',
             'authorization': `Bearer ${PersonKeys !== null && PersonKeys}`
            },
      
        }).then(res=>{
            setRefresh(!Refresh)

         if(!res.data.status){
            setErr(res.data.statusText)
            setPreload(false)
         } 
           
           if(res.data.status){
               setMsg(res.data.statusText)
               setPreload(false)
          } 


           
      }).catch(err=>{
          if(err) console.log(err.message)
          setErr(err.message)
          setPreload(false)
      })
         
    
  };


  const HandlrequstCode = () =>{
        
    setErr("")
    setMsg("")
  
      if(Person === null || PersonKeys === null){
          setErr("Refresh your browser and Login again please...")
          return
      }
      setMuteBtn(true)

      const timer = setTimeout(()=>{
           
           setMuteBtn(false)
           clearTimeout(timer)

      },70000)

       axios.put(`${baseUrl}/auth/re_request`,{token:Person.st_tokens,email:Person.email},
      {
           withCredentials: true,
            headers: {
              'Accept' : 'application/json',
             'authorization': `Bearer ${PersonKeys!==null&&PersonKeys}`
            },
      
        }).then(res=>{

            setRefresh(!Refresh)

         if(!res.data.status){
            setErr(res.data.statusText)
            
         } 
           
           if(res.data.status){
               setMsg(res.data.statusText)
          } 
           
      }).catch(err=>{
          if(err) console.log(err.message)
          setErr(err.message)
      })
         




  }






       return (
        
         <div> 

            {
              Person !== null ? 
           <Dialog
             open={open}
             aria-labelledby="alert-dialog-title"
             aria-describedby="alert-dialog-description"
           >
             <DialogTitle id="alert-dialog-title">
               {"Welcome to MastermindTech"}
             </DialogTitle>
             <DialogContent>
               <DialogContentText id="alert-dialog-description">
                 Verify your account
                 <div className='verify-con-input'>
                   <input
                   
                    value={Code}
                    onChange={e=>setCode(e.target.value)}
                    className='verify-input'
                   />
                 {
                  Err !== "" && <span style={{color:"red"}}>{Err !== "" && Err}</span>
                 }
                 {
                  Msg !== "" &&  <span style={{color:"green"}}>{Msg !== "" && Msg}</span>
                 }
                 </div>
                 <sub>A verification code has been send to the provided email during sign up</sub>
                  {
                    MuteBtn && <p>You can request another code after 4min</p>
                  }
               </DialogContentText>
             </DialogContent>
             <DialogActions>

             <Button
              onClick={HandlrequstCode}
              disabled={MuteBtn}
              >
                 re-send-code
               </Button>
                
                   {
                    Preload ?  <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}><PreloaderFunc/></div>
                    : 
                    <Button onClick={handleVarify}>
                      Verify
                    </Button> 
                  }
                

             </DialogActions>
           </Dialog>  : " "
            }
            

         </div>
       );

}