import * as React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import QuizIcon from '@mui/icons-material/Quiz';

export default function InnerTogglerHeader2() {
  const [alignment, setAlignment] = React.useState('web');

  const handleChange = (newAlignment) => {
    setAlignment(newAlignment);
  };

  return (
    <ToggleButtonGroup
      color="primary"
      value={alignment}
      exclusive
      onChange={handleChange}
      aria-label="Platform"
    >
      <ToggleButton sx={{background: `linear-gradient(30deg,rgb(54, 1, 61),
    rgb(0, 10, 53))`,color:'white'}} value="web"><SkipPreviousIcon/>Prev</ToggleButton>
      <ToggleButton value="android">carry out trials on <QuizIcon style={{marginLeft:'4px'}}/> </ToggleButton>
      <ToggleButton sx={{background: `linear-gradient(30deg,rgb(54, 1, 61),
    rgb(0, 10, 53))`,color:'white'}} value="ios">Next<SkipNextIcon/></ToggleButton>
    </ToggleButtonGroup>
  );
} 