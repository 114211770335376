import React, { useState,useContext } from "react";
import {
  TextField,
  Button,
  FormControl,
  FormHelperText,
} from "@mui/material";
import Confetti from 'react-confetti'
import axios from "axios";
import validator from "validator";
import { ContextData } from "../../Context/Context";
import baseUrl from "../../BaseURL";
import WorkshopAlert from "./WorkshopAlert";



const SubmitForm = () => {

  const [fname, setfname] = useState("");
  const [lname, setlname] = useState("");
  const [email, setemail] = useState("");
  const [mobile, setmobile] = useState("");
  const [fieldofprofession, setfieldofprofession] = useState("");
  const [age, setage] = useState("");
  const [Confit,setConfit] = useState(false)
  const [Error,setError] = useState("")
  const [Loading,setLoading] = useState(false)
  const [attendanceid,setattendanceid] = useState("")





  const {AdministratorTokens,reloadworkshop,setreloadworkshop,setOpenWorkshopAlert}= useContext(ContextData)
  
  
  const handleSubmit = (event) => {
    event.preventDefault();
    setError("")
    // Validate the inputs before submitting
    if (
      fname.trim() === "" ||
      lname.trim() === "" ||
      email.trim() === "" ||
      mobile.trim() === "" ||
      fieldofprofession.trim() === "" ||
      age.trim() === "") {
      alert("Please fill in all the fields.");
      return;
    }

    if(!validator.isEmail(email)){
      setError("Is not an email")
      return
    } 
    if(!validator.isNumeric(mobile)){
      setError("Is not a number")
      return
    } 
    if(!validator.isNumeric(age)){
      setError("Age must be a numeric")
      return
    }
    setLoading(true)
    // Form submission logic here
    // You can send the form data to your backend or perform any other action
      
       const data = {

        fname,
        lname,
        email,
        mobile,
        fieldofprofession,
        age,
        institute: "KASU",
        topic: "Software"

       }
        
     axios.post(`${baseUrl}/auth/registerstudent_workshop`,{...data},{
      withCredentials: true,
      headers: {
        'Accept' : 'application/json',
        'authorization': `Bearer ${AdministratorTokens!==null&&AdministratorTokens}`
      },
     }).then(res=>{

     
       if(res.data.status){
        setError("")
        setConfit(true)
        setLoading(false)
        setOpenWorkshopAlert(true)
        setattendanceid(res.data.attendance_id)
         // Rest the confit after loaded
        const timer = setTimeout(()=>{
          setConfit(false)
          clearTimeout(timer)
        },15000)


        setfname("")
        setlname("")
        setemail("")
        setmobile("")
        setfieldofprofession("")
        setage("")
        setreloadworkshop(!reloadworkshop)
       }


       if(!res.data.status){
        setError(res.data.statusText)   
        setLoading(false) 
     }

  
     }).catch(err=>{

           if(err) console.log(err)
            setError(err.message)
           setLoading(false)
           setConfit(false)
     })



  };




  return (
   
        <form onSubmit={handleSubmit}>
         
             {
              Error !== ""  && 
              <p style={{textAlign:'center',color:"red",fontWeight:"800"}}>{Error}</p>
            
             }
              {
                Confit && 
             <Confetti
            numberOfPieces={500}
            gravity={0.2}
            
             /> 
              }
              {
               <WorkshopAlert
               attendanceid={attendanceid}
               />
              }
          <FormControl fullWidth margin="normal" error={fname.trim() === ""}>
            <TextField
              label="Fname"
              value={fname}
              onChange={(e) => setfname(e.target.value)}
              required
            />
            {fname.trim() === "" && (
              <FormHelperText error>Please enter  First Name.</FormHelperText>
            )}
          </FormControl>


          <FormControl fullWidth margin="normal" error={lname.trim() === ""}>
            <TextField
              label="Lname"
              value={lname}
              onChange={(e) => setlname(e.target.value)}
              required
            />
            {lname.trim() === "" && (
              <FormHelperText error>Please enter Last Name.</FormHelperText>
            )}
          </FormControl>


          <FormControl fullWidth margin="normal" error={email.trim() === ""}>
            <TextField
              label="Email"
              value={email}
              onChange={(e) => setemail(e.target.value)}
              required
            />
            {email.trim() === "" && (
              <FormHelperText error>Please enter an email.</FormHelperText>
            )}
          </FormControl>


          <FormControl fullWidth margin="normal" error={mobile.trim() === ""}>
            <TextField
              label="mobile"
              value={mobile}
              onChange={(e) => setmobile(e.target.value)}
              required
            />
            {mobile.trim() === "" && (
              <FormHelperText error>Please enter a mobile number.</FormHelperText>
            )}
          </FormControl>


          <FormControl fullWidth margin="normal" error={fieldofprofession.trim() === ""}>
            <TextField
              label="Field of Profession"
              value={fieldofprofession}
              onChange={(e) => setfieldofprofession(e.target.value)}
              required
            />
            {fieldofprofession.trim() === "" && (
              <FormHelperText error>Please enter a Field of Profession</FormHelperText>
            )}
          </FormControl>


          <FormControl fullWidth margin="normal" error={age.trim() === ""}>
            <TextField
              label="Age"
              value={age}
              onChange={(e) => setage(e.target.value)}
              required
            />
            {age.trim() === "" && (
              <FormHelperText error>Please enter your age.</FormHelperText>
            )}
          </FormControl>


          
          <Button variant="contained" color="primary" type="submit">
            {Loading ? "Submitting..." : "Submit"}
          </Button>

        </form>
    
  );
};

export default SubmitForm;
