/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState,useContext,useEffect } from "react";
import {
  Box,
} from "@mui/material";
import axios from "axios";
import { ContextData } from "../../Context/Context";
import baseUrl from "../../BaseURL";
import SubmitForm from "./SubmitForm";


const RegisterFormWorkshop = () => {


  const {setLoader,reloadworkshop,AdministratorTokens}= useContext(ContextData)
  const [ProductDataList,setProductDataList] = useState(null)
  


  useEffect(()=>{
    setLoader(true) 
    async function GetData(){   
      try {
           const response = await  axios.get(`${baseUrl}/auth/getAllWorkshopAtt`,{
            withCredentials: true,
            headers: {
              'Accept' : 'application/json',
              'authorization': `Bearer ${AdministratorTokens !== null && AdministratorTokens}`
            }})
           setProductDataList(response.data)
         //  console.log(ProductDataList)
           setLoader(false)
        
      } catch (error) {
          console.log(error.message)
          setLoader(false)
      }
    }
    GetData()

},[reloadworkshop])

  return (
    <Box display="flex" className="inner-attendee-register-con">

      <Box className="inner-attendee-register-con-form"  height="100%" padding="1rem">
        <h1 style={{textAlign:"center"}}>Workshop Attendance</h1>
       <SubmitForm/>
      </Box>
      
      {/* Right section with accordion */}


      
    
 {/*      <Box className="inner-attendee-register-con-list"  sx={{overflowY:'auto'}} height="50rem" padding="1rem">
             <h2>List</h2>
            {
             
             ProductDataList !== null &&  Object.keys(ProductDataList).map((data)=>{
               //    console.log(ProductDataList) 
            return(
              <Acc
               data={ProductDataList[data]}
              />
            )
            })
           } 

      </Box>
 */}
   
    </Box>
  );
};

export default RegisterFormWorkshop;
