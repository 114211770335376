/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */

import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Container,
  TableContainer,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button
} from '@mui/material';
import { usePaystackPayment } from 'react-paystack';
import axios from 'axios';
import { ContextData } from '../../../../../Context/Context';
import baseUrl from '../../../../../BaseURL';

  
  const config = {
    publicKey: 'pk_test_4ab0279a9e039c8f59e41e45f185790ec48ae75a',
  }



const List =["Enroll","Program","Fee","Duration","Credit Hour","Total Credit Hours","Location"]

const MakePayment = () => {

    const [ProductData,setProductData] = useState(null)
    const {setLoader,PersonKeys} = useContext(ContextData)
    const [SelectedVenue,setSelectedVenue] = useState("")
    const [ProductDataList,setProductDataList] = useState({})
    const point = "00"
      
    const initializePayment = usePaystackPayment({...config,
      reference: (new Date()).getTime().toString(),
      email:"",
      amount: Number(point),
      firstname: "",
      lastname: '',
       });
  


  useEffect(()=>{
    setLoader(true) 
    async function GetData(){   
      try {
           const response = await  axios.get(`${baseUrl}/auth/get_all_posted_products/${""}`,{
            withCredentials: true,
             headers: {
              'Accept' : 'application/json',
              'Content-Type': 'application/json',
             'authorization': `Bearer ${PersonKeys.accessToken}`
            }
            })
           setProductDataList(response.data)
            console.log(ProductDataList)
           setLoader(false)
        
      } catch (error) {
          console.log(error.message)
          setLoader(false)
      }
    }
    GetData()

},[]) 

       const OnsubmitForm = ()=>{
          

            if(SelectedVenue.trim() === ""){

                   alert("empty")
            }
            
            // Excute Payment fuction

           // initializePayment(onSuccess,onClose)
            const onSuccess = (reference) => {
             // Implementation for whatever you want to do with reference and after success call.
             console.log('reference',reference);
             alert(`Your payment has been receive ${""}`)
           };
           
           const onClose = () => {
             // implementation for  whatever you want to do when the Paystack dialog closed.
             console.log('closed')
             
           }
       
         




       }





  return (
    <Container>
      <Box my={4}>
        <TableContainer component={Paper}>
          <div style={{height:'90vh',width:"100%",overflowY:'auto',display:'flex',justifyContent:'center',alignItems:'center'}}>

             <div className='tbl-box-con'>


               <div className='tbl-box'>
                <div className='tbl-box-hd'>Enrolling</div>
               </div>

               <div className='tbl-box'>
                <div className='tbl-box1'>Program</div><div className='tbl-box-2'>text</div>
               </div>
                 
               <div className='tbl-box'>
                <div style={{height:"4em",}} className='tbl-box1'>Fee</div>
                <div style={{flexDirection:"column",justifyContent:'flex-start',alignItems:'baseline'}} className='tbl-box-2'>

                  <span title='Attend the training in physical class at the center'><b>Physical Class:</b> 
                  <span style={{color:"red",fontWeight:"800"}}>30000NGN</span></span>
                  <span title='Attend the training in online from your comfort zone'><b>Online Class:</b>
                  <span style={{color:"red",fontWeight:"800"}}>30000NGN</span>
                  </span>
                  
                </div>
               </div>

               <div className='tbl-box'>
                <div className='tbl-box1'>Duration</div><div className='tbl-box-2'>text</div>
               </div>

               <div className='tbl-box'>
                <div className='tbl-box1' title='Credit Hour'>T.H</div><div className='tbl-box-2'>text</div>
               </div>

               <div className='tbl-box'>
                <div className='tbl-box1' title='Total Credit Hours'>T.C.H</div><div className='tbl-box-2'>text</div>
               </div>

              

               <div className='tbl-box'>
                <div className='tbl-box3'>
                <div className='tbl-box-bt'>
                 Select attending venue
                </div>
                 
                <div className='tbl-box-select'>

                <FormControl fullWidth variant="outlined"  sx={{width:'100%'}} required>
                <InputLabel>Choose</InputLabel>
                <Select
                value={SelectedVenue}
                onChange={(e) => setSelectedVenue(e.target.value)}
                label="Program"
                sx={{width:'25em',border:'none',outline:"none"}} 
                >
                <MenuItem value={"Physical Class"}>
                {"Physical Class"}
                </MenuItem>
                <MenuItem value={"Online Class"}>
                {"Online Class"}
                </MenuItem>

                </Select>
                </FormControl>
                </div>

                </div>
               </div>

               <div className='tbl-box'>
                <div className='tbl-box-bt'>
                <Button onClick={()=> OnsubmitForm()} type="submit" variant="contained" color="primary" fullWidth>
                    Make Payment <span style={{marginLeft:'0.4em',fontWeight:'800'}}>{2000} NGN</span>
                  </Button>
                </div>
               </div>


               
              
              


             </div>
            </div>
        </TableContainer>
      </Box>
      
    </Container>
  );
};

export default MakePayment;



