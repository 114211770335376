/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */

// @mui
import { Grid } from '@mui/material';
import ProgramCard from './ProgramCard';


// ----------------------------------------------------------------------

/* ProgramList.propTypes = {
  products: PropTypes.array.isRequired,
};
 */
export default function ProgramList({ data, ...other }) {
  return (
    <Grid container spacing={3} {...other}>
      {Object.keys(data).map((product) => (
        <Grid key={product} item xs={12} sm={6} md={3}>
      <ProgramCard product={data[product]} /> 
        </Grid>
      ))}
    </Grid>
  );
}
