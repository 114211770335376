/* eslint-disable react/prop-types */
import React from 'react';
import { Accordion, AccordionSummary, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import GetVideos from './GetVideos';

const VideoAccordion = (props) => {

   const {status} = props

  return (
    <Accordion style={{ marginTop: '20px' }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h5">{status} Video Modules</Typography>
      </AccordionSummary>
       {
         status === "English" &&
         <GetVideos
          status="English"
         />
       }

       {
         status === "Arabic" &&
         <GetVideos
          status="Arabic"
         />
       }
      {
         status === "Hausa" &&
         <GetVideos
          status="Hausa"
         />
       }

    </Accordion>
  ); 
};

export default VideoAccordion;
   