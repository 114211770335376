/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
// @mui
import { Box, List, ListItemText } from '@mui/material';
// component
import SvgColor from '../svg-color';
//
import { StyledNavItem, StyledNavItemIcon } from './styles';
import { ContextData } from '../../Context/Context';

// ----------------------------------------------------------------------

NavSection.propTypes = {
  data: PropTypes.array,
};

export default function NavSection({...other }) {

  const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;
  const {PersonKeys} = useContext(ContextData)

  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>

           {

           ( PersonKeys !== null && PersonKeys?.accessToken) && 
           <NavItem
           title="Home"
           path="/dashboard/userprofile"
           icon={icon('ic_analytics')}
           info={""}
           />
           }
          
             <NavItem 
             title={"Categories"}
             path={"/dashboard/categories"}
             icon={icon('ic_analytics')}
             info={""}
             />
             <NavItem
             title={"Programs"}
             path={"/dashboard/products"}
             icon={icon('ic_cart')}
             info={""}
             />
            
        </List>
    </Box>
  );
}

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
};

function NavItem(props) {
  const { title, path, icon, info } = props;
 

  return (
    <StyledNavItem
      component={RouterLink}
      to={path}
      sx={{
        '&.active': {
          color: 'white',
          bgcolor: `rgb(54, 1, 61)`,
          fontWeight: 'fontWeightBold',
        },
      }}
    >
      <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>

      <ListItemText disableTypography primary={title} />

      {info && info}
    </StyledNavItem>
  );
}
