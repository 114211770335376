/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import * as React from 'react';
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';
import Stack from '@mui/material/Stack';
import { ContextData } from '../../../Context/Context';

export default function Alerts() {

  const {signUp,setsignUp} = React.useContext(ContextData)

  React.useEffect(()=>{

    const timer =  setTimeout(()=>{
     setsignUp(false)
     clearTimeout(timer)
    },15000)
     
 },[signUp])


  return (
    <div className='signup-alert-con'> 
    <Stack className='inner-signup-alert-con' sx={{ width: '50%' }} spacing={2}>
      <Alert className='inner2-signup-alert-con' icon={<CheckIcon fontSize="inherit" />} severity="success">
        Congratulations and welcome to MastermindTech! Please check your inbox for an email from us to verify your account. By doing so, you'll unlock exciting features, product updates, and special promotions. We can't wait to provide you with an exceptional tech experience as a valued member of our community. Thank you for choosing MastermindTech!
      </Alert>
    </Stack>
{/* 
      <Link style={{marginTop:'3em'}} to="/login">
      <Button className='Hover-elemnt' elevation={10} sx={{whiteSpace: 'nowrap' , fontSize: '10px',marginRight:'5px', backgroundColor:'rgb(92, 12, 12)'}}  size="small" variant="contained" endIcon={''}>
        Login
    </Button> 

      </Link> */}
    

    </div>
  );
}