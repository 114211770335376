/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import {  Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Typography, IconButton,Button} from '@mui/material';
import { Search as SearchIcon, ArrowBack as ArrowBackIcon, ArrowForward as ArrowForwardIcon } from '@mui/icons-material';
import axios from 'axios';
import baseUrl from '../../BaseURL';
import { ContextData } from '../../Context/Context';


const Payment = () => {
  const [searchText, setSearchText] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [StudentData,setStudentData] = useState(null)
  const [CountData,setCountData] = useState(0)
  const rowsPerPage = 10;
   const {setLoader,AdministratorTokens} = useContext(ContextData)

  useEffect(()=>{
      

      async function FetchData(){
                setLoader(true)  

            try {
              const response = await axios.get(`${baseUrl}/auth/getrecordpayment`,{
                withCredentials: true,
                headers: {
                  'Accept' : 'application/json',
                  'authorization': `Bearer ${AdministratorTokens !== null && AdministratorTokens}`
                }}) 
              setCountData(Object.keys(response.data))
              setStudentData(response.data)
              setLoader(false)
             
            } catch (err) {
               if(err) console.log(err.message)
               setLoader(false)
            }

           
      }FetchData()


  },[])

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };
  
     const List =  Object.keys(StudentData !== null && StudentData).map(data=> StudentData[data])
  const filteredUsers = List.filter(user => {
    const lowerCaseSearchText = searchText.toLowerCase().trim();
    return (
     
       user.productName.toLowerCase().includes(lowerCaseSearchText)  ||
      user.paymentReferenceToken.toLowerCase().includes(lowerCaseSearchText) ||
      user.paymentAmount.toLowerCase().includes(lowerCaseSearchText) ||
      user.stEmail.toLowerCase().includes(lowerCaseSearchText) ||
      user.typeProduct.toLowerCase().includes(lowerCaseSearchText)  ||
      user.paymentAmount.toLowerCase().includes(lowerCaseSearchText) ||
      user.inserted_date.toLowerCase().includes(lowerCaseSearchText) ||
      user.inserted_time.toLowerCase().includes(lowerCaseSearchText) 
      
      // Add more fields for search if needed (e.g., state, phone number)
    );
  });
  


  const totalPages = Math.ceil(filteredUsers.length / rowsPerPage);
  const indexOfLastUser = currentPage * rowsPerPage;
  const indexOfFirstUser = indexOfLastUser - rowsPerPage;
  const usersToShow = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

  const handleNextPage = () => {
    setCurrentPage(prevPage => prevPage + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage(prevPage => prevPage - 1);
  };


  const HandleClick = async(click,token) =>{


    try {
      const response = await axios.put(`${baseUrl}/auth/update_click_getrecordpayment`,{click,token},{
        withCredentials: true,
        headers: {
          'Accept' : 'application/json',
          'authorization': `Bearer ${AdministratorTokens !== null && AdministratorTokens}`
        }})  
    } catch (err) {
       if(err) console.log(err.message)
       setLoader(false)
    }

            
  }


  return (
    <Container>
      <Typography variant="h4" gutterBottom>
         Payments
      </Typography>
      <TextField
        label="Search"
        variant="outlined"
        value={searchText}
        onChange={handleSearchChange}
        InputProps={{
          endAdornment: (
            <IconButton>
              <SearchIcon />
            </IconButton>
          ),
        }}
        fullWidth
        margin="normal"
      />
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell>Product Name</TableCell>
              <TableCell>Product Type</TableCell>
              <TableCell>Amount: NGN</TableCell>
              <TableCell>Pymnt Reference ID</TableCell>
              <TableCell>Time</TableCell>
              <TableCell>Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {usersToShow.map((user) => (
              <TableRow onClick={()=>HandleClick(1,user.paymentTableToken)} className={user.click === "0" ? 'newpay' : ""} key={user.id}>
                
                <TableCell className={user.click === 0 ? 'newpaycolor' : ""}>{user.stEmail}</TableCell>
                <TableCell className={user.click === 0 ? 'newpaycolor' : ""}>{user.productName}</TableCell>
                <TableCell className={user.click === 0 ? 'newpaycolor' : ""}>{user.typeProduct}</TableCell>
                <TableCell className={user.click === 0 ? 'newpaycolor' : ""}>{user.paymentAmount}</TableCell>
                <TableCell className={user.click === 0 ? 'newpaycolor' : ""}>{user.paymentReferenceToken}</TableCell>
                <TableCell className={user.click === 0 ? 'newpaycolor' : ""}>{user.inserted_time}</TableCell>
                <TableCell className={user.click === 0 ? 'newpaycolor' : ""}>{user.inserted_date}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {totalPages > 1 && (
        <div>
          {currentPage > 1 && (
            <IconButton onClick={handlePrevPage}>
              <ArrowBackIcon />
            </IconButton>
          )}
          <span>Page {currentPage} of {totalPages}</span>
          {currentPage < totalPages && (
            <IconButton onClick={handleNextPage}>
              <ArrowForwardIcon />
            </IconButton>
          )}
        </div>
      )}
    </Container>
  );
};

export default Payment;
