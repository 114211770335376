import * as React from 'react';


export default function ComfirmPay() {

    // status type
    // error
    // warning
    // info
    // success

    
  return (
    <div className='comfirm-alert-con'>
         <p><i><b>we would like to assure you that your payment has been processed successfully.</b></i></p>
       <p>Please keep an eye on your email inbox and phone for communication from our support team. They will provide you with all the necessary information and guidance to ensure a seamless and productive experience with our offerings.</p>

    </div>
  );
}