/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState,useContext } from "react";
import {
  TextField,
  Button,
  FormControl,
  FormHelperText,
} from "@mui/material";
import axios from "axios";
import AlertBox from "../../../../../layouts/dashboard/header/AlertBox";
import { ContextData } from "../../../../../../Context/Context";
import baseUrl from "../../../../../../BaseURL";



const PhysicalClass = (props) => {
  
  

  const [productToken, setproductToken] = useState(props.producttokens);
  const [duration, setduration] = useState("");
  const [creditHours, setcreditHours] = useState("");
  const [totalCreditHours, settotalCreditHours] = useState("")
  const [fee, setfee] = useState("");
 
 
  const {setLoader,AlertStatus,setAlertStatus,AdministratorTokens,ReloadData,setReloadData}= useContext(ContextData)
  
  
  const handleSubmit = (event) => {
    event.preventDefault();
    setAlertStatus(null)
    // Validate the inputs before submitting
    if (
      duration.trim() === "" ||
      creditHours.trim() === "" ||
      totalCreditHours.trim() === "" ||
      fee.trim() === "" 

    ) {
      alert("Please fill in all the fields.");
      return;
    }
    
    // Form submission logic here
    // You can send the form data to your backend or perform any other action
      setLoader(true)

        const data = {
          duration,
          creditHours,
          totalCreditHours,
          productToken,
          fee   
        }


     axios.put(`${baseUrl}/auth/physical_class`,{...data},{
      withCredentials: true,
      headers: {
        'Accept' : 'application/json',
        'authorization': `Bearer ${AdministratorTokens !== null && AdministratorTokens}`
      }}).then(res=>{

        // console.log(res.data)
          setAlertStatus({
          status:"success",
          textStatus: "Updated"
       })
       setReloadData(!ReloadData)
       setLoader(false)
     }).catch(err=>{

           if(err) console.log(err)
           setAlertStatus({
              status:"error",
              textStatus: err.message
           })
           setLoader(false)
     })
  };


  return (
   
        <form onSubmit={handleSubmit}>

             {
              AlertStatus !== null  && 
              <AlertBox 
               Status={AlertStatus.status}
               textStatus={AlertStatus.textStatus}
               />
              }
             
          
          <FormControl fullWidth margin="normal" error={duration.trim() === ""}>
            <TextField
              label="duration"
              value={duration}
              onChange={(e) => setduration(e.target.value)}
              required
            />
            {duration.trim() === "" && (
              <FormHelperText error>Please enter the Duration</FormHelperText>
            )}
          </FormControl>

          <FormControl fullWidth margin="normal" error={creditHours.trim() === ""}>
            <TextField
              label="creditHours"
              value={creditHours}
              onChange={(e) => setcreditHours(e.target.value)}
              required
            />
            {creditHours.trim() === "" && (
              <FormHelperText error>Please enter the Credit Hours</FormHelperText>
            )}
          </FormControl>

          <FormControl fullWidth margin="normal" error={totalCreditHours.trim() === ""}>
            <TextField
              label="totalCreditHours"
              value={totalCreditHours}
              onChange={(e) => settotalCreditHours(e.target.value)}
              required
            />
            {totalCreditHours.trim() === "" && (
              <FormHelperText error>Please enter the Total Credit Hours</FormHelperText>
            )}
          </FormControl>

          <FormControl fullWidth margin="normal" error={fee.trim() === ""}>
            <TextField
              label="fee"
              value={fee}
              onChange={(e) => setfee(e.target.value)}
              required
            />
            {fee.trim() === "" && (
              <FormHelperText error>Please enter the Fee</FormHelperText>
            )}
          </FormControl>

       
          
          <Button variant="contained" color="primary" type="submit">
            Add
          </Button>

        </form>
    
  );
};

export default PhysicalClass;
