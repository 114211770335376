/* eslint-disable react-hooks/exhaustive-deps */
import { Helmet } from 'react-helmet-async';
import { useState,useEffect,useContext } from 'react';
import axios from 'axios';

import { Container, Typography ,Stack} from '@mui/material';

// components
import { ProductSort, ProductList, ProductCartWidget } from '../sections/@dashboard/products';

import baseUrl from '../../BaseURL';
import { ContextData } from '../../Context/Context';

// ----------------------------------------------------------------------

export default function DashboardAppPage() {



  const [ProductDataList,setProductDataList] = useState(null)
  const {setLoader,AdministratorTokens,RefreshFun} = useContext(ContextData)



 useEffect(()=>{
   // setLoader(true) 
    async function GetData(){   
      try {
           const response = await  axios.get(`${baseUrl}/auth/get_all_posted_products`,{

              withCredentials: true,
              headers: {
                'Accept' : 'application/json',
                'authorization': `Bearer ${AdministratorTokens!==null&&AdministratorTokens}`
              }
           })
           setProductDataList(response.data)
         //  console.log(ProductDataList)
        //   setLoader(false)
        
      } catch (error) {
          console.log(error.message)
         // setLoader(false)
      }
    }
    GetData()

},[RefreshFun]) 

 



  return (
    <>
      
      <Helmet>
        <title> MastermindTech | Product Page </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
         MastermindTech posted products 
        </Typography>
       

        <Stack direction="row" flexWrap="wrap-reverse" alignItems="center" justifyContent="flex-end" sx={{ mb: 5 }}>
          <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
           {/*  <ProductFilterSidebar
               openFilter={openFilter}
              onOpenFilter={handleOpenFilter}
              onCloseFilter={handleCloseFilter}
            /> */}
            <ProductSort />
          </Stack>
        </Stack>

        <ProductList products={ProductDataList !== null && ProductDataList} />
        
        <ProductCartWidget />
  
      </Container>
    </>
  );
}
