/* eslint-disable react-hooks/exhaustive-deps */
import React,{useContext,useEffect} from 'react'
import MTCNav from './MTCNav'
import WelcomText from './WelcomText'
import InfoAccordion from './InfoAccordion'
import InfoPicCard from './InfoPicCard'
import { ContextData } from '../../../Context/Context'
import Loading from '../../../layouts/dashboard/header/Loading'
import Map from './Map'
import MapContact from './Contact'
import SlideTabs from './SlideTabs'
import Phrase from './Phrase'
import FooterNav from './FooterNav'

function OfflineIndex() {


   const {FadeLoad,setFedLoad} = useContext(ContextData)
   const d = new Date().getFullYear()
   useEffect(() =>{
    setFedLoad(true)    
    setTimeout(()=>{
     setFedLoad(false)    
    },5000)

  },[])

  return (
   

 <div>
     {
      FadeLoad &&
      <Loading/>
     }
     {/* Header Section */}
    <div className='mtc-header'>
    <div className='mtc-nav'>
       <MTCNav/>
    </div>
    </div>

<div className='main-con'>
<div className='inner-main-con'>

<div className='mmt-center-con'>

    {/* -----------End------------------- */}


     {/* --------------Welcome text ------------------- */}

    <div className='mtc-section-1'>
      <WelcomText/>
    </div>


    {/* ----------------End--------------------- */}


    {/* ----Section that contain all contents-------- */}
    <div className='mtc-section'>
   
         {/* Course Table Section */}
     <div className='mtc-sec-tabs-card-con'>
      
       <h3 style={{textAlign:'center',fontSize:'1.3em',textTransform:'uppercase'}}>"Empowering Innovators through Code: Your Journey Starts Here!"</h3>
        <div className='tabs-table-con'>
      {/*  <CourseTable/> */}
         <SlideTabs/>
        </div>
     </div>

     {/* -------End---------- */}


     {/* Phrase Section */}


      <div className='phrase-main-con'>
       <Phrase/>
      </div>


     {/* -------End------- */}


     {/* Info Pic Card Section */}

     <div className='mtc-sec-info-pic-con'>
        <div className='inner-info-pic-con'>
        <InfoPicCard/>
        </div>
    </div>
  
     {/* -----------End---------------- */}


    {/* Accordion Section */}

     <div className='mtc-sec-info-con'>
         <div className='mtc-inner-sec-info-con'>
         <InfoAccordion/>
         </div>
     </div>

     {/* -----------End------- */}


       
       {/* -------------Contact and Map Section */}

       <div className='map-sec-con'>
         <div className='inner-map-sec-con'>
          <MapContact/>
          <Map/>
         </div>
       </div>

     {/* --------------End--------------- */}

    
    </div>

    {/* ----------End----------- */}









</div>

</div>

    {/* Footer */}
<div className='mtc-footer'>
     <FooterNav/>

     <div className='footerATdate'>
          <span>Powered By MastermindTech.</span>
           <span> All Rights Reserved </span>
           <span style={{fontSize:'13px'}}>
             (UptoDate:{d})
           </span>
          
    </div>


    
</div>

    {/* -----end----- */}

</div>


 </div>


 
  )
}

export default OfflineIndex