import React from 'react'
import RegisterFormWorkshop from './PostProduct/RegisterFormWorkshop'


function RegisterAttendee() {
  return (
    <div className='attendee-register-con'>
      <RegisterFormWorkshop/>
    </div>
  )
}

export default RegisterAttendee