/* eslint-disable no-unused-vars */
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import CallIcon from '@mui/icons-material/Call';


export default function LetsHaveTalk() {
  // eslint-disable-next-line no-unused-vars
  const [dense, setDense] = React.useState(false);
  const [secondary, setSecondary] = React.useState(false);

  return (
    <Box sx={{ flexGrow: 1, maxWidth: 752 }}>
      
      <List dense={dense}>

                <ListItem>
                  <ListItemAvatar>
                    <Avatar sx={{backgroundColor:'green'}}>
                      <WhatsAppIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="+234 913 383 3261"
                    secondary={secondary ? 'Secondary text' : null}
                  />
                </ListItem>

                <ListItem>
                  <ListItemAvatar>
                    <Avatar sx={{background:`linear-gradient(20deg,rgb(8, 3, 90),rgb(14, 54, 13),rgb(38, 21, 54))`}}>
                      <AlternateEmailIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="info@mmt-ng.com"
                    secondary={secondary ? 'Secondary text' : null}
                  />
                </ListItem>
                
                <ListItem>
                  <ListItemAvatar>
                    <Avatar sx={{backgroundColor:'blue'}}>
                      <CallIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="+234 913 383 3261"
                    secondary={secondary ? 'Secondary text' : null}
                  />
                </ListItem>
            


            </List>
    </Box>
  )
}