/* eslint-disable react/prop-types */
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import ViewReport from './ViewReport';

const Transition = React.forwardRef( (props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function ReportDialog(props) {


  
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant="outlined" sx={{background:`linear-gradient(20deg,rgb(8, 3, 90),rgb(14, 54, 13),rgb(38, 21, 54))` , color:"white"}} onClick={handleClickOpen}>
        Report
      </Button>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative',background:`linear-gradient(20deg,rgb(8, 3, 90),rgb(14, 54, 13),rgb(38, 21, 54))` }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
             <CloseIcon />
            </IconButton>

            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              MastermindTech
            </Typography>


            <Button autoFocus color="inherit" onClick={handleClose}>
              Close
            </Button>

          </Toolbar>

        </AppBar>

         <h1 style={{textAlign:"center"}}>Progress Manifest</h1>
      
        <div style={{margin:"0px 5em",marginTop:"1em"}}>
           <ViewReport
            data={props.data}
           />
         </div>
       
      </Dialog>
    </div>
  );
}