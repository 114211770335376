/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import { Button, Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Typography, IconButton } from '@mui/material';
import { Search as SearchIcon, ArrowBack as ArrowBackIcon, ArrowForward as ArrowForwardIcon } from '@mui/icons-material';
import axios from 'axios';
import baseUrl from '../../BaseURL';
import { ContextData } from '../../Context/Context';



const WorkshopAccounts = () => {
  const [searchText, setSearchText] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [StudentData,setStudentData] = useState(null)
  const [CountData,setCountData] = useState(0)
  const rowsPerPage = 10;
   const {setLoader,AdministratorTokens} = useContext(ContextData)

  useEffect(()=>{
      

      async function FetchData(){
                setLoader(true)  

            try {
              const response = await axios.get(`${baseUrl}/auth/getAllWorkshopAtt`,{
                withCredentials: true,
                headers: {
                  'Accept' : 'application/json',
                  'authorization': `Bearer ${AdministratorTokens !== null && AdministratorTokens}`
                }}) 
              setCountData(Object.keys(response.data))
              setStudentData(response.data)
              setLoader(false)
             
            } catch (err) {
               if(err) console.log(err.message)
               setLoader(false)
            }

           
      }FetchData()


  },[])

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };
  


  const List =  Object.keys(StudentData !== null && StudentData).map(data=> StudentData[data])
  const filteredUsers = List.filter(user => {
    const lowerCaseSearchText = searchText.toLowerCase().trim();
    return (
     ( user.fname.toLowerCase().includes(lowerCaseSearchText) &&   user.lname.toLowerCase().includes(lowerCaseSearchText) ) ||
     user.institute.toLowerCase().includes(lowerCaseSearchText)  ||
     user.topic.toLowerCase().includes(lowerCaseSearchText)  ||
     user.attendance_id.toLowerCase().includes(lowerCaseSearchText)  ||
     user.field_of_profession.toLowerCase().includes(lowerCaseSearchText)  ||
     user.mobile.toLowerCase().includes(lowerCaseSearchText)  ||
     user.email.toLowerCase().includes(lowerCaseSearchText)  ||
     user.age.toLowerCase().includes(lowerCaseSearchText)
      // Add more fields for search if needed (e.g., state, phone number)
    );
  });

  const totalPages = Math.ceil(filteredUsers.length / rowsPerPage);
  const indexOfLastUser = currentPage * rowsPerPage;
  const indexOfFirstUser = indexOfLastUser - rowsPerPage;
  const usersToShow = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

  const handleNextPage = () => {
    setCurrentPage(prevPage => prevPage + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage(prevPage => prevPage - 1);
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Workshop Attendance Table
      </Typography>
      <Button className='Hover-elemnt' elevation={10} sx={{whiteSpace: 'nowrap' , fontSize: '14px', backgroundColor:'rgb(92, 12, 12)',margin:'9px 0px'}}  size="large" variant="contained">
                   SendMail to All
       </Button>
      <TextField
        label="Search"
        variant="outlined"
        value={searchText}
        onChange={handleSearchChange}
        InputProps={{
          endAdornment: (
            <IconButton>
              <SearchIcon />
            </IconButton>
          ),
        }}
        fullWidth
        margin="normal"
      />
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow style={{whiteSpace:'nowrap'}}>
             <TableCell>No</TableCell>
              <TableCell>Institute</TableCell>
              <TableCell>Topic</TableCell>
              <TableCell>Attendance ID</TableCell>
              <TableCell>Full Name</TableCell>
              <TableCell>Profession</TableCell>
              <TableCell>Mobile</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Age</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Inbox</TableCell>
              <TableCell>Edit</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {usersToShow.map((user,index) => (
              <TableRow key={user.id} style={{whiteSpace:'nowrap'}}>
                  <TableCell>{index +1}</TableCell>
                <TableCell>{user.institute}</TableCell>
                <TableCell>{user.topic}</TableCell>
                <TableCell>{user.attendance_id}</TableCell>
                <TableCell>{user.fname} {user.lname}</TableCell>
                <TableCell>{user.field_of_profession}</TableCell>
                <TableCell>{user.mobile}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{user.age}</TableCell>
                <TableCell>{user.inserted_date}</TableCell>
                <TableCell>
                <Button className='Hove' elevation={10} sx={{whiteSpace: 'nowrap' , fontSize: '14px', backgroundColor:'rgb(92, 2, 2)',margin:'9px 0px'}}  size="large" variant="contained">
                   SendMail
                 </Button>
                  </TableCell>
                <TableCell>
                <Button className='Hover-elemnt' elevation={10} sx={{whiteSpace: 'nowrap' , fontSize: '14px', backgroundColor:'rgb(92, 12, 12)',margin:'9px 0px'}}  size="large" variant="contained">
                   Edit
                 </Button>
                  </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {totalPages > 1 && (
        <div>
          {currentPage > 1 && (
            <IconButton onClick={handlePrevPage}>
              <ArrowBackIcon />
            </IconButton>
          )}
          <span>Page {currentPage} of {totalPages}</span>
          {currentPage < totalPages && (
            <IconButton onClick={handleNextPage}>
              <ArrowForwardIcon />
            </IconButton>
          )}
        </div>
      )}
    </Container>
  );
};

export default WorkshopAccounts;
