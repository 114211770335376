/* eslint-disable react/prop-types */
import React from 'react';
import { Accordion, AccordionSummary, Typography,AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const Des1 = (props) => {

   const {des} = props

  return (
    <Accordion style={{ marginTop: '20px',background:`linear-gradient(20deg,rgb(8, 3, 90),rgb(14, 54, 13),rgb(38, 21, 54))` }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography sx={{color:"white"}} variant="h5">Description</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{background:"rgb(241, 241, 241)",borderBottom:" 2px solid rgba(0, 0, 0, 0.7)"}}>
          <p style={{whiteSpace:"pre-line"}}>{des}</p>
       </AccordionDetails>

    </Accordion>
  );
};

export default Des1;
   