const {countries} = require('countries-list')



const CountryNames= ()=>{

     const AllCountryNames = Object.keys(countries).map(countryCode=>
        (
        {
          
             value: countryCode,
             label: countries[countryCode].name

        })
        
        ) 

        return AllCountryNames


}


module.exports = CountryNames