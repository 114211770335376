/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import axios from 'axios';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Delete } from '@mui/icons-material';
import { ContextData } from '../../../../Context/Context';
import baseUrl from '../../../../BaseURL';

export default function DeleteDialog(props) {
  
  const [open, setOpen] = React.useState(false);
  const {setAlertStatus,setLoader,setDeleteProductStatus,DeleteProductStatus,AdministratorTokens} = React.useContext(ContextData)

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  }


  const handleDelete = ()=>{

    axios.delete(`${baseUrl}/auth/deleteproduct/${props.data.product_token}`,{
      withCredentials: true,
      headers: {
        'Accept' : 'application/json',
        'authorization': `Bearer ${AdministratorTokens !== null && AdministratorTokens}`
      }}).then(res=>{
      setDeleteProductStatus(!DeleteProductStatus)
        // console.log(res.data)
         setAlertStatus({
          status:"success",
          textStatus: "Deleted"
       })
         setLoader(false)
     }).catch(err=>{

           if(err) console.log(err)
           setAlertStatus({
              status:"error",
              textStatus: err.message
           })
           setLoader(false)
     })


  }

  
  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen}>
       <Delete/>
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Python Program
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are sure want to delete this Program
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button onClick={handleDelete} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}