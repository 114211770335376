import React from 'react';
import { Container, Grid, Paper, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import VideoAccordion from '../sections/@dashboard/products/ProductCardInfo/VModules/VideoAccordion';
import UploadDialog from '../sections/@dashboard/products/ProductCardInfo/UploadDialog';
import PhyAccordion from '../sections/@dashboard/products/ProductCardInfo/PhysicalClass/PhyAccordion';
import InsAccordion from '../sections/@dashboard/products/ProductCardInfo/AddInstructor/InsAccordion';
import OnlineAccordion from '../sections/@dashboard/products/ProductCardInfo/Online/OnlineAccordion';
import DesAccordion from '../sections/@dashboard/products/ProductCardInfo/Description/DesAccordion';
import Publish from './Publish';
import Revenue from './Total/Revenue';
import TotalOnline from './Total/TotalOnline';
import TotalPhysical from './Total/TotalPhysical';
import TotalUsers from './Total/TotalUsers';

function ProductCardInfo() {

  
  const {producttokens } =  useParams()
  const {posttype,productname} = useParams()




  return (
    <Container maxWidth="lg" style={{ marginTop: '20px' }}>
       <Publish/>
       <div>
        <h1>{productname}</h1>
       </div>

      <Grid container spacing={2}>
        
        <Grid item xs={12} md={4}>
          <Paper elevation={3} style={{boxShadow:'0px 0px 10px rgba(0,0,0,0.322)', padding: '20px', textAlign: 'center',background:`rgb(92, 12, 12)`,color:'white',fontWeight:'800' }}>
            <Typography variant="h5">Registered Users</Typography>
            <Typography variant="h3"><TotalUsers/></Typography>
             <Revenue/>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper elevation={3} style={{ boxShadow:'0px 0px 10px rgba(0,0,0,0.322)', padding: '20px', textAlign: 'center',background:`rgb(92, 12, 12)`,color:'white',fontWeight:'800' }}>
            <Typography variant="h5">Online Registrations</Typography>
            <TotalOnline/>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper elevation={3} style={{boxShadow:'0px 0px 10px rgba(0,0,0,0.322)', padding: '20px', textAlign: 'center',background:`rgb(92, 12, 12)`,color:'white',fontWeight:'800' }}>
            <Typography variant="h5">Physical Class Registrations</Typography>
            <TotalPhysical/>
          </Paper>
        </Grid>
      </Grid>

      
      <UploadDialog
       status="video"
       producttokens={producttokens}
      />
       <VideoAccordion
         status="Arabic"
         producttokens={producttokens}
       />
       <VideoAccordion
        status="Hausa"
        producttokens={producttokens}
      />
      <VideoAccordion
       status="English"
       producttokens={producttokens}
      />


       {
        posttype === "Both" &&
      <>
      <>
      <UploadDialog
       status="physicalclass"
       producttokens={producttokens}
      />
      <PhyAccordion
        producttokens={producttokens}
      />
      </> 


      <>
      <UploadDialog
       status="onlineclass"
       producttokens={producttokens}
      />
      <OnlineAccordion
       producttokens={producttokens}
      />
         
      </>
        
        
      </>

       }
      {
          posttype === "Physical Class" && 
      <>
      <UploadDialog
       status="physicalclass"
       producttokens={producttokens}
      />
      <PhyAccordion
        producttokens={producttokens}
      />
      </> 
      }

      {
     posttype === "Online Class" &&
      <>
      <UploadDialog
       status="onlineclass"
       producttokens={producttokens}
      />
      <OnlineAccordion
       producttokens={producttokens}
      />
         
      </>
      }

    
      <UploadDialog
       status="instructor"
       producttokens={producttokens}
      />
      <InsAccordion
       producttokens={producttokens}
      />

       <UploadDialog
       status="description"
       producttokens={producttokens}
      />
      <DesAccordion
       producttokens={producttokens}
      />

    </Container>
  );
}

export default ProductCardInfo;
