import React from 'react'
import GetApprovedStudent from '../sections/@dashboard/Certificate/GetApprovedStudent'

function Certificate() {
  return (
    <div className='post_product-con'>


     <GetApprovedStudent/>

    </div>
  )
}

export default Certificate