/* eslint-disable react/prop-types */
import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import SendRecjMssge from './SendRecjMssge';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {


const { children, onClose, ...other } = props;


  
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function ViewRejectAssessment(props) {

  const [open, setOpen] = React.useState(false)

  // const {PersonKeys,Person,setUnderReviewStatus}= React.useContext(ContextData)

/*   React.useEffect(()=>{ 

    async function FetchData(){
        try {  
           const response = await axios.get(`${baseUrl}/auth/under_review/${Person.st_tokens}/${props.status === "Submit Assessment Task" && props.moduleToken}`,{
            withCredentials: true,
            headers: {
              'Accept' : 'application/json',
              'authorization': `Bearer ${PersonKeys !== null && PersonKeys}`
            }})
            setUnderReviewStatus(response.data)   
         //  console.log(UnderReviewStatus)    
                    
        } catch (error) {
           console.log(error.message)
        }
    }  
    FetchData()     
},[])

 */


  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>

        {
            props.productStatus === 1 ? 
       <Button disabled style={{color:"white",width:"auto",whiteSpace:"nowrap",marginBottom:"10px",backgroundColor: "rgb(10,0,0)"}} variant="outlined">
        Completed Module
       </Button>  
        :
        <Button  onClick={handleClickOpen} style={{color:"white",width:"auto",whiteSpace:"nowrap",marginBottom:"10px",backgroundColor: "rgb(130,0,0)"}} variant="outlined">
        Reject
       </Button>
        }

        <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
          >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
        {props.status} {""}
        </BootstrapDialogTitle>

        <DialogContent dividers>
            <h2 style={{textAlign:"center"}}>Reason of Rejection </h2>
                
                {
                   props.data.rejct_descrp !== null &&
              <p>
                 <p style={{
                  padding:"7px",
                  width:"150px",
                  fontWeight:"800",
                  backgroundColor:"purple",
                  color:"white",
                  borderRadius:"7px",
                  whiteSpace:"nowrap",
                  textAlign:"center"
                  }}>Recent Message</p>  
                 <p><i><b>You: </b></i>{props.data.rejct_descrp}</p>
              </p>
                }

           <div style={{width:"100%",justifyContent:"center",alignItems:"center"}}>
               {
                props.productStatus === 0 ? 
                <SendRecjMssge
                  data={props.data}
                />
                :
                "Completed"
               }
                
           </div>   

        </DialogContent>

        <DialogActions>
        

          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button> 


        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}

