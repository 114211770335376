/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState,useContext, useEffect } from "react";
import {
  TextField,
  Button,
  FormControl,
  FormHelperText,
} from "@mui/material";
import axios from "axios";
import AlertBox from "../../../../../../layouts/dashboard/header/AlertBox";
import { ContextData } from "../../../../../../../Context/Context";
import baseUrl from "../../../../../../../BaseURL";



const SubmitText = (props) => {


  const [descrp, setdescrp] = useState("");
  const [File, setFile] = useState(null);
 
  
  const {setLoader,AlertStatus,setAlertStatus,AdministratorTokens,ReloadData,setReloadData}= useContext(ContextData)
  const uploadedbyusertoken= "ewoiioi9000"

  
  const handleSubmit = (event) => {

    event.preventDefault();
    setAlertStatus(null)

    // Validate the inputs before submitting
    if (
     
      descrp.trim() === ""
    ) {
      alert("Please fill in all the fields.");
      return;
    }
  
 
    const formData = new FormData();
   
     setLoader(true)

     formData.append("descrp",descrp)
     formData.append("sub_token",props.data)
     formData.append("videoFile",File)


      axios.put(`${baseUrl}/auth/uploadAssign`,formData,{
        withCredentials: true,
        headers: {
          'Accept' : 'application/json',
          'Content-Type': 'application/octet-stream',
          'Content-Length': 200 * 1024 * 1024,
          'authorization': `Bearer ${AdministratorTokens !== null && AdministratorTokens}`
        }}).then(res=>{
  
            if(res.data.status){

              setAlertStatus({
              status:"success",
              textStatus: res.data.textStatus
            }) 

            if(!res.data.status){

                setAlertStatus({
                status:"error",
                textStatus: res.data.textStatus
              }) 
            }

            // update the data after being uploaded 
              setReloadData(!ReloadData)

             // reset the form
             setdescrp("")
             setLoader(false)
             return

            }
       

           setLoader(false)
          
       }).catch(err=>{
  
             if(err) console.log(err)
             setAlertStatus({
                status:"error",
                textStatus: err.message
             })
             setLoader(false)
       })


  };


  const handleFileChange = (event) => {
    const filedata = event.target.files[0];

    setAlertStatus({
      status:"",
      textStatus: ""
   })
    // Check if a file is selected
    if (filedata) {
     
      if (filedata.size > 200 * 1024 * 1024) {
        setAlertStatus({
          status:"error",
          textStatus: "Video size exceeds the maximum allowed limit (200MB)."
       })
        return;
      }
      setFile(filedata)    
    }
  };



  return (
   
        <form onSubmit={handleSubmit}>
             {
              AlertStatus !== null  && 
              <AlertBox 
               Status={AlertStatus.status}
               textStatus={AlertStatus.textStatus}
               />
             }
             
             
          <FormControl fullWidth margin="normal" error={descrp.trim() === ""}>
            <TextField
              label="Description"
              value={descrp}
              onChange={(e) => setdescrp(e.target.value)}
              required
              id="filled-multiline-flexible"
              multiline
              maxRows={10}
              variant="filled"
            />
            {descrp.trim() === "" && (
              <FormHelperText error>Please enter the description</FormHelperText>
            )}
          </FormControl>
          <FormControl fullWidth margin="normal" error={File === null}>
            <input
              type="file"
              accept="video/"
              onChange={handleFileChange}
              required
            />
            {File === null && (
              <FormHelperText error>Please upload video (max 200MB).</FormHelperText>
            )}
          </FormControl>
          <Button variant="contained" color="primary" type="submit">
            ADD
          </Button>
        </form>
    
  );
};

export default SubmitText;
