/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import { io } from 'socket.io-client';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import axios from 'axios';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import baseUrl from '../../../../BaseURL';
import { ContextData } from '../../../../Context/Context';
import ViewAssessment from './ModulesManifest/ViewAssessment';
import AlertPOP from '../../../../AlertPOP';


function Row(props) {
 
  const [open, setOpen] = React.useState(true);
  const {AdministratorTokens} =  React.useContext(ContextData)
  const [Data,setData] = React.useState(null)


  // Socket io
 // const socket = io(baseUrl)
  const [ReloadData,setReloadData] = React.useState(true)

 /*  React.useEffect(()=>{


    socket.on("reupdate_progress",(arg,callback)=>{
      setReloadData(!ReloadData)
     })


  },[socket]) */

  const [message,setmessage] = React.useState("")
  const [OpenAlert, setOpenAlert] = React.useState(false);
  const [transition, setTransition] = React.useState(undefined);


  // Handle the alert transition and function

  const handleClick = (Transition) => {
   setTransition(() => "TransitionLeft")
    setOpenAlert(true);
  };
   // Handle the close alert box
  const handleClose = () => {
    setOpenAlert(false);
  };


  React.useEffect(()=>{
        
    async function FetchData(){

      try {
        const response = await axios.get(`${baseUrl}/auth/get_all_under_review_for_single/${props.data.st_token}/${props.data.product_token}`,{
          withCredentials: true,
          headers: {
            'Accept' : 'application/json',
            'authorization': `Bearer ${AdministratorTokens !== null && AdministratorTokens}`
          }})  
             setData(response.data)
         // console.log(response.data)
      } catch (err) {
         if(err) console.log(err.message)
       
      }


    }FetchData()
            
  },[ReloadData])


  // Handle Approve Completion button

  const HandleApproveCompletion = (a,b) =>{

    handleClick("left")
    

    if(a !== b){
      setmessage("Student most complete all given assessment before completion approval")
         return
    }

    if(a === b){

         
      const data = {
        productToken: props.data.product_token,
        st_token: props.data.st_token,
        value:"1"
      }
      
     axios.put(`${baseUrl}/auth/approved_completion`,data,{
      withCredentials: true,
      headers: {
        'Accept' : 'application/json',
         'authorization': `Bearer ${AdministratorTokens !== null && AdministratorTokens}`
      },
     }).then(res=>{

           if(res.data.status){

            setmessage(res.data.textStatus)
          }
         // socket.emit("update_progress_table","update");
    
        
     }).catch(err=>{

           if(err) console.log(err)
           setmessage(err.message)
          
     })
    }
  }


  return (
    <>
    <div style={{position:"absolute"}}>
      <AlertPOP
       Open={OpenAlert}
       handleClose={handleClose}
       transition={transition}
       message={message}
      />
    </div>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
            
        <TableCell align="center" component="th" scope="row">{props.data.productName}</TableCell>
        <TableCell align="center">{props.data.ProductType}</TableCell>
        <TableCell align="center">{props.data.program_duration} week{props.data.program_duration !== "1" ? "s" : ""}</TableCell>
        <TableCell align="center">{props.data.productModule_count}</TableCell>
        <TableCell align="center">{props.data.lang}</TableCell>
        <TableCell align="center">{props.data.watch_count}</TableCell>
        <TableCell align="center">{props.data.assessiment_count}</TableCell>
        <TableCell align="center">{props.data.submit_count}</TableCell>
        <TableCell align="center">{props.data.attendance_count}</TableCell>
        <TableCell align="center">{props.data.progress_count}</TableCell>
        <TableCell align="center">{props.data.productStatus === 0 ?
    
         <Button onClick={ () => HandleApproveCompletion(props.data.productModule_count,props.data.assessiment_count)} style={{ color:"white",width:"auto",whiteSpace:"nowrap",marginBottom:"10px",backgroundColor: "rgb(4, 102, 19)"}} variant="outlined"> 
            Approve
        </Button>    : 
                        
         <Button disabled={"true"} style={{color:"white",width:"auto",whiteSpace:"nowrap",marginBottom:"10px",backgroundColor: "rgb(41, 201, 22)"}} variant="outlined"> Completed
          </Button>   
             }
         </TableCell>
        <TableCell align="center">{props.data.startDate}</TableCell>

      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={14}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Assessment Modules 
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell  align="center">Module Name</TableCell>
                    <TableCell  align="center">Module Number</TableCell>
                    <TableCell  align="center">Video</TableCell>
                    <TableCell  align="center">Status</TableCell>
                    <TableCell  align="center">Watch</TableCell>
                    <TableCell align="center">Submitted Time</TableCell>
                    <TableCell align="center">Submitted Date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Data !== null && Data.map((list,index) => (
                    <TableRow key={index}>
                      
                      <TableCell  align="center">{list.module_title}</TableCell>
                      <TableCell  align="center">{list.module_number}</TableCell>
                      <TableCell  align="center">
                        <ViewAssessment
                         data={list} 
                         product_token={props.data.product_token} 
                         productStatus = {props.data.productStatus}
                        />
                      </TableCell>

                      <TableCell  align="center">
                        {
                          list.asst_status === 3 &&
                        <Button style={{color:"white",width:"auto",whiteSpace:"nowrap",marginBottom:"10px",backgroundColor: "rgb(41, 201, 22)"}} variant="outlined"> Approved
                        </Button>   
                        }

                          {
                         ( list.asst_status === 2) &&
                        <Button style={{color:"white",width:"auto",whiteSpace:"nowrap",marginBottom:"10px",backgroundColor: "rgb(0, 154, 203)"}} variant="outlined"> Rejected
                        </Button>
                        }

                        {
                         ( list.asst_status === 1 ) &&
                        <Button style={{color:"white",width:"auto",whiteSpace:"nowrap",marginBottom:"10px",backgroundColor: "rgb(0, 0, 203)"}} variant="outlined"> New
                        </Button>
                        }
                      </TableCell>
                      <TableCell  align="center">{list.watch_count} Times</TableCell>
                      <TableCell  align="center">{list. inserted_date.slice(list.inserted_date.indexOf("T")+1)}</TableCell>
                      <TableCell  align="center">{list. inserted_date.slice(0,list.inserted_date.indexOf("T"))}</TableCell>
                    
                    </TableRow>
                  ))} 
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
       
      </TableRow>
      
    </>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    calories: PropTypes.number.isRequired,
    carbs: PropTypes.number.isRequired,
    fat: PropTypes.number.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number.isRequired,
        customerId: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
      }),
    ).isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    protein: PropTypes.number.isRequired,
  }).isRequired,
};




export default function ViewReport(props) {




  return (
    <TableContainer component={Paper}>
      <Table  aria-label="collapsible table">
        <TableHead sx={{background:"rgb(38, 21, 54)"}}>
          <TableRow >
            <TableCell sx={{background:"rgb(38, 21, 54)" , color:"white", fontWeight:"800"}} />
            <TableCell sx={{background:"rgb(38, 21, 54)" , color:"white", fontWeight:"800"}}>ProductName</TableCell>
            <TableCell sx={{background:"rgb(38, 21, 54)" , color:"white", fontWeight:"800"}} align="right">ProductType</TableCell>
            <TableCell sx={{background:"rgb(38, 21, 54)" , color:"white", fontWeight:"800"}} align="right">Duration</TableCell>
            <TableCell sx={{background:"rgb(38, 21, 54)" , color:"white", fontWeight:"800"}} align="right">Module Number</TableCell>
            <TableCell sx={{background:"rgb(38, 21, 54)" , color:"white", fontWeight:"800"}} align="right">Locality</TableCell>
            <TableCell sx={{background:"rgb(38, 21, 54)" , color:"white", fontWeight:"800"}} align="right">Watch Count</TableCell>
            <TableCell sx={{background:"rgb(38, 21, 54)" , color:"white", fontWeight:"800"}} align="right">Approved Assessment</TableCell>
            <TableCell sx={{background:"rgb(38, 21, 54)" , color:"white", fontWeight:"800"}} align="right">Submit Count</TableCell>
           
            <TableCell sx={{background:"rgb(38, 21, 54)" , color:"white", fontWeight:"800"}} align="right">Attendance Count</TableCell>
            <TableCell sx={{background:"rgb(38, 21, 54)" , color:"white", fontWeight:"800"}} align="right">Progress Count</TableCell>
            <TableCell sx={{background:"rgb(38, 21, 54)" , color:"white", fontWeight:"800"}} align="center">Status</TableCell>
            <TableCell sx={{background:"rgb(38, 21, 54)" , color:"white", fontWeight:"800"}} align="center">StartDate</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          
            <Row data={props.data} />

        </TableBody>
      </Table>
    </TableContainer>
  );
}