/* eslint-disable prefer-destructuring */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState,useContext } from "react";
import {
  TextField,
  Button,
  FormControl,
  FormHelperText,
} from "@mui/material";
import axios from "axios";
import AlertBox from "../../../../../layouts/dashboard/header/AlertBox";
import { ContextData } from "../../../../../../Context/Context";
import baseUrl from "../../../../../../BaseURL";

 

const Description = (props) => {
  
   const {producttokens} = props
  
 
  const [Description, setDescription] = useState("");
  const [File, setFile] = useState(null);
  const [FileName,setFileName] = useState()
  const [imagePreview, setImagePreview] = useState(null);
  
  const {setLoader,AlertStatus,setAlertStatus,AdministratorTokens,ReloadData,setReloadData}= useContext(ContextData)
  
  
  const handleSubmit = (event) => {
    event.preventDefault();
    setAlertStatus(null)
    // Validate the inputs before submitting
    if (
      Description.trim() === "" ||
      File === null
    ) {
      alert("Please fill in all the fields.");
      return;
    }
    
    // Form submission logic here
    // You can send the form data to your backend or perform any other action
      setLoader(true)
      const formData = new FormData()
      formData.append("descriptn",Description)
      formData.append("productToken",producttokens)
      formData.append("File",File)
    
  
     axios.put(`${baseUrl}/auth/description`,formData,{
      withCredentials: true,
      headers: {
        'Accept' : 'application/json',
        'Content-Type': 'application/octet-stream',
        'Content-Length': 12582912,
         'authorization': `Bearer ${AdministratorTokens !== null && AdministratorTokens}`
      },
     }).then(res=>{

        // console.log(res.data)
         setAlertStatus({
          status:"success",
          textStatus: "Description Added"
       })
       setReloadData(!ReloadData)
       setLoader(false)

        
     }).catch(err=>{

           if(err) console.log(err)
           setAlertStatus({
              status:"error",
              textStatus: err.message
           })
           setLoader(false)
     })
  };

 
  const handleFileChange = (event) => {
    const filedata = event.target.files[0];
   
    const name = event.target.files[0].name
    // Check if a file is selected
    if (filedata) {

      if (filedata.size > 20 * 1024 * 1024) {
        alert("Image size exceeds the maximum allowed limit (10MB).");
        return;
      }
      setFile(filedata)
      setFileName(name)
            // Show image preview
            const reader = new FileReader();
            reader.onloadend = () => {
              setImagePreview(reader.result);
            };
            reader.readAsDataURL(filedata)
    }
  };



  return (
   
        <form onSubmit={handleSubmit}>

             {
              AlertStatus !== null  && 
              <AlertBox 
               Status={AlertStatus.status}
               textStatus={AlertStatus.textStatus}
               />
              }
             
          <FormControl fullWidth margin="normal" error={Description.trim() === ""}>
            <TextField
              label="Description"
              value={Description}
              onChange={(e) => setDescription(e.target.value)}
              required
              id="filled-multiline-flexible"
              multiline
              maxRows={10}
              variant="filled"
            />
            {Description.trim() === "" && (
              <FormHelperText error>Please enter the Description</FormHelperText>
            )}
          </FormControl>

          <FormControl fullWidth margin="normal" error={File === null}>
            <input
              type="file"
              name="Filename"
              accept="file/*"
              onChange={handleFileChange}
              required
            />
            {File === null && (
              <FormHelperText error>Please upload an image (max 10MB).</FormHelperText>
            )}
            {
            imagePreview !== null && 
              
                <div className="post-product-img-preview-con">
                 <img className="post-p-img" src={imagePreview}  alt="imagePreview" />
                 </div>
            }
          </FormControl>
          
          <Button variant="contained" color="primary" type="submit">
            Add
          </Button>

        </form>
    
  );
};

export default Description;
