/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import * as React from 'react';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import { Tooltip } from '@material-ui/core';
import Slide from '@mui/material/Slide';
import Register from './Register';
import Loading from '../../../layouts/dashboard/header/Loading';
import { ContextData } from '../../../Context/Context';
 


const Transition = React.forwardRef( (props, ref) => <Slide direction="up" ref={ref} {...props} />);
   
export default function SignUp(props) {
  
  const {Loader,open,setOpen} = React.useContext(ContextData)
  const {status} = props 


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
         {
          status === "true" &&  <Tooltip title="Sign Up">
         <Button onClick={handleClickOpen}className='Hover-elemnt' elevation={10} sx={{whiteSpace: 'nowrap' , fontSize: '10px',marginRight:'5px', backgroundColor:'rgb(92, 12, 12)'}}  size="small" variant="contained" endIcon={''}>
         SignUp
        </Button>

       </Tooltip>

         }
       
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className='appBar-preview' sx={{ position: 'relative'}}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1}} variant="h6" component="div">
            <img style={{width:'150px'}} src='/assets/illustrations/logocrop.png' alt='mastermindtech'/>  
            <h3 style={{textAlign:"center",position:"absolute"}}>SignUp Form</h3>
            </Typography>
            
            <Link style={{textDecoration:'none',color:'white'}} to="/login">
            <Button style={{fontWeight:"800"}}  autoFocus color="inherit" onClick={handleClose}>
              Login
            </Button>
            </Link>
          </Toolbar>
        </AppBar>
           {
            Loader &&  <Loading/>
          } 
       

         <div className='main-preview-con'>
          <div className='general-width-veiw' >
          <div className='PreviewCon'>
            <div className='signUp-con'>
            
                 <Register/>
             
            </div>
          </div>

          </div>

         </div>

      </Dialog>
    </div>
  );
}