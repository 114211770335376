/* eslint-disable react/prop-types */
import React, { useState,useContext } from "react";
import {
  TextField,
  Button,
  FormControl,
  FormHelperText,
} from "@mui/material";
import axios from "axios";
import AlertBox from "../../../../layouts/dashboard/header/AlertBox";
import { ContextData } from "../../../../Context/Context";
import baseUrl from "../../../../BaseURL";

 

const FormEditStudent = (props) => {

    const {fname,lname,gender,mobileNumber,email,country} = props.data;

  const [Fname, setFname] = useState(fname || "");
  const [Lname, setLname] = useState( lname ||"");
  const [dirtOfbirth, setdirtOfbirth] = useState(props.data.date_of_birth || "");
  const [Gender, setGender] = useState(gender || "");
  const [mobile, setmobile] = useState(mobileNumber ||"");
  const [Email, setEmail] = useState(email || "");
  const [Country, setCountry] = useState(country || "");
  const [StateofOrigin, setStateofOrigin] = useState(props.data.state_of_origin || "");
  const [homeaddress, sethomeaddress] = useState( props.data.home_address || "");
 
  
  const {setLoader,AlertStatus,setAlertStatus,AdministratorTokens}= useContext(ContextData)
  
  
  const handleSubmit = (event) => {
    event.preventDefault();
    setAlertStatus(null)
    // Validate the inputs before submitting
    if (
      Fname.trim() === "" ||
      mobile.trim() === "" ||
      dirtOfbirth.trim() === "" ||
      Gender.trim() === "" ||
      Email.trim() === "" ||
      Country.trim() === "" 
    ) {
      alert("Please fill in all the fields.");
      return;
    }
    
    // Form submission logic here
    // You can send the form data to your backend or perform any other action
      setLoader(true)
      const data = {
        Fname ,
        mobile ,
        dirtOfbirth,
        Gender,
        total_credit_hours: Email,
        Country
      }
 
     axios.post(`${baseUrl}/auth/product`,{
         ...data
     },{
      withCredentials: true,
      headers: {
        'Accept' : 'application/json',
        'authorization': `Bearer ${AdministratorTokens !== null && AdministratorTokens}`
      }}).then(res=>{
             
      // update_by_user_tokens
       // update_date_time
        // console.log(res.data)
         setAlertStatus({
          status:"success",
          textStatus: res.data
       })
         setLoader(false)

         // reset the form
        setFname("")
        setmobile("")
        setdirtOfbirth("")
        setGender("")
        setEmail("")
        setCountry("")
        setStateofOrigin("")
        sethomeaddress("")
      
        
        
     }).catch(err=>{

           if(err) console.log(err)
           setAlertStatus({
              status:"error",
              textStatus: err.message
           })
           setLoader(false)
     })
  };






  return (
   
        <form onSubmit={handleSubmit}>
             {
              AlertStatus !== null  && 
              <AlertBox 
               Status={AlertStatus.status}
               textStatus={AlertStatus.textStatus}
               />
             }
             

          
          <FormControl fullWidth margin="normal" error={Fname.trim() === ""}>
            <TextField
              label="Fname"
              value={Fname}
              onChange={(e) => setFname(e.target.value)}
              required
            />
            {Fname.trim() === "" && (
              <FormHelperText error>Please enter First Name.</FormHelperText>
            )}
          </FormControl>

          <FormControl fullWidth margin="normal" error={Lname.trim() === ""}>
            <TextField
              label="Lname"
              value={Lname}
              onChange={(e) => setLname(e.target.value)}
              required
            />
            {Lname.trim() === "" && (
              <FormHelperText error>Please enter Last Name.</FormHelperText>
            )}
          </FormControl>


          <FormControl fullWidth margin="normal" error={dirtOfbirth.trim() === ""}>
            <TextField
              label="Date_of_birth"
              value={dirtOfbirth}
              onChange={(e) => setdirtOfbirth(e.target.value)}
              required
            />
            {dirtOfbirth.trim() === "" && (
              <FormHelperText error>Please enter Date_of_birth.</FormHelperText>
            )}
          </FormControl>


          <FormControl fullWidth margin="normal" error={Gender.trim() === ""}>
            <TextField
              label="Gender"
              value={Gender}
              onChange={(e) => setGender(e.target.value)}
              required
            />
            {Gender.trim() === "" && (
              <FormHelperText error>Please enter Gender.</FormHelperText>
            )}
          </FormControl>


          <FormControl fullWidth margin="normal" error={mobile.trim() === ""}>
            <TextField
              label="Mobile Number"
              value={mobile}
              onChange={(e) => setmobile(e.target.value)}
              required
            />
            {mobile.trim() === "" && (
              <FormHelperText error>Please enter Mobile Number.</FormHelperText>
            )}
          </FormControl>


          <FormControl fullWidth margin="normal" error={Email.trim() === ""}>
            <TextField
              label="Email"
              value={Email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            {Email.trim() === "" && (
              <FormHelperText error>Please enter Email.</FormHelperText>
            )}
          </FormControl>


          <FormControl fullWidth margin="normal" error={Country.trim() === ""}>
            <TextField
              label="Country"
              value={Country}
              onChange={(e) => setCountry(e.target.value)}
              required
            />
            {Country.trim() === "" && <FormHelperText error>Please enter Country.</FormHelperText>}
          </FormControl>


          <FormControl fullWidth margin="normal" error={StateofOrigin.trim() === ""}>
            <TextField
              label="State of Origin"
              value={StateofOrigin}
              onChange={(e) => setStateofOrigin(e.target.value)}
              required
            />
            {StateofOrigin.trim() === "" && <FormHelperText error>Please enter State of Origin.</FormHelperText>}
          </FormControl>



          <FormControl fullWidth margin="normal" error={homeaddress.trim() === ""}>
            <TextField
              label="home_address"
              value={homeaddress}
              onChange={(e) => sethomeaddress(e.target.value)}
              required
            />
            {homeaddress.trim() === "" && <FormHelperText error>Please enter Home Address.</FormHelperText>}
          </FormControl>



          <Button variant="contained" color="primary" type="submit">
            Update
          </Button>


        </form>
    
  );
};

export default FormEditStudent;
