import React from 'react'
import PostForm from '../sections/@dashboard/PostProduct/PostForm'


function PostProduct() {
  return (
    <div className='post_product-con'>
      <PostForm/>
    </div>
  )
}

export default PostProduct