import React from 'react'

import CourseMTextHeader1 from './CourseMTextHeader1';
import CourseMTextHeader2 from './CourseMTextHeader2';
import Stepper from './Stepper'



function CourseModule() {



  return (
    <div className='MainContainer'>

       
     {/* Text Viewer container  */}

    <div  className='TextViewContainer'>
         <CourseMTextHeader1/>
        <Stepper/>
        <CourseMTextHeader2/> 
    </div>

  {/* ------End--------- */}



   {/* Video viewer */}

    <div className='VidoeContainer'>
       
        <div className='videoCon'>

        <video style={{height:"100%",width:"100%"}} controls controlsList="nodownload" className='vi-con-inner'>
       <source  src={`https://www.loom.com/share/ca8d0aaded3c40cfa40ffc597db6f2db?sid=09624939-59f4-4f1b-89c0-4e85e6d31acf`} type="video/mp4"/>
       <track kind="captions" label="English" src="" srcLang="en" default/>
       </video>
      
        </div>
    </div>

    {/* ----End ------- */}

     


    </div>
  )
}

export default CourseModule