/* eslint-disable react/prop-types */
/* eslint-disable arrow-body-style */
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { useNavigate } from 'react-router-dom';
import { usePaystackPayment } from 'react-paystack';
import DialogActions from '@mui/material/DialogActions';
import axios from 'axios';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { ContextData } from '../../Context/Context';
import Form from './SignUp/Form';
import baseUrl from '../../BaseURL';

const Transition = React.forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />;
});


const config = {
  publicKey: 'pk_live_9fb9da914b757f1bb1dc9590ef8e057764886a6c',
} 

export default function CheckDialog(props) {

  
  const [open, setOpen] = React.useState(false);
  const {PersonKeys,Person,setFadeConfetti,setFadePayAlert} = React.useContext(ContextData)
  const [CreateAcc,setCreateAcc] = React.useState(false)
  const {amount,ProductType,ProductName ,ProductToken} = props
  const navigate = useNavigate();
  const point = "00"
   

      
  const initializePayment = usePaystackPayment({...config,
    reference: (new Date()).getTime().toString(),
    email: Person !== null && Person.email,
    amount: Number(amount+point),
    firstname:  Person !== null && Person.fname,
    lastname: Person !== null && Person.lname,
     });
         
       
     const SubmitRecordForm = (ref)=>{
        
      const data = {
        paymentReferenceToken:  ref ,
        stEmail:  Person !== null && Person.email,
        paymentAmount: Number(amount),
        productName: ProductName,
        typeProduct: ProductType,
        productTokens: ProductToken,
        firstname:  Person !== null && Person.fname,
       }
              axios.post(`${baseUrl}/auth/recordpayment`,{...data},{
                withCredentials: true,
                headers: {
                 'Accept':'application/json',
                 'authorization': `Bearer ${PersonKeys!==null&&PersonKeys}`
                },
              }).then(() =>{
                 // console.log(res)
              }).catch(err =>{
                console.log(err.message)
           })



     }

    const onSuccess = (reference) => {
      // Implementation for whatever you want to do with reference and after success call.
        SubmitRecordForm(reference.reference)
        setFadePayAlert(true)
        setFadeConfetti(true)
        setTimeout(()=>{
          const timer = setFadeConfetti(null)
          clearTimeout(timer)
        },17000)
    };
    
    const onClose = () => {
      // implementation for  whatever you want to do when the Paystack dialog closed.
      console.log('closed')
      
    }

  const handleClickOpen = () => {

          setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  
  };
  const handleLogin =() =>{
    navigate('/login', { replace: true });
  }
  const handlePay = () => {
      
    setTimeout(()=>{
          const timer =  setOpen(false)
          clearTimeout(timer)
     },2000) 
      // Excute Payment fuction
      initializePayment(onSuccess,onClose)
  };
 const handleaccount = () => {
     setCreateAcc(true)
}


  return (
    <div>

       <Button className='Hover-elemnt' elevation={10} sx={{whiteSpace: 'nowrap' , fontSize: '14px',marginRight:'5px', backgroundColor:'rgb(92, 12, 12)',width:"100%"}} onClick={()=>handleClickOpen()}  size="small" variant="contained" endIcon={''}>
        Register Now {/* <span style={{marginLeft:"0.5em"}}> 50% Discount</span> */}
       </Button> 
   
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        className='check-dialog'
      >
        <DialogTitle sx={{display:'flex',justifyContent:'center',alignItems:'center'}}> <img style={{width:"80%",height:'5em'}} src="/assets/illustrations/logocrop.png" alt="MastermindTech"/> </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">

              {

              CreateAcc ? <Form/> :
             <>
             
             
               {
                PersonKeys !== null ?
           <div style={{width:'100%',height:'19em'}}>
            <p style={{paddingTop:'6px'}}>
           
            <p>
            By clicking the Payment button, you will be redirected to the secure payment pop up page, where you can securely enter your payment details and complete the registration. 

            </p>

            <p>
            We have implemented industry-standard security measures to ensure the safety and privacy of your information.
            </p>

            <p>

            If you encounter any issues during the payment process or have any questions, please do not hesitate to contact our customer support team at 07040768898.
            </p>
          </p>
           </div>  :
                 <div style={{width:'100%',height:'19em'}}>
                 <p style={{paddingTop:'6px'}}> 

                    Signing up is quick and straightforward, and it will enable you to have a seamless and personalized learning experience. Once you're registered, you can easily track your progress, access course materials, and receive updates on any new offerings.
                     <p>
                     Don't miss out on this opportunity to expand your knowledge and skills. Click the button below to create your account and then proceed with the payment to secure your spot! 💡💳
                     </p>

                    If you encounter any questions or need assistance during the signup process, feel free to reach out to our helpful support team at support@mmt-ng.com.

                    Thank you for choosing our course! We look forward to having you as part of our learning community! 🌟🎓"
                 </p>
                 </div>
               
               }
             </>
              }

          </DialogContentText>
        </DialogContent>
        <DialogActions>
          
              {

             CreateAcc ? "" :
            <>
           {
             PersonKeys !== null  ? 
             <Button onClick={()=>handlePay()}>Pay now</Button> 
             :
             <>
             <Button onClick={()=>handleLogin()}>Login</Button>
              <b>Or</b>
             <Button onClick={()=>handleaccount()}>Create New account</Button> 
             </>
           }   
            </> 
              }
        
        </DialogActions>
      </Dialog>
    </div>
  );
}