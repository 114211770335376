import React from 'react'
import Form from './Form'

function Register() {
  return (
    <div className='main-registration-con'>
     <div className='general-width-con'>
     <h3 style={{textAlign:"center"}}>SignUp Form</h3>
    
     <div className='inner-registration-con'>
      <Form/>
     </div>

     </div>

    </div>
  )
}

export default Register