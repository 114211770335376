import React from 'react'

function Map() {
  return (
    <div className='map-con'>
   
   <h1 style={{ fontFamily:`Anton, sans-serif`,fontSize:'3em'}}>NAVIGATE US</h1>

    <div className='inner-map-con'>
    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1961.3242796413529!2d7.4932168!3d10.5283218!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x104d4b9cb600135f%3A0x43779581331c609c!2sJagaban%20Plaza!5e0!3m2!1sen!2sng!4v1687345676128!5m2!1sen!2sng" width="600" height="450" title='map' style={{border:0}} allowfullscreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"/>
    </div>
 
    </div>
  )
}

export default Map