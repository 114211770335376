/* eslint-disable react/prop-types */
import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccTable from './AccTable';


const InsAccordion = (props) => {


  const {producttokens} = props

  return (
    <Accordion style={{ marginTop: '20px' }} className='btncolor'>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h5">Instructor</Typography>
      </AccordionSummary>
      <AccordionDetails>
       <AccTable
        producttokens = {producttokens}
       />
      </AccordionDetails>
    </Accordion>
  );
};

export default InsAccordion;
   