/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios'
import React, { createContext, useEffect, useState } from 'react'
import baseUrl from '../BaseURL'

export const ContextData = createContext()
      
function Context({children}) {

    const [FadeLoad,setFedLoad] = useState(true)
    const [ChangeSliderText,setChangeSliderText] = useState(0)
    const [signUp,setsignUp] = useState(false)
    const [Loader,setLoader] = useState(false)
    const [Person,setPerson] = useState({})
    const [PersonKeys,setPersonKeys] = useState(null)
    const [UserToken,setUserToken] = useState(null)
    const [open, setOpen] = useState(false);
    const [AlertStatus,setAlertStatus] = useState(null)
    const [popdelete,setpopdelete] = useState(false)
    const [CourseData,setCourseData] = useState(null)
    const [userEmail,setuserEmail] = useState("")
    const [AdministratorTokens,setAdministratorTokens] = useState("")
    const [DeleteProductStatus,setDeleteProductStatus] = useState(false)
    const [FadeConfetti,setFadeConfetti] = useState(null)
    const [Refresh,setRefresh] = useState(false)
    const [OpenDialog,setOpenDialog] = useState(false)
    const [FadePayAlert,setFadePayAlert] = useState(false)
    const [RefreshFun,setRefreshFun] = useState(false)
    const [reloadworkshop,setreloadworkshop] = useState(false)
    const [WorkshopUserTokens,setWorkshopUserTokens] = useState(null)
    const [openWorkshopAlert, setOpenWorkshopAlert] = useState(false);
    const [ReloadData,setReloadData] = useState(false)
    const [UnderReviewStatus,setUnderReviewStatus] = useState(null)
    const [ChangeFetchCompleted,setChangeFetchCompleted] = useState(0)

    // Reload Functions --------------------------------

    const [ReloadPostedProducts,setReloadPostedProducts] = useState(true)
    const [ReloadVideos,setReloadVideos] = useState(true)
    const [ReloadAssignments,setReloadAssignments] = useState(true)
  

  // Fetch client Refresh Token

   useEffect(()=>{
   
    // Fetch client Refresh Token

    async function FetchData(){

    try {
    const response = await axios.get(`${baseUrl}/client/clientrefreshtoken`,{
        withCredentials: true,
        headers: {
          'Accept' : 'application/json',
          'Content-Type': 'application/json',
            }
           }) 
              
         if(!response.data.status){
        //  navigate('/login', { replace: true }); 
         // setPersonKeys(null) 
            return
         }
         if(response.data.status) {

          setPersonKeys(response.data.accesstoken)
          setUserToken(response.data.Usertoken)
         // console.log(response.data.accesstoken)
        //  console.log(PersonKeys != null && PersonKeys)
         }  

    } catch (err) {
    if(err) console.log(err.message)
    }
    }

    FetchData()


},[])


// Get Client Data

 useEffect(()=>{


  async function FetchData(){
        
  /*   if(PersonKeys.trim() === null || PersonKeys.trim() === undefined || PersonKeys.trim() === "")  return console.log("Empty Key Point") */
      try {
          
        const response = await axios.get(`${baseUrl}/auth/get_student_data/${UserToken}`,{
          withCredentials: true,
           headers: {
            'Accept' : 'application/json',
            'Content-Type': 'application/json',
            'authorization': `Bearer ${PersonKeys}`
          }
          } 
          ) 
     
         setPerson(response.data)
     } catch (err) {
       if(err) console.log(err.message)
     }
  
    }
    
    FetchData() 

 },[PersonKeys,Refresh])


// Fetch Administrator Refresh Token

useEffect(()=>{
   
  // Fetch Administrator Refresh Token
  async function FetchData(){

  try {
  const response = await axios.get(`${baseUrl}/dash/adminrefreshtoken`,{
      withCredentials: true,
      headers: {
        'Accept' : 'application/json',
        'Content-Type': 'application/json',
          }
         })  
        
      // if(!response.data.status) return  setAdministratorTokens(null)
           
       if(response.data.status){
        setAdministratorTokens(response.data.accesstoken)
        setUserToken(response.data.userToken)
       }

  } catch (err) {
  if(err) console.log(err.message)
  }
  }
  FetchData()

},[])






  return (
    <ContextData.Provider
    value={{

        FadeLoad,setFedLoad,
        ChangeSliderText,setChangeSliderText,
        signUp,setsignUp,
        Person,setPerson,
        Loader,setLoader,
        PersonKeys,setPersonKeys,
        open,setOpen,
        AlertStatus,setAlertStatus,
        popdelete,setpopdelete,
        CourseData,setCourseData,
        userEmail,setuserEmail,
        AdministratorTokens,setAdministratorTokens,
        DeleteProductStatus,setDeleteProductStatus,
        UserToken,setUserToken,
        FadeConfetti,setFadeConfetti,
        Refresh,setRefresh,
        OpenDialog,setOpenDialog,
        FadePayAlert,setFadePayAlert,
        RefreshFun,setRefreshFun,
        reloadworkshop,setreloadworkshop,
        WorkshopUserTokens,setWorkshopUserTokens,
        openWorkshopAlert, setOpenWorkshopAlert,
        ReloadData,setReloadData,
        UnderReviewStatus,setUnderReviewStatus,
        ReloadPostedProducts,setReloadPostedProducts,
        ReloadVideos,setReloadVideos,
        ReloadAssignments,setReloadAssignments,
        ChangeFetchCompleted,setChangeFetchCompleted

    } }
    > 
    {children}
    
    </ContextData.Provider>
  )
}

export default Context