import { Navigate,Routes,Route } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
/* import BlogPage from './pages/BlogPage';
import UserPage from './pages/UserPage'; */
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import ProductsPage from './pages/ProductsPage';
import CoursesPage from './pages/CoursesPage';
import CpanelLayout from './CPanel/layouts/dashboard/CpanelLayout';
import CpanelLoginPage from './CPanel/pages/CpanelLoginPage';
import CourseModule from './sections/@dashboard/Courses/Module/CourseModule';
import OfflineIndex from './sections/@dashboard/OfflinePrograms/OfflineIndex';
import UserPro from './sections/@dashboard/UserProfile/UserPro';
import DashboardAppPage from './CPanel/pages/ProductPage';
import Student from './CPanel/pages/Student';
import PostProduct from './CPanel/pages/PostProduct';
import WorkshopAccounts from './CPanel/pages/WorkshopAccounts';
import Instructor from './CPanel/pages/Instructor';
import Payment from './CPanel/pages/Payment';
import Validation from './CPanel/pages/Assigned';
import Certificate from './CPanel/pages/Certificate';
import ProductCardInfo from './CPanel/pages/ProductCardInfo';
import MakePayment from './sections/@dashboard/SignUp/Registration/Use-File/MakePayment';
import DashPrivateRoute from './CPanel/sections/auth/login/DashPrivateRoute';
import PrivateRoute from './sections/auth/login/PrivateRoute';
import CourseDisplayer from './sections/@dashboard/Courses/CourseDisplayer';
import RegisterAttendee from './workshop/RegisterAttendee';
import WorkshopUserLoginPage from './workshop/Login/WorkshopUserLoginPage';
import WorkshopPrivateRoute from './workshop/Login/WorkshopPrivateRoute';
import Progress from './CPanel/pages/Progress';
import ContactUs from './CPanel/pages/ContactUs';
import ValidateCartificate from './CPanel/pages/ValidateCartificate';



// ----------------------------------------------------------------------

export default function Router() {

    return( 

   <Routes>
 
       {/* Home route */}
    <Route path="/home" element={<OfflineIndex />}  />
      {/* -------------------- */}


     {/* User Dashboard route */}
    <Route path='/dashboard' element={<DashboardLayout />}>   
    <Route path='categories' element={<CoursesPage />} />
    <Route path='course_page_displayer/:id/:productname/:locationtype' element={<CourseDisplayer/>} />

    <Route element={<PrivateRoute/>}>
    <Route path='userprofile' element={<UserPro />} />
    </Route> 
    <Route path='products' element={<ProductsPage />} />

    </Route>
     {/* ------------------- */}

     {/* Login route */}
    <Route path='login' element={<LoginPage />} />
     {/* ------------------- */}


     {/* Workshop route */}
   
     <Route element={<Navigate to="/workshop_user_login"/>}/>
     <Route path='/workshop_user_login' element={<WorkshopUserLoginPage/>}/>
     <Route element={<WorkshopPrivateRoute/>}>
     <Route path='/workshop/user_account' element={<RegisterAttendee />} />
     </Route>
 




     <Route element={<DashPrivateRoute/>}>  
     <Route path='/cpanel' element={<CpanelLayout/>}>

     <Route path='app' element={<DashboardAppPage />}/>
     <Route path='product_card/:producttokens/:pub/:posttype/:productname' element={<ProductCardInfo />} />
    {/*  <Route path='user' element={ <UserPage /> } /> */}
     <Route path='products' element={<ProductsPage />} />
    {/*  <Route path='blog' element={ <BlogPage /> } /> */}
     <Route path='student' element={<Student />} />
     <Route path='post_product' element={<PostProduct />} />
     <Route path='WorkshopAccounts' element={<WorkshopAccounts />} />
     <Route path='intructor' element={<Instructor />} />
     <Route path='payment' element={<Payment />} />
     <Route path='assign' element={<Validation />} />
     <Route path='progress' element={<Progress/>} />
     <Route path='certificate' element={<Certificate />} />
     <Route path='contact-us' element={<ContactUs />} />
     <Route path='validate' element={<ValidateCartificate />} />

     </Route>
     </Route>
      
   
     {/* ------------------------ */}

     {/* C-panel Login route */}
    <Route path='cpanel-login' element={ <CpanelLoginPage />} />
      {/* ---------------------------- */}


  

    <Route path='/dashboard/courses/module' element={<CourseModule />} />
    <Route path='/payment' element={<MakePayment />} />
     {/* -------------------- */}
 
     {/* Wrong route handler route */}
     <Route element={<SimpleLayout />}>
     <Route element={<Navigate to="/home" />} index />
     <Route path='404' element={<Page404/>} />
     <Route path='*' element={ <Navigate to="/404" /> } />
     </Route>
      {/* ----------------------- */}

     <Route path='*' element={<Navigate to="/404" replace />} />


   
 
   </Routes>

    )
  
 
   
}
