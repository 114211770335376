import * as React from 'react';
import { Button } from '@mui/material';

export default function CertificateCard() {



  return (
     <div className='cert-card-con'>

           <div className='cert-img-card-con'>

              <img  src='/assets/Certificate/cert.svg' alt='Your Certificate'/>
{/* 
              <Button title="Your certificate has been approved you can download it" style={{color:"white",whiteSpace:"nowrap",marginBottom:"10px",backgroundColor:"rgb(12, 12, 38)"}} size="small" color="primary">
               Download Certificate
              </Button> */}

              <Button title='Your certificate is pending. After approval you will be able to download it' style={{color:"white",whiteSpace:"nowrap",marginBottom:"10px",backgroundColor:"rgb(12, 12, 38)"}} size="small" color="primary">
               Pending Certificate...
              </Button>



           </div>
     </div>
  );
}