import React from 'react'

function CourseMTextHeader1() {
  return (
    <div className='inner-TextViewHeader'>
          <div className='inner-TextViewHeader-inner-img'>
         <img style={{width:'140px'}} src='/assets/illustrations/supernovanew.svg' alt='supernovar'/>  
          </div>
         <div className="inner-TextViewHeader-inner-text">
           <div>
             text 2
           </div>
            <div>
           <h2>C++</h2>
            </div>
            <div>
           <b>Module 1</b>

            </div>
          </div> 
         </div>
  )
}

export default CourseMTextHeader1