/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React ,{ useState }from 'react';
import {AccordionDetails } from '@mui/material';
import Button from '@mui/material/Button';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import baseUrl from '../../../../../../BaseURL';
import { ContextData } from '../../../../../../Context/Context';
import Des1 from './Des/Des1';
import GiveAssessment from './GiveAssessment/GiveAssessment';
import VideoPath from '../../../../../../VideoPath';

const GetVideos = (props) => {

 
    const {AdministratorTokens,UserToken,ReloadData,setReloadData} = React.useContext(ContextData)
    const {producttokens} = useParams()
    const [Data,setData] = useState(null)
    const [Refetch,setRefetch] = useState(false)



    React.useEffect(()=>{

        async function FetchData(){
            try { 
                
               const response = await axios.get(`${baseUrl}/auth/getvideo/${producttokens}/${props.status}`,{
                withCredentials: true,
                headers: {
                  'Accept' : 'application/json',
                  'authorization': `Bearer ${AdministratorTokens !== null && AdministratorTokens}`
                }})
             // console.log(response.data)
              setData(response.data)
            } catch (error) {
               console.log(error.message)
            }
    
        }
       
        FetchData()     
    
    },[ReloadData,Refetch])
    

    // Delete Module

     const handlRemove =  (subtoken,vname)=>{
    

    axios.delete(`${baseUrl}/auth/delete_module/${subtoken}/${vname}`,{
      withCredentials: true,
      headers: {
        'Accept' : 'application/json',
        'authorization': `Bearer ${AdministratorTokens !== null && AdministratorTokens}`
      }}).then(res=>{
                
     setReloadData(!ReloadData)
     setRefetch(!Refetch)
        
     }).catch(err=>{

           if(err) console.log(err)
           
     })






     }



  return (

       <>
    
         {
           Data !== null && Data.map((list,index) =>(
                  
           <AccordionDetails key={index} 
           sx={{ 
            boxShadow:"1px 3px 10px rgba(0,0,0,0.4)",
            background:"rgb(231, 231, 231)",
            borderTop:" 2px solid rgba(0, 0, 0, 0.5)",
            marginTop:"10px",
            marginBottom:"10px",
            borderRadius:'7px'
            }}>
 
       <div className='v-info-vi-con'>
        {/* ?id=${AdministratorTokens}&ADMID=${UserToken} */}
    
       <video controls controlsList="nodownload" className='vi-con-inner'>
       <source  src={`${VideoPath}${list.video}?id=${AdministratorTokens}&ADMID=${UserToken}`} type="video/mp4"/>
       <track kind="captions" label="English" src="" srcLang="en" default/>
       </video>
 
         <div className='v-info-con-inner'>
       
          <div className='v-info-con-inner-inner' style={{fontFamily:"sans-serif"}}>
             <p><b>Title:</b> <span>{list.v_title}</span></p>
             <p><b>Module:</b> <span>{list.module}</span></p>
             <p title='Uploaded Time'><b>Time:</b> <span>{list.upload_date.slice(list.upload_date.indexOf("T") + 1)}</span></p>
             <p title='Uploaded Date'><b>Date:</b> <span>{list.upload_date.slice(0,list.upload_date.indexOf("T"))}</span></p>
             <p><b>Language:</b> <span>{list.lan}</span></p>
             
          </div>

          <div className='ass-sub-com'>

             <div className='absolute top-0 right-0'>
             <Button variant="outlined" onClick={()=>handlRemove(list.sub_token,list.video)}>Remove </Button>
             </div>
              
              <GiveAssessment
                title="Assmnt"
                status="Assessment"
                data={list.sub_token}
              />

               {
                list.videoPath2 &&
               <GiveAssessment
                title="viewAssmnt"
                status="View Given Assessment"
                data={list.videoPath2}
                des={list.assign}
              />
               }



           </div>
          
         </div>
 
         </div>
 
         <div className='des-v-con'>
            <Des1
             des={list.descrp}
            /> 
         </div>
 
    
       </AccordionDetails>
             ))
         }
       
     
       </>

  );
};

export default GetVideos;
   