/* eslint-disable react/prop-types */
import * as React from 'react';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';


function TransitionLeft(props) {
  return <Slide sx={{background:"linear-gradient(20deg,rgb(8, 3, 90),rgb(14, 54, 13),rgb(38, 21, 54))", color:"white",fontWeight:"800"}} className='' {...props} direction="left" />;
}



export default function AlertPOP({message,Open,transition,handleClose}) {

  
  return (
    <Box sx={{ width: 300 }}>
      <Snackbar
        open={Open}
        onClose={handleClose}
        TransitionComponent={transition === "TransitionLeft" && TransitionLeft}
        message={message}
        key={transition === "TransitionLeft" ? transition.name : ''}
      />
    </Box>
  );
}