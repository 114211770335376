import React, { useContext } from 'react'
import { Outlet,Navigate} from 'react-router-dom'
import { ContextData } from '../../Context/Context'

const useAuth = () =>{

  const {WorkshopUserTokens} = useContext(ContextData)
  return WorkshopUserTokens !== null && WorkshopUserTokens
}



function WorkshopPrivateRoute() {

  const IsAuth = useAuth()

  return <>{ IsAuth ? <Outlet /> : <Navigate to="/workshop_user_login" /> }</>
}

export default WorkshopPrivateRoute





