/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React ,{ useState,useEffect,useContext}from 'react';
import {AccordionDetails } from '@mui/material';
import axios from 'axios';
// eslint-disable-next-line no-unused-vars
import { io } from 'socket.io-client';
import baseUrl from '../../../../BaseURL';
import { ContextData } from '../../../../Context/Context';
import Des1 from './Des/Des1';
import SubAssViewAssCon from './Assessment/SubAssViewAssCon';
import InnerLoarder from '../../../../InnerLoarder';
import VideoPath from '../../../../VideoPath';


const GetVideos = (props) => {

   const {ProdcutToken,LangStatus} = props.dataset

    const {PersonKeys,Person} = useContext(ContextData)
 // const {producttokens} = useParams()
    const [Data,setData] = useState(null)
    const [AssStatus,setAssStatus] = useState(null)
    const [Loader,setLoader] = useState(false)

    
   // Send to the server to retrieve 
//   const socket = io(baseUrl)
  const [ReloadData1,setReloadData1] = useState(true)

/*   useEffect(()=>{


    socket.on("reupdate_progress",(arg,callback)=>{  
      setReloadData1(!ReloadData1)
     })


  },[socket]) */

    useEffect(()=>{
        
        async function FetchData(){

         

            try {  
               const response = await axios.get(`${baseUrl}/auth/getvideo/${ProdcutToken}/${LangStatus}`,{
                withCredentials: true,
                headers: {
                  'Accept' : 'application/json',
                  'authorization': `Bearer ${PersonKeys !== null && PersonKeys}`
                }})
             // console.log(response.data)
                setData(response.data)
                setLoader(true)

            } catch (error) {
               console.log(error.message)
            }
        }
        
        FetchData()     
    },[])
    



    // Watch user active 

    const CountUserWatchActivity  = (moduleToken) =>{

      const data ={

          moduleToken,
          ProdcutToken,
          st_tokens:Person.st_tokens
      }    

      axios.post(`${baseUrl}/auth/count_user_watch_activity`,data,{
         withCredentials: true,
         headers: {
           'Accept' : 'application/json',
           'authorization': `Bearer ${PersonKeys !== null && PersonKeys}`
         }}).catch(err=>{
   
              if(err) console.log(err)
               console.log(err.message)
        }).then(res =>{
         // socket.emit("update_progress_table","update");
        })

      

}

  return (

       <>


        {
            Loader && 
         
           Data !== null && Data.map((list,index) => (      
                  
       <AccordionDetails key={index} 
           sx={{ 
            boxShadow:"1px 3px 10px rgba(0,0,0,0.4)",
            background:"rgb(231, 231, 231)",
            borderTop:" 2px solid rgba(0, 0, 0, 0.5)",
            marginTop:"10px",
            marginBottom:"10px",
            borderRadius:'7px',
            position:"relative"
            }}
             className='get-video-accordion-details-container'
            >

       <div className='v-info-vi-con'>
        {/* ?id=${PersonKeys}&UID=${Person.st_tokens} */}
       <video  onPlay={CountUserWatchActivity(list.sub_token)} controls controlsList="nodownload" className='vi-con-inner'>
       <source src={`${VideoPath}${list.video}?id=${PersonKeys}&UID=${Person.st_tokens}`} type="video/mp4"/>
       <track kind="captions" label="English" src="" srcLang="en" default/>
      </video>
  
      <div className='v-info-con-inner'>
           
  
         <div className='v-info-con-inner-inner' style={{fontFamily:"sans-serif"}}>
             <p><b>Title:</b> <span>{list.v_title}</span></p>
             <p><b>Module:</b> <span>{list.module}</span></p>
             <p><b>Date:</b> <span>{list.upload_date.slice(0,list.upload_date.indexOf("T"))}</span></p>
             <p><b>Language:</b> <span>{list.lan}</span></p>
            {AssStatus !== null && AssStatus.asst_status}
          </div>

              
          <SubAssViewAssCon
          data={list.videoPath2}
          moduleToken={list.sub_token}
          videoPath2Exis={list.videoPath2}
          moduleTile={list.v_title}
          moduleNumber={list.module}
          ProdcutToken={list.v_token}
          assignDes={list.assign}
           
          />

  
         </div>


         </div>
  
         <div className='des-v-con'>
            <Des1
             des={list.descrp}
            /> 
         </div>
  
    
       </AccordionDetails>
             
           ) 
            )


      }
          

      {
          !Loader &&

          <div className='inner-int'>
              <div className='inner-int-inner'>
              <InnerLoarder/>
              </div>  
          </div>
      
      }
       
        
        
            
     
       </>

  );
};

export default GetVideos;
   