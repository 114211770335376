/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { io } from 'socket.io-client';
import {
    Button,
  } from "@mui/material";
import { ContextData } from '../../Context/Context';
import baseUrl from '../../BaseURL';

export default function Publish() {
    const {producttokens} = useParams()
    const {AdministratorTokens,ReloadData,setReloadData} = React.useContext(ContextData)
    const [status,setstatus] = React.useState("0")
    const socket = io(baseUrl)
    

/*   const handleChange = (event) => {
    setChecked(event.target.checked)
  }; */




  const HandlClick = (Status)=>{



      axios.put(`${baseUrl}/auth/publish`,{productToken:producttokens,Status},{
        withCredentials: true,
        headers: {
          'Accept' : 'application/json',
          'authorization': `Bearer ${AdministratorTokens !== null && AdministratorTokens}`
        // eslint-disable-next-line no-unused-vars
        }}).then(res=>{
            setReloadData(!ReloadData)

       socket.emit("update_client_side_products","update");

       }).catch(err=>{
             if(err) console.log(err)
       }) 

  }

  React.useEffect(()=>{

    async function FetchData(){
        try { 
            
           const response = await axios.get(`${baseUrl}/auth/get_publish_status/${producttokens}`,{
            withCredentials: true,
            headers: {
              'Accept' : 'application/json',
              'authorization': `Bearer ${AdministratorTokens !== null && AdministratorTokens}`
            }})

           console.log(response.data[0].publish)
           setstatus(response.data[0].publish)
        } catch (error) {
           console.log(error.message)
        }

    }

    FetchData()

},[ReloadData])

   



  return (

      <div style={{display:'flex',alignItems:'center'}}>
         
        {

            status === "0" &&
            
            <Button onClick={()=>HandlClick(1)} className='Hover-elemnt' elevation={10} sx={{whiteSpace: 'nowrap' , fontSize: '14px', backgroundColor:'rgb(92, 12, 12)',margin:'9px 0px'}}  size="large" variant="contained" endIcon={''}>
            Publish
           </Button>
        }

         {
            status === "1" &&
            <Button onClick={()=>HandlClick(0)} className='Hover-elemnt' elevation={10} sx={{whiteSpace: 'nowrap' , fontSize: '14px', backgroundColor:'rgb(92, 12, 12)',margin:'9px 0px'}}  size="large" variant="contained" endIcon={''}>
            Un-Publish
            </Button>

            }

      </div>
    
  );
}