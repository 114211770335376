import React, { useContext } from 'react'
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import { Link } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ContextData } from '../../../Context/Context';



function NavList() {

    const {setFedLoad}= useContext(ContextData)
    const matches = useMediaQuery('(max-width:700px)');

  return (
    <div className='mtc-navlist-con'>
 
      {
        matches ? "" :
       <ul>
         {/* <li style={{display: 'flex'}}><CardMembershipIcon/>Certification Courses</li> */}
        {/*  <li style={{display: 'flex'}}><WorkspacePremiumIcon/>Workshops </li> */}
        <Link onClick={()=>setFedLoad(true)} to='/dashboard/products' style={{textDecoration:'none'}}> <li style={{display: 'flex'}}><AppRegistrationIcon/>Programs</li></Link>
        <Link onClick={()=>setFedLoad(true)} to='/dashboard/categories' style={{textDecoration:'none'}}> <li style={{display: 'flex'}}><AppRegistrationIcon/> Categories</li></Link>
       {/*   <li style={{display: 'flex'}}><InfoIcon/>About Us</li> */}
       </ul>
      }
   
    </div>
  )
}

export default NavList