/* eslint-disable react/button-has-type */
import React, { useState,useEffect, useContext } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios';
import FileDownload from "js-file-download";
import Online from './Product/Online'
import Physical from './Product/Physical'
import baseUrl from '../../../BaseURL';
import { ContextData } from '../../../Context/Context';
import PreloaderFunc from '../../../PreloaderFunc';


function ProductPreview() {


    const {productname,locationtype,id} = useParams()
    const [Des,setDes] = useState(null)
    const {PersonKeys} = useContext(ContextData)
  
     
    useEffect(()=>{
  
  
        async function FetchData(){
  
            try { 
                
               const response = await axios.get(`${baseUrl}/client/get_description_data/${id}`,{
                withCredentials: true,
                headers: {
                  'Accept' : 'application/json',
                  'authorization': `Bearer ${PersonKeys !== null && PersonKeys}`
                },
               })
               console.log(response.data[0])
               setDes(response.data)
            } catch (error) {
               console.log(error.message)
            }
  
        }
  
      FetchData()
  
  
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[id])

   const GetOutline = async(id)=>{
         try {
               const response = await axios.get(`${baseUrl}/pub/downloadoutline/${id}`,{
                withCredentials: true,
                headers: {
                  'Accept' : 'application/json',
                 'authorization': `Bearer ${PersonKeys !== null && PersonKeys}`
                },
                responseType: "blob",
               })
               FileDownload(response.data,`MMT_outline_file`);
         } catch (error) {
            console.log(error)
         }
   }

  return (
    <div className='ProductPreview'>

        <div style={{width:'100%',height:'auto'}}>  
        <h1 style={{textAlign:'center'}}>Learn {productname}</h1>
        </div>

        <div className='ProductViewText-Course-con'>

          {
            locationtype === "Online Class" && 
            
            <>
            <Online
              showtext="true" 
              type="Online Class"
              title="You can join this program online from your comfort zone, kindly register now and start right away"
            />     
            </>
          }


            {
            locationtype === "Physical Class" && 
            
            <>
            <Physical
               showtext="false" 
               type="Physical Class"
               title="You can join the physical class in the training center, kindly register now to join the up coming class"
            />      
            </>
          }
          

          {
            locationtype === "Both" && 
            
            <>
            <Online
              showtext="true" 
              type="Online Class"
              title="You can join this program online from your comfort zone, kindly register now and start right away"
            />
   
            <Physical
               showtext="false" 
               type="Physical Class"
               title="You can join the physical class in the training center, kindly register now to join the up coming class"
            />      
            </>
          }
  
      </div>

      <div className='ProductViewText-Specification-con'>
        <dl>
        <dt className='textcolor' style={{marginBottom:'4px'}}><b>PREREQUISITES</b></dt>
        <dd>- You are required to spend the given time for each module </dd>

        <dd>
        - For Technical program or course, you’re required to have a computer
        </dd> 
        <dd>
        - Practicing is compulsary for every module
        </dd> 
        <dd>
        - Submitting assessment is mandatory for every module
        </dd> 

        </dl>
      </div>

      <div className='ProductViewText-CourseDescreption-con'>
        <dl>
        <dt className='textcolor'><h3>Description</h3></dt>

           {
            Des !== null && Des.map((item,index)=>(
              <div key={index}>
               <dd> <b> Get the {productname} outline </b><button onClick={()=>GetOutline(item.outline_file)} className='dowbtn'>Download</button></dd> 
              <dd style={{whiteSpace: 'pre-line'}} > {item.descriptn} </dd>
              </div>
            ))
           }

           
            {
            Des === null && <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}> <PreloaderFunc/></div>
          }
      
  
        </dl>
      </div>



             </div>
  )
}

export default ProductPreview