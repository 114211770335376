/* eslint-disable prefer-destructuring */
/* eslint-disable no-unused-vars */
import React, { useState,useContext } from "react";
import {
  Box,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  FormHelperText,
} from "@mui/material";
import axios from "axios";
import AlertBox from "../../../layouts/dashboard/header/AlertBox";
import { ContextData } from "../../../../Context/Context";
import baseUrl from "../../../../BaseURL";



const SubmitForm = (props) => {

  const [productName, setProductName] = useState("");
  const [productType, setProductType] = useState("");
  const [productLocation, setProductLocation] = useState("");
  const [File, setFile] = useState(null);
  const [FileName,setFileName] = useState()
  const [imagePreview, setImagePreview] = useState(null);
  
  const {setLoader,AlertStatus,setAlertStatus,AdministratorTokens,ReloadPostedProducts,setReloadPostedProducts}= useContext(ContextData)
  
  
  const handleSubmit = (event) => {
    event.preventDefault();
    setAlertStatus(null)
    // Validate the inputs before submitting
    if (
      productName.trim() === "" ||
      productType.trim() === "" ||
      productLocation.trim() === "" ||
      File === null
    ) {
      alert("Please fill in all the fields.");
      return;
    }
    
    // Form submission logic here
    // You can send the form data to your backend or perform any other action
      setLoader(true)
      const formData = new FormData()
      formData.append("File",File);
      formData.append("FileName",FileName);
      formData.append("product_location",productLocation);
      formData.append("product_name",productName);
      formData.append("product_type",productType);
    
  
     axios.post(`${baseUrl}/auth/product`,formData,{
      withCredentials: true,
      headers: {
        'Accept' : 'application/json',
        'Content-Type': 'application/octet-stream',
        'Content-Length': 12582912,
        'authorization': `Bearer ${AdministratorTokens !== null && AdministratorTokens}`
      },
     }).then(res=>{

        // console.log(res.data)
         setAlertStatus({
          status:"success",
          textStatus: res.data
       })
       setLoader(false)

       setReloadPostedProducts(!ReloadPostedProducts)

        // reset the form
        setProductName("")
        setProductType("")
        setProductLocation("")
        setFile(null)
        setImagePreview(null)
        
     }).catch(err=>{

           if(err) console.log(err)
           setAlertStatus({
              status:"error",
              textStatus: err.message
           })
           setLoader(false)
     })
  };

  const handleDelete = () => {
    // Add delete functionality here
    console.log("Delete button clicked!");
  };

  const handleUpdate = () => {
    // Add update functionality here
    console.log("Update button clicked!");
  };

  const handleFileChange = (event) => {
    const filedata = event.target.files[0];
   
    const name = event.target.files[0].name
    // Check if a file is selected
    if (filedata) {
      // Check if the file is an image (you can add more image types if needed)
      if (!filedata.type.startsWith("image/")) {
        alert("Please select an image file.");
        return;
      }
      // Check if the file size is within the limit (10MB)
      if (filedata.size > 10 * 1024 * 1024) {
        alert("Image size exceeds the maximum allowed limit (10MB).");
        return;
      }
      setFile(filedata)
      setFileName(name)
            // Show image preview
            const reader = new FileReader();
            reader.onloadend = () => {
              setImagePreview(reader.result);
            };
            reader.readAsDataURL(filedata);
      
    }
  };



  return (
   
        <form onSubmit={handleSubmit}>
             {
              AlertStatus !== null  && 
              <AlertBox 
               Status={AlertStatus.status}
               textStatus={AlertStatus.textStatus}
               />
             }
             
          
          <FormControl fullWidth margin="normal" error={productName.trim() === ""}>
            <TextField
              label="Product Name"
              value={productName}
              onChange={(e) => setProductName(e.target.value)}
              required
            />
            {productName.trim() === "" && (
              <FormHelperText error>Please enter a product name.</FormHelperText>
            )}
          </FormControl>

          <FormControl fullWidth margin="normal" error={productType.trim() === ""}>
            <Select
              label="Product Type"
              value={productType}
              onChange={(e) => setProductType(e.target.value)}
              required
            >
              <MenuItem value="Program">Program</MenuItem>
              <MenuItem value="Course">Course</MenuItem>
              <MenuItem value="Workshop">Workshop</MenuItem>
            </Select>
            {productType.trim() === "" && (
              <FormHelperText error>Please select a product type.</FormHelperText>
            )}
          </FormControl>
    
          <FormControl fullWidth margin="normal" error={productLocation.trim() === ""}>
            <Select
              label="Product Type"
              value={productLocation}
              onChange={(e) => setProductLocation(e.target.value)}
              required
            >
              <MenuItem value="Online Class">Online</MenuItem>
              <MenuItem value="Physical Class">Physical Class</MenuItem>
              <MenuItem value="Both">Both</MenuItem>
            </Select>
            {productLocation.trim() === "" && (
              <FormHelperText error>Please select a {productType} location type.</FormHelperText>
            )}
          </FormControl>
          <FormControl fullWidth margin="normal" error={File === null}>
            <input
              type="file"
              name="Filename"
              accept="image/*"
              onChange={handleFileChange}
              required
            />
            {File === null && (
              <FormHelperText error>Please upload an image (max 10MB).</FormHelperText>
            )}
            {
              imagePreview !== null && 
              
                <div className="post-product-img-preview-con">
                 <img className="post-p-img" src={imagePreview}  alt="imagePreview" />
                 </div>
            }
          </FormControl>
          
          <Button variant="contained" color="primary" type="submit">
            Post
          </Button>

        </form>
    
  );
};

export default SubmitForm;
