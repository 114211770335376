/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import baseUrl from '../../../../../../BaseURL';
import { ContextData } from '../../../../../../Context/Context';
 



export default function AccTable(props) {

    const {producttokens} = props
    const [TableData,setTableData] = React.useState(null)
    const {AdministratorTokens,ReloadData} = React.useContext(ContextData)

     
    React.useEffect(()=>{


        async function FetchData(){
            try { 
                
               const response = await axios.get(`${baseUrl}/auth/get_physical_class_data/${producttokens}`,{
                withCredentials: true,
                headers: {
                  'Accept' : 'application/json',
                  'authorization': `Bearer ${AdministratorTokens !== null && AdministratorTokens}`
                }})
             //  console.log(response.data[0])
               setTableData(response.data)
            } catch (error) {
               console.log(error.message)
            }

        }

        FetchData()

},[producttokens,ReloadData])


  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Duration</TableCell>
            <TableCell align="center">Credit Hour</TableCell>
            <TableCell align="center">Total Credit Hours</TableCell>
            <TableCell align="center">Fee</TableCell>
          </TableRow>
        </TableHead>
         <TableBody>
            {
              TableData !== null &&  TableData.map((Listnum,index) =>(
                    <TableRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                     >
                    <TableCell component="th" scope="row">
                      {Listnum.duration}
                    </TableCell>
                    <TableCell align="center">{Listnum.credit_hours}</TableCell>
                    <TableCell align="center">{Listnum.total_credit_hours}</TableCell>
                    <TableCell align="center"><span style={{fontWeight:"800"}}>NGN</span> {Listnum.fee}</TableCell>
                   
                  </TableRow>
                   )
                   
                   
                )
            }
          
        </TableBody> 
      </Table>
    </TableContainer>
  );
}