import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { Link } from 'react-router-dom';
import NavList from './NavList';
import SignUp from '../SignUp/SignUp';

const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

function MTCNav() {


  const [anchorElUser, setAnchorElUser] = React.useState(null);


  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  
  return (
    <AppBar sx={{backgroundColor: 'white'}} position="static">
      <Container sx={{paddingLeft: '2px',paddingRight: '2px'}} maxWidth="xl">
        <Toolbar disableGutters>
    
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: '', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
         <img className='logo' alt="logo" src='/assets/illustrations/logocrop.png' />
          </Typography>



          <NavList/>
        

          <Box  sx={{ flexGrow: 0,}}>
            <Tooltip title="See our programs">
              <Link to="/dashboard/categories">
            <Button className='Hover-elemnt' elevation={10} sx={{whiteSpace: 'nowrap' , fontSize: '10px',marginRight:'5px', backgroundColor:'rgb(92, 12, 12)'}}  size="small" variant="contained" endIcon={''}>
                  Enroll now
            </Button>
              </Link>
            </Tooltip>         
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        
            <SignUp
             status="true"
            />
          

        </Toolbar>
  
      </Container>
    </AppBar>
  );
}
export default MTCNav