/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState,useContext } from 'react';
import { TextField, Button, Grid, Container, MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import validator from 'validator';
import zxcvbn from 'zxcvbn';
import axios from 'axios';
import baseUrl from '../../../BaseURL';
import CountryNames from './CountriesList';
import { ContextData } from '../../../Context/Context';
import AlertBox from '../../../layouts/dashboard/header/AlertBox';




const countryOptions = [...CountryNames()];



const Form = () => {

  const [formData, setFormData] = useState({
    fname: '',
    lname: '',
    date_of_birth: '',
    email: '',
    gender: '',
    state_of_origin: '',
    country: 'Choose Nationality',
    home_address: '',
    mobileNumber: '',
    userpasswrd: ''
  });

  const [errors, setErrors] = useState({});
  const [passwordStrength, setPasswordStrength] = useState('');
  const {FadeLoad,setLoader,setsignUp,setOpen} = useContext(ContextData)
  const [Err,setErr] = useState("")
  const navigate = useNavigate();
  const [handleError,sethandleError] = useState({
    status:'',
    textStatus:''
})

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
  };

  const handleCountrySelectChange = (selectedOption) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      country: selectedOption.label
    }));
  };

  const handlePasswordChange = (event) => {
    const { value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      userpasswrd: value
    }));

    const passwordStrengthInfo = zxcvbn(value);
    setPasswordStrength(passwordStrengthInfo.score);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const validationErrors = {};
   
   
    Object.entries(formData).forEach(([field, value]) => {
      if (value.trim() === '') {
        validationErrors[field] = 'Field cannot be empty';
      }
    });

    if (!validator.isEmail(formData.email)) {
      validationErrors.email = 'Invalid email address';
   
    }

    if (passwordStrength < 2) {
      validationErrors.userpasswrd = 'Password is weak. Please choose a stronger password.';
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {

            if(formData.country === "Choose Nationality") {
              sethandleError({
                status:'error',
                textStatus:'Select Country'
              })
              return
            } 

            if(!validator.isNumeric(formData.mobileNumber)) {
              sethandleError({
                status:'error',
                textStatus:'Mobile number should be a figures and must contain country code'
              })
              return
            } 

            sethandleError({
              status:'',
              textStatus:''
            })
                setLoader(true)
                axios.post(`${baseUrl}/client/register`,{
                  ...formData  
                }).then((res)=>{
                   // console.log(res.data.statusText)
                 
                    if(!res.data.status){
                      
                      if(res.data.statusText.includes("Email exist")){
                        setErr(res.data.statusText)
                        setLoader(false)
                      } 
    
                      if(res.data.statusText.includes("Number exist")){
                        setErr(res.data.statusText)
                        setLoader(false)
                      } 
                       
                    }

                  if(res.data.status){
                    setsignUp(true)
                    setLoader(false)
                    setOpen(false)
                    navigate('/login',{ replace: true });
                  } 

                }).catch(err=>{
                    console.log(err.message)
                    setLoader(false)
                })
    }
  };

  return (
    <Container maxWidth="sm">
        {
        handleError.status !== "" && 
        <AlertBox
        Status={handleError.status}
        textStatus={handleError.textStatus}
       />
      }
      <form onSubmit={handleSubmit}>
        
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              label="First Name"
              name="fname"
              value={formData.fname}
              onChange={handleInputChange}
              error={Boolean(errors.fname)}
              helperText={errors.fname}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Last Name"
              name="lname"
              value={formData.lname}
              onChange={handleInputChange}
              error={Boolean(errors.lname)}
              helperText={errors.lname}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label=""
              name="date_of_birth"
              value={formData.date_of_birth}
              onChange={handleInputChange}
              error={Boolean(errors.date_of_birth)}
              helperText={errors.date_of_birth}
              fullWidth
              required
              type='date'
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              error={Boolean(errors.email)}
              helperText={errors.email}
              fullWidth
              required
            />
             <span style={{color:"red"}}> {Err === "Email exist" && Err}</span>
          </Grid>
          <Grid item xs={12}>
            <TextField
              select
              label="Gender"
              name="gender"
              value={formData.gender}
              onChange={handleInputChange}
              error={Boolean(errors.gender)}
              helperText={errors.gender}
              fullWidth
              required
            >
              <MenuItem value="male">Male</MenuItem>
              <MenuItem value="female">Female</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="State of Origin"
              name="state_of_origin"
              value={formData.state_of_origin}
              onChange={handleInputChange}
              error={Boolean(errors.state_of_origin)}
              helperText={errors.state_of_origin}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={6}>
                  <Select
                 options={countryOptions}
                 value={{ value:formData.country, label: formData.country }}
                 onChange={handleCountrySelectChange}
                 placeholder="Select Country"
                 isSearchable
                 required
               />
        
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Home Address"
              name="home_address"
              value={formData.home_address}
              onChange={handleInputChange}
              error={Boolean(errors.home_address)}
              helperText={errors.home_address}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Valid Mobile Number"
              name="mobileNumber"
              value={formData.mobileNumber}
              onChange={handleInputChange}
              error={Boolean(errors.mobileNumber)}
              helperText={errors.mobileNumber}
              fullWidth
              required
            />
            <span style={{color:"red"}}> {Err === "Number exist" && Err}</span>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Password"
              name="userpasswrd"
              type="password"
              value={formData.userpasswrd}
              onChange={handlePasswordChange}
              error={Boolean(errors.userpasswrd)}
              helperText={errors.userpasswrd}
              fullWidth
              required
            />
            {passwordStrength > 0 && (
              <div>Password Strength: {passwordStrength}/4</div>
            )}
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary">
              Submit
            </Button>
          </Grid>
        </Grid> 
      </form>
    </Container>
  );
};

export default Form;
