import * as React from 'react';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import validator from 'validator';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import Loading from '../../../layouts/dashboard/header/Loading';
import { ContextData } from '../../../Context/Context';
import baseUrl from '../../../BaseURL';



const Transition = React.forwardRef( (props, ref) => <Slide direction="up" ref={ref} {...props} />);
   
export default function ForgetPass() {
  
  const {Loader,OpenDialog,setOpenDialog} = React.useContext(ContextData)
  
  const [email,setemail] = React.useState("")
  const [code,setcode] = React.useState("")
  const [password,setpassword] = React.useState("")
  const [Load,setLoad] = React.useState(false)
  const [Err,setErr]= React.useState("")
  const [Err2,setErr2]= React.useState("")
  const [Progress,setProgress] = React.useState("step1")




  const handleSubmit = () => {

     if(email.trim() === ""){
        setErr("Email input required")
       return
     }

     if(!validator.isEmail(email)){
        setErr("Not an email")
        return
     }


     if(Progress === "step1"){

        setLoad(true)
         axios.post(`${baseUrl}/pub/forgetpassword`,{email},{
          withCredentials: true,
          headers: {
           'Accept' : 'application/json',
           'Content-Type': 'application/json'
           // 'authorization': `Bearer ${AccessToken}`
         }
         }).then(res=>{
    
          if(!res.data.status){
             setErr(res.data.statusText)
             setLoad(false)
          }
            
          if(res.data.status){
             
               const timer = setTimeout(()=>{
                setErr2(res.data.statusText) 
                  setProgress("step2") 
                  setLoad(false) 
                  clearTimeout(timer)
    
               },6000)
           }
    
         }).catch(err=>{
              console.log(err.message)
              setLoad(false)
         })

     }else{
 
       
     if(code.trim() === ""){
        setErr("Code input required")
       return
     }

     if(!validator.isNumeric(code)){
        setErr("Not a number")
        return
     }
          
     if(password.trim() === ""){
        setErr("Password input required")
       return
     }



     if(!validator.isStrongPassword(password)){
        setErr("Password is weak. Please choose a stronger password. Must contain 1 UpperCase,1 LowerCase, and numberic ")
        return
     }
         
           setLoad(true)
        axios.put(`${baseUrl}/pub/updatepassword`,{email,code,password},{
            withCredentials: true,
            headers: {
             'Accept' : 'application/json',
             'Content-Type': 'application/json'
             // 'authorization': `Bearer ${AccessToken}`
           }
           }).then(res=>{
      
            if(!res.data.status){
               setErr(res.data.statusText)
               setLoad(false)
            }
              
            if(res.data.status){
                
                const timer = setTimeout(()=>{ 
                     setErr2(res.data.statusText)
                    setProgress("step1")  
                    setemail("")
                    setcode("")
                    setpassword("")
                    setLoad("")
                    setErr("")
                    setLoad(false)
                    clearTimeout(timer)
                 },8000)
             }
      
           }).catch(err=>{
                console.log(err.message)
                setLoad(false)
           })

     }




     
  };

  const handleClose = () => {
    setOpenDialog(false)
    setProgress("step1")  
    setemail("")
    setcode("")
    setpassword("")
    setLoad("")
    setErr("")
    setErr2("")
  };

  return (
    <div>
       
      <Dialog
        fullScreen
        open={OpenDialog}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className='appBar-preview' sx={{ position: 'relative'}}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1}} variant="h6" component="div">
            <img style={{width:'150px'}} src='/assets/illustrations/logocrop.png' alt='mastermindtech'/>  
            </Typography>
            <h3 className='h3-text-passwrd-recry' style={{textAlign:"center",position:'absolute',}}>Password Recovery</h3>
            
            <Link style={{textDecoration:'none',color:'white'}} to="/login">
            <Button style={{fontWeight:"800"}}  autoFocus color="inherit" onClick={handleClose}>
              Login
            </Button>
            </Link>
          </Toolbar>
        </AppBar>
           {
            Loader &&  <Loading/>
          } 
       

         <div className='main-preview-con'>
          <div className='general-width-veiw' >
          <div className='PreviewCon'>
            <div className='signUp-con'>
               
                <h3> Receive recovery code</h3>
                 <p style={{color:"green",fontWeight:"800"}}>{Err2 !== "" && Err2}</p>
               {
                Progress === "step1" &&

                <div className='passwrd-recory-con'>
                    
                <input 
                 value={email}
                 onChange={(e)=>setemail(e.target.value)}   
                 placeholder='Enter Email'                 
                 />
                  <span style={{color:'red'}}>{Err !== "" && Err}</span> 
             <Button className='Hover-elemnt' elevation={10} sx={{whiteSpace: 'nowrap' , fontSize: '14px',marginRight:'5px',   backgroundColor:'rgb(92, 12, 12)',width:"100%"}} onClick={()=>handleSubmit()}  size="small" variant="contained" endIcon={''}>
                {Load ? "Loading...":"verify"}
             </Button> 
             

                </div>
               }

              
                 {
                Progress === "step2" &&
                <div className='passwrd-recory-con'>
                    
                <input 
                 value={code}
                 onChange={(e)=>setcode(e.target.value)}   
                 placeholder='Enter code'                 
                 />

                  <input 
                 value={password}
                 onChange={(e)=>setpassword(e.target.value)}   
                 placeholder='Enter new password'                 
                 />

                  <span style={{color:'red'}}>{Err !== "" && Err}</span>
             <Button className='Hover-elemnt' elevation={10} sx={{whiteSpace: 'nowrap' , fontSize: '14px',marginRight:'5px', backgroundColor:'rgb(92, 12, 12)',width:"100%"}} onClick={()=>handleSubmit()}  size="small" variant="contained" endIcon={''}>
             {Load ? "Loading...":"Change Password"}  
             </Button> 
             

                </div>
                }





             
            </div>
          </div>

          </div>

         </div>

      </Dialog>
    </div>
  );
}