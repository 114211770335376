/* eslint-disable no-unused-vars */
import React, { useContext } from 'react'
import { Outlet,Navigate} from 'react-router-dom'
import { ContextData } from '../../../../Context/Context'


const useAuth = () =>{

    const {AdministratorTokens} = useContext(ContextData)
    return AdministratorTokens !== null && AdministratorTokens
}

function DashPrivateRoute() {
    
    const IsAuth = useAuth()
    const {AdministratorTokens} = useContext(ContextData)
    return <>{AdministratorTokens !==null && AdministratorTokens ? <Outlet /> : <Navigate to="/cpanel-login"/>}</>

}

export default DashPrivateRoute


