/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState,useContext,useEffect } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from 'axios';
// eslint-disable-next-line no-unused-vars
import { io } from 'socket.io-client';
import Divider from '@mui/material/Divider';
import baseUrl from '../../../../BaseURL';
import { ContextData } from '../../../../Context/Context';
import MoreInfo from "./more/MoreInfo"

import GetVideos from './GetVideos';
import Certificate from './Certificate';
import InnerLoarder from '../../../../InnerLoarder';

const ShowClass = (props) => {

  
  const {PersonKeys,Person,ChangeFetchCompleted} = useContext(ContextData)

  const [Data,setData] = useState(null)
  const [AlertText,setAlertText] = useState(true)
  const [Loader,setLoader] = useState(false)
  
  // Socket io
//  const socket = io(baseUrl)
  const [ReloadData1,setReloadData1] = useState(true)

/*   useEffect(()=>{

    socket.on("reupdate_progress",(arg,callback)=>{  
      setReloadData1(!ReloadData1)
     })


  },[socket]) */


  useEffect(()=>{

      async function FetchData(){

        setLoader(true)
        setAlertText(false) 
          try { 
              
             const response = await axios.get(`${baseUrl}/auth/get_record_after_payment/${Person.st_tokens}/${props.value}`,{
              withCredentials: true,
              headers: {
                'Accept' : 'application/json',
                'authorization': `Bearer ${PersonKeys !== null && PersonKeys}`
              }})

         //   console.log(response.data)
              if(response.data.status){
                setData(response.data.data)
                setAlertText(false) 
                setLoader(false)
              }

              if(!response.data.status){
                setAlertText(true) 
                setLoader(false)
              }



          } catch (error) {  
             console.log(error.message)
             setLoader(false)
          }
  
      }
     
      FetchData() 
      
      
  },[])
  

  
  return ( 
     <>
       {
        AlertText && <h3 style={{textAlign:"center"}}>{ChangeFetchCompleted === 1 ?"Completed Program / Course, No available list" :  "No Assign Program / Course, available list" }</h3>
       }

         {
         !Loader &&
    
          Data !== null && Data.map((list,index) => (
          
        <Accordion key={index} style={{ marginTop: '20px',background:`linear-gradient(20deg,rgb(8, 3, 90),rgb(14, 54, 13),rgb(38, 21, 54))`  }}>
          
          <AccordionSummary style={{margin:"0 2em"}} expandIcon={<ExpandMoreIcon />}>
             <Typography   sx={{color:"white",display:"flex" , gap:"1em",whiteSpace:"nowrap"}} align='center' variant="h5"><b>{list.ProductType}:</b> {list.ProdcutName}
    
               <MoreInfo
               comstatus={list.com_status}
               duration={list.duration}
               uploaddate={list.upload_date}
               endAt={list.endAt}
                />
            
              <Divider  orientation="vertical" sx={{backgroundColor:"white"}} variant="inset"  />
            </Typography> 
          </AccordionSummary>
          
           <AccordionDetails
              sx={{background:"rgb(241, 241, 241)",borderBottom:" 2px solid rgba(0, 0, 0, 0.7)"}}
            >
               
               {
                list.com_status === 0 ? 
                <GetVideos dataset={list}/> :
                 <Certificate
                 dataset={list}
                 /> 
               }
             
           </AccordionDetails>
        
    
        </Accordion>
    
            )
           )
         
         }

         {
         Loader &&
          <div className='inner-int'>
              <div className='inner-int-inner'>
              <InnerLoarder/>
              </div>  
          </div>
        }
     
     </>
  ); 
};

export default ShowClass;
   