/* eslint-disable no-unused-vars */
import { useState,useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
// @mui
import {Stack, IconButton, InputAdornment, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/iconify';
import { ContextData } from '../../../../Context/Context';
import baseUrl from '../../../../BaseURL';
import AlertBox from '../../../layouts/dashboard/header/AlertBox';
import Loading from '../../../layouts/dashboard/header/Loading';


// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({
       status:""
  });
  const [handleError,sethandleError] = useState({
      status:'',
      textStatus:''
  })
  const {setLoader,Loader,setAdministratorTokens,setUserToken} = useContext(ContextData)
  const [userToken,setuserTokens] = useState(null)
  const [formData, setFormData] = useState({
    password: '',
    email: '',
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
  };

  const handleClick = () => {
     
    if(formData.email.trim() === ""){

      setErrors({
         status:"Email Field cannot be empty"
      })
    }else if(formData.password.trim() === ""){
      setErrors({
        status:"Password Field cannot be empty"
     })
    }else{

      setErrors({status:""})
      sethandleError({
        status:"",
        textStatus:""
      })
      setLoader(true)
     
      axios.post(`${baseUrl}/dash/dash_admin_users_login`,{
        password: formData.password,
        email: formData.email
      },{
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
            'Accept':'*'
            }
      }).then(res=>{

         if(!res.data.status){
            
             setErrors({
              status: res.data.statusText
             })
             setLoader(false)

         }
           
          if(res.data.status){
            
             // console.log(res.data.accessToken)
              setAdministratorTokens(res.data.accessToken)
            //  setuserTokens(res.data.userToken)
              setUserToken(res.data.userToken)
              setLoader(false)
              // Call function
             // FetchData()
              navigate('/cpanel/app', { replace: true });

          }
           
      }).catch(err=>{
          if(err) console.log(err.message)
          sethandleError({
            status:"error",
            textStatus:err.message
          })
          setLoader(false)

      })



    }

   
  };


  return (
    <>
      {
        handleError.status !== "" && 
        <AlertBox
        Status={handleError.status}
        textStatus={handleError.textStatus}
       />
      }
   
      <Stack spacing={3}>
        
      <span style={{color:"red"}}> {errors.status === "Email or Password incorrect" && errors.status}</span>
        <TextField 
        value={formData.Email}
        name="email" 
        label="Email address"
        onChange={handleInputChange}
        />
        <span style={{color:"red"}}> {errors.status === "Email Field cannot be empty" && errors.status}</span>
        <TextField
          name="password"
          label="Password"
          value={formData.Password}
          onChange={handleInputChange}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <span style={{color:"red"}}>{errors.status === "Password Field cannot be empty" && errors.status}</span>
        
      </Stack>

      <LoadingButton fullWidth className='btncolor fontStyle' size="large" type="submit" variant="contained" onClick={handleClick}>
          {Loader ? "Loading..." :  "Login" }
      </LoadingButton>
      
     
    </>
  );
}
