import React, { useContext } from 'react'
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import baseUrl from '../../../../BaseURL';
import { ContextData } from '../../../../Context/Context';
import PreloaderFunc from '../../../../PreloaderFunc';

function InstructorPreview() {




  const {id} = useParams()
  const [InsData,setInsData] = React.useState(null)
  const {PersonKeys} = useContext(ContextData)

   
  React.useEffect(()=>{



      async function FetchData(){

          try { 
              
             const response = await axios.get(`${baseUrl}/client/get_instructor_class_data/${id}`,{
              withCredentials: true,
              headers: {
                'Accept' : 'application/json',
                'authorization': `Bearer ${PersonKeys !== null && PersonKeys}`
              },
             })
           //  console.log(response.data[0])
             setInsData(response.data)
          } catch (error) {
             console.log(error.message)
          }

      }

    FetchData()



// eslint-disable-next-line react-hooks/exhaustive-deps
},[id])




  return (
    <div className='InstructorPreview'>

          {
        InsData !== null && InsData.map((item,index)=>
        (   

          <div key={index} className='img-InstructorPreview-con'>
          <img src={`${baseUrl}/product_image/assets/ProductImages/${item.ins_picture}`} alt='Instructor'/>
          </div>
     

        ))

        }
   
         
         {
            InsData === null && <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}> <PreloaderFunc/></div>
          }
       

     <div>
      {
        InsData !== null && InsData.map((item,index)=>
        (

        <div key={index} className='InstructorPreviewViewText-con'>
          <dl>
          <dt className='textcolor' style={{marginBottom:'4px'}}><b>INSTRUCTOR</b></dt>
          <dd><b className=''>Name:</b> {item.instructorName}</dd>
          <dd><b>Profession:</b> {item.profession}</dd>
          <dd><b>Experience:</b> {item.experience}{item.experience < 2 ? 'yr': 'yrs'}</dd>   
       </dl>
       </div>


        ))  
        
      }

        {
            InsData === null && <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}> <PreloaderFunc/></div>
          }
       


   {/* 
   



   
   
   */}
   <div className='InstructorPreview-supernova-social-con'>
          <dl>
          <dt className='textcolor' style={{marginBottom:'4px'}}><b>MastermindTech Social Media Pages</b></dt>

          <dd><Link to="https://web.facebook.com/profile.php?id=100091581297282"><b style={{display:"flex",alignItems:'center'}}><FacebookIcon sx={{color:'blue',fontSize:'2em'}} />Facebook</b></Link></dd>

          <dd><Link to="https://twitter.com/MastermindTech9"><b style={{display:"flex",alignItems:'center'}}><TwitterIcon sx={{color:'lightblue',fontSize:'2em'}} />Twitter</b></Link></dd>

          <dd><Link to="https://www.instagram.com/mastermindtech_ng/"><b style={{display:"flex",alignItems:'center'}}><InstagramIcon sx={{color:'pink',fontSize:'2em'}} />Instagram</b></Link> </dd>
          <dd><Link to="https://www.linkedin.com/in/mastermindtech-mastermindtech-591095251/"><b style={{display:"flex",alignItems:'center'}}><LinkedInIcon sx={{color:'blue',fontSize:'2em'}} />LinkedIn</b></Link></dd>

     </dl>  
   </div>

      </div>
            



 </div>

  )
}

export default InstructorPreview