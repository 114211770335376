import React,{useState} from 'react'
import { TypeAnimation } from 'react-type-animation';

function WelcomText() {

    const [textColor, setTextColor] = useState('rgb(92, 12, 12)');
  return (
    <>
    <div
      style={{
        fontSize: '3vw',
        color: textColor,
        fontWeight:300,
        fontFamily:`'Anton', sans-serif`
      }}
      className='welcome-text-con'
    >
      <TypeAnimation
        sequence={[
          'MastermindTech',
          800,
          () => setTextColor('rgb(27, 47, 47)'),
          'Creative Technology Center',
          800,
          () => setTextColor('rgb(50, 5, 29)'),
          'Your Success is Our First Priority',
          1000,
          () => setTextColor('rgb(39, 37, 10)'),
          '',
        ]}
        repeat={Infinity}
      />
    </div>
   
  </>
  )
}

export default WelcomText