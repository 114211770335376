/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */

import React, { useState,useEffect } from "react";
import axios from "axios";
import LocalPostOfficeIcon from '@mui/icons-material/LocalPostOffice';
import { Button } from "@mui/material";
import Assessment from "./Assessment";
import { ContextData } from "../../../../../Context/Context";
import baseUrl from '../../../../../BaseURL';
import PopMessageDialog from "./Messages/PopMessageDialog";


function SubAssViewAssCon(props){
   
    const {data,moduleToken,videoPath2Exis,moduleTile,moduleNumber,ProdcutToken,assignDes} = props
    const {PersonKeys,Person,ReloadData}= React.useContext(ContextData)
    const [UnderReviewStatus,setUnderReviewStatus] = useState(null)
    const [Mst,setMst] = useState(null)
 
    useEffect(()=>{ 

      async function FetchData(){
          try {  
             const response = await axios.get(`${baseUrl}/auth/under_review/${Person.st_tokens}/${moduleToken}`,{
              withCredentials: true,
              headers: {
                'Accept' : 'application/json',
                'authorization': `Bearer ${PersonKeys !== null && PersonKeys}`
              }})
              setUnderReviewStatus(response.data)   
              setMst(response.data.rejct_descrp)  
             /*  console.log(Mst)  */      
          } catch (error) {  
             console.log(error.message)
          }
      }  
      FetchData()     
  },[ReloadData])
  
  
   //   {UnderReviewStatus !== null && UnderReviewStatus}
    return(
       
       <div className='ass-sub-com'>
          <div style={{position:"absolute",right:"2em",top:"15px"}}>
            
            {
            Mst !== null &&
            <span title='Click New Message'>
             <PopMessageDialog
               Mst={Mst}
             />
            </span>
            }

            {
            Mst === null &&
            <span title='No Message'>
            <LocalPostOfficeIcon
            sx={{fontSize:"3em",color:"white"}}
             />
            </span>
            }
            </div>
        {
        videoPath2Exis !== null &&
         <>
         <Assessment
           title="Assmnt"
           status="Assessment Task"
           data={data}
           moduleToken={moduleToken}
           assignDes={assignDes}
         />
         
         {
         UnderReviewStatus !== null && UnderReviewStatus.asst_status === 1 ?  
          <Button style={{color:"white",width:"240px",whiteSpace:"nowrap",marginBottom:"10px",backgroundColor: "rgb(130,0,0)"}} variant="outlined">
            Under Review...
          </Button> : ""
         }

        {
         UnderReviewStatus !== null && UnderReviewStatus.asst_status === 2 ?  
          <p style={{color:"red",width:"240px",whiteSpace:"nowrap",marginBottom:"10px",fontWeight:"800",textAlign:"center"}}>
            Rejected 
          </p> : ""
         }

         {
         UnderReviewStatus !== null && UnderReviewStatus.asst_status === 3   ?  
         <p style={{color:"green",width:"240px",whiteSpace:"nowrap",marginBottom:"10px",fontWeight:"800",textAlign:"center"}}>
            Approved 
          </p> : ""
         }
           
        {(UnderReviewStatus !== null && UnderReviewStatus.asst_status === 1 || UnderReviewStatus !== null && UnderReviewStatus.asst_status === 3  ) ? "" :
           <Assessment
              title="btn"
              status="Submit Assessment Task"
              data={data}
              moduleToken={moduleToken}
              moduleTile={moduleTile}
              moduleNumber={moduleNumber}
              ProdcutToken={ProdcutToken}
              
          />
          }
          
         </> 
      }
      {
            videoPath2Exis === null && "No Assessment for this module"
      }
      
     </div> 
    
    )


}

export default SubAssViewAssCon;
