/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import { Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Typography, IconButton,Button} from '@mui/material';
import { Search as SearchIcon, ArrowBack as ArrowBackIcon, ArrowForward as ArrowForwardIcon } from '@mui/icons-material';
import axios from 'axios';
import baseUrl from '../../../../../BaseURL';
import { ContextData } from '../../../../../Context/Context';


const AssignList = () => {
  const [searchText, setSearchText] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [StudentData,setStudentData] = useState(null)
  const [CountData,setCountData] = useState(0)
  const [Update,setUpdate] = useState(true)
  const rowsPerPage = 10;
  const {setLoader,AdministratorTokens,ReloadAssignments,setReloadAssignments} = useContext(ContextData)


  useEffect(()=>{
      

      async function FetchData(){
                setLoader(true)  

            try {
              const response = await axios.get(`${baseUrl}/auth/get_all_record_after_payment`,{
                withCredentials: true,
                headers: {
                  'Accept' : 'application/json',
                  'authorization': `Bearer ${AdministratorTokens !== null && AdministratorTokens}`
                }}) 
              setCountData(Object.keys(response.data))
              setStudentData(response.data)
              setLoader(false)
             
            } catch (err) {
               if(err) console.log(err.message)
               setLoader(false)
            }

           
      }FetchData()


  },[Update,ReloadAssignments])


  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };
  
     const List =  Object.keys(StudentData !== null && StudentData).map(data=> StudentData[data])
     const filteredUsers = List.filter(user => {
     const lowerCaseSearchText = searchText.toLowerCase().trim();
    return (
     
      user.email.toLowerCase().includes(lowerCaseSearchText) 
      // Add more fields for search if needed (e.g., state, phone number)
    );
  });
  


  const totalPages = Math.ceil(filteredUsers.length / rowsPerPage);
  const indexOfLastUser = currentPage * rowsPerPage;
  const indexOfFirstUser = indexOfLastUser - rowsPerPage;
  const usersToShow = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

  const handleNextPage = () => {
    setCurrentPage(prevPage => prevPage + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage(prevPage => prevPage - 1);
  };


  const HandleClick = async(click,token) =>{


    try {
      const response = await axios.put(`${baseUrl}/auth/update_click_getrecordpayment`,{click,token},{
        withCredentials: true,
        headers: {
          'Accept' : 'application/json',
          'authorization': `Bearer ${AdministratorTokens !== null && AdministratorTokens}`
        }})  
    } catch (err) {
       if(err) console.log(err.message)
       setLoader(false)
    }

            
  }

  const HandleRemove = async (token,prodcuttoken) =>{


    try {
        const response = await axios.get(`${baseUrl}/auth/delete_record_after_payment/${token}/${prodcuttoken}`,{
          withCredentials: true,
          headers: {
            'Accept' : 'application/json',
            'authorization': `Bearer ${AdministratorTokens !== null && AdministratorTokens}`
          }}).then(response =>setReloadAssignments(!ReloadAssignments))
          // response.data.
         
      } catch (err) {
         if(err) console.log(err.message)
         setLoader(false)
      }


     setUpdate(!Update)


  }




  return (
    <Container>

      <Typography variant="h4" gutterBottom>
        Assigned Student List
      </Typography>

      <TextField
        label="Search"
        variant="outlined"
        value={searchText}
        onChange={handleSearchChange}
        InputProps={{
          endAdornment: (
            <IconButton>
              <SearchIcon />
            </IconButton>
          ),
        }}
        fullWidth
        margin="normal"
      />
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>

              <TableCell>Email</TableCell>
              <TableCell>Product Name</TableCell>
              <TableCell>Language </TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Unassigned</TableCell>

            </TableRow>
          </TableHead>
          <TableBody>
            {usersToShow.map((user) => (
              <TableRow className={user.click === 0 ? 'newpay' : ""} key={user.id}>
                
                <TableCell className={user.click === 0 ? 'newpaycolor' : ""}>{user.email}</TableCell>
                <TableCell className={user.click === 0 ? 'newpaycolor' : ""}>{user.ProdcutName}</TableCell>
                <TableCell className={user.click === 0 ? 'newpaycolor' : ""}>{user.LangStatus}</TableCell>
                <TableCell className={user.click === 0 ? 'newpaycolor' : ""}>{user.upload_date}</TableCell>
                <TableCell>
                 <Button className='Hover-' onClick={()=> HandleRemove(user.userToken,user.ProdcutToken)} elevation={10} sx={{whiteSpace: 'nowrap' , fontSize: '14px', backgroundColor:'rgb(199, 1, 1)', margin:'9px 0px'}}  size="large" variant="contained">
                   Unassigned
                 </Button>
                 </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {totalPages > 1 && (

        <div>
          {currentPage > 1 && (
            <IconButton onClick={handlePrevPage}>
              <ArrowBackIcon />
            </IconButton>
          )}
          <span>Page {currentPage} of {totalPages}</span>
          {currentPage < totalPages && (
            <IconButton onClick={handleNextPage}>
              <ArrowForwardIcon />
            </IconButton>
          )}
        </div>

      )}

    </Container>
  );
};

export default AssignList;
