import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
// @mui
import { Grid } from '@mui/material';

import ProductCard from './ProductCard';


// ----------------------------------------------------------------------

ProductList.propTypes = {
  products: PropTypes.array.isRequired,
};

export default function ProductList({ products, ...other }) {


  return (
    <Grid container spacing={3} {...other}>
      {Object.keys(products).map((product,index) => (
        <Grid key={index} item xs={12} sm={6} md={3}>
          {/* <ShopProductCard product={product} /> */}
          <Link to={`/cpanel/product_card/${products[product].product_token}/${products[product].publish}/${products[product].product_location}/${products[product].product_name}`}>
            {product.fee}
          <ProductCard
           product={products[product]}
          />
          </Link>

          
        </Grid>
      ))}
    </Grid>
  );
}
