/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import * as React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
// eslint-disable-next-line no-unused-vars
import { io } from 'socket.io-client';
import CloseIcon from '@mui/icons-material/Close';
import MarkEmailUnreadIcon from '@mui/icons-material/MarkEmailUnread';
import { ContextData } from "../../../../../../Context/Context";
import baseUrl from '../../../../../../BaseURL';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {


  const { children, onClose, ...other } = props;


  
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function PopMessageDialog(props) {

  const [open, setOpen] = React.useState(false)
  const {PersonKeys,Person,setUnderReviewStatus}= React.useContext(ContextData)
  const {Mst} = props
 
  const messages = [
    { text: 'Hello!', sender: 'sender' },
    { text: 'Hi there!', sender: 'receiver' },
    { text: 'How are you?', sender: 'sender' },
    { text: 'I\'m good, thanks!', sender: 'receiver' },
  ];

 

   // Send to the server to retrieve 
 //  const socket = io(baseUrl)
  const [ReloadData1,setReloadData1] = React.useState(true)

/*   React.useEffect(()=>{


    socket.on("reupdate_progress",(arg,callback)=>{  
      setReloadData1(!ReloadData1)
     })


  },[socket]) */


  React.useEffect(()=>{ 

    async function FetchData(){
        try {  
           const response = await axios.get(`${baseUrl}/auth/under_review/${Person.st_tokens}/${props.status === "Submit Assessment Task" && props.moduleToken}`,{
            withCredentials: true,
            headers: {
              'Accept' : 'application/json',
              'authorization': `Bearer ${PersonKeys !== null && PersonKeys}`
            }})
            setUnderReviewStatus(response.data)   
         //  console.log(UnderReviewStatus)    
                    
        } catch (error) {
           console.log(error.message)
        }
    }  
    FetchData()     
},[ReloadData1])



  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>

       <MarkEmailUnreadIcon
        onClick={handleClickOpen}
        sx={{fontSize:"3em",color:"blue"}}
       />

        <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
          >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
        {props.status} {""}
        </BootstrapDialogTitle>

        <DialogContent dividers>
        
          <div className='mst-con-show'>
          <p style={{whiteSpace:"pre-line"}}>{Mst}</p>
          </div>
        
        </DialogContent>

        <DialogActions>

          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>

        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}

