/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from 'react';
import {AccordionDetails } from '@mui/material';
import CertificateCard from './Certificate/CertificateCard';


const Certificate = (props) => 

 /*   const {ProdcutToken,LangStatus} = props.dataset

    const {PersonKeys,Person} = useContext(ContextData)
 // const {producttokens} = useParams()
    const [Data,setData] = useState(null)
    const [AssStatus,setAssStatus] = useState(null) */

    
    

  /*   useEffect(()=>{

        async function FetchData(){
            try {  
               const response = await axios.get(`${baseUrl}/auth/getvideo/${ProdcutToken}/${LangStatus}`,{
                withCredentials: true,
                headers: {
                  'Accept' : 'application/json',
                  'authorization': `Bearer ${PersonKeys !== null && PersonKeys}`
                }})
             // console.log(response.data)
                setData(response.data)

            } catch (error) {
               console.log(error.message)
            }
        }
       
        FetchData()     
    },[PersonKeys,ProdcutToken,LangStatus])
     */




   (

       <>
    
       
         <AccordionDetails  
           sx={{ 
            boxShadow:"1px 3px 10px rgba(0,0,0,0.4)",
            background:"rgb(231, 231, 231)",
            borderTop:" 2px solid rgba(0, 0, 0, 0.5)",
            marginTop:"10px",
            marginBottom:"10px",
            borderRadius:'7px',
            position:"relative"
            }}>

            <div className='v-info-vi-con'>
            
              <CertificateCard/>

            <div className='v-info-con-inner'>
                
                {/*   <p><span>Status:</span> Completed</p>
                   <p><span>Uploaded Time:</span> {props.dataset.upload_date.slice(props.dataset.upload_date.indexOf("T") + 1)}</p>
                   <p><span>Uploaded Date:</span> {props.dataset.upload_date.slice(0,props.dataset.upload_date.indexOf("T"))}</p>
                   <p><span>Language:</span> {props.dataset.lan}</p> */}

                </div>



                </div>
    
       </AccordionDetails>



       </>

  )

;

export default Certificate;
   