/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState,useContext } from "react";
import {
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  FormHelperText,
} from "@mui/material";
import axios from "axios";
import AlertBox from "../../../layouts/dashboard/header/AlertBox";
import { ContextData } from "../../../../Context/Context";
import baseUrl from "../../../../BaseURL";



const EditForm = (props) => {
   
   
  const [productName, setProductName] = useState(""|| props.data.product_name);
  const [productType, setProductType] = useState(""|| props.data.product_type);
  const [productLocation, setProductLocation] = useState(""||props.data.product_location);
  const [productToken, setproductToken] = useState(props.data.product_token);
  const [productPicture, setProductPicture] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  
  const {setLoader,AlertStatus,setAlertStatus,AdministratorTokens}= useContext(ContextData)
  
  
  const handleSubmit = (event) => {
    event.preventDefault();
    setAlertStatus(null)
    // Validate the inputs before submitting
    if (
      productName.trim() === "" ||
      productType.trim() === "" ||
      productLocation.trim() === "" ||
      productPicture === null
    ) {
      alert("Please fill in all the fields.");
      return;
    }
    
    // Form submission logic here
    // You can send the form data to your backend or perform any other action
      setLoader(true)
      const formData = new FormData()
      formData.append("File",productPicture);
      formData.append("product_location",productLocation);
      formData.append("product_name",productName);
      formData.append("product_type",productType);
      formData.append("productToken",productToken);
  
   
     axios.put(`${baseUrl}/auth/updateproduct`,formData,{
      withCredentials: true,
      headers: {
        'Accept' : 'application/json',
        'authorization': `Bearer ${AdministratorTokens !== null && AdministratorTokens}`
      }}).then(res=>{

        // console.log(res.data)
         setAlertStatus({
          status:"success",
          textStatus: res.data
       })
         setLoader(false)
     }).catch(err=>{

           if(err) console.log(err)
           setAlertStatus({
              status:"error",
              textStatus: err.message
           })
           setLoader(false)
     })
  };

  const handleProductPictureChange = (event) => {
    
    const file = event.target.files[0];
    // Check if a file is selected
    if (file) {
      // Check if the file is an image (you can add more image types if needed)
      if (!file.type.startsWith("image/")) {
        alert("Please select an image file.");
        return;
      }
      // Check if the file size is within the limit (10MB)
      if (file.size > 10 * 1024 * 1024) {
        alert("Image size exceeds the maximum allowed limit (10MB).");
        return;
      }
      setProductPicture(file);
            // Show image preview
            const reader = new FileReader();
            reader.onloadend = () => {
              setImagePreview(reader.result);
            };
            reader.readAsDataURL(file);
      
    }
  };



  return (
   
        <form onSubmit={handleSubmit}>
             {
              AlertStatus !== null  && 
              <AlertBox 
               Status={AlertStatus.status}
               textStatus={AlertStatus.textStatus}
               />
             }
             
          
          <FormControl fullWidth margin="normal" error={productName.trim() === ""}>
            <TextField
              label="Product Name"
              value={productName}
              onChange={(e) => setProductName(e.target.value)}
              required
            />
            {productName.trim() === "" && (
              <FormHelperText error>Please enter a product name.</FormHelperText>
            )}
          </FormControl>
          <FormControl fullWidth margin="normal" error={productType.trim() === ""}>
            <Select
              label="Product Type"
              value={productType}
              onChange={(e) => setProductType(e.target.value)}
              required
            >
              <MenuItem value="Program">Program</MenuItem>
              <MenuItem value="Course">Course</MenuItem>
              <MenuItem value="Workshop">Workshop</MenuItem>
            </Select>
            {productType.trim() === "" && (
              <FormHelperText error>Please select a product type.</FormHelperText>
            )}
          </FormControl>
      
          <FormControl fullWidth margin="normal" error={productLocation.trim() === ""}>
            <Select
              label="Product Type"
              value={productLocation}
              onChange={(e) => setProductLocation(e.target.value)}
              required
            >
              <MenuItem value="Online Class">Online</MenuItem>
              <MenuItem value="Physical Class">Physical Class</MenuItem>
              <MenuItem value="Both">Both</MenuItem>
            </Select>
            {productLocation.trim() === "" && (
              <FormHelperText error>Please select a {productType} location type.</FormHelperText>
            )}
          </FormControl>
          <FormControl fullWidth margin="normal" error={productPicture === null}>
            <input
              type="file"
              accept="image/*"
              onChange={handleProductPictureChange}
              required
            />
            {productPicture === null && (
              <FormHelperText error>Please upload an image (max 10MB).</FormHelperText>
            )}
            {
              imagePreview !== null && 
              
                <div className="post-product-img-preview-con">
                 <img className="post-p-img" src={imagePreview}  alt="imagePreview" />
                 </div>
            }
          </FormControl>
          <Button variant="contained" color="primary" type="submit">
            Update {props.data.product_type}
          </Button>
        </form>
    
  );
};

export default EditForm;
