import PropTypes from 'prop-types';
import React,{useContext} from 'react';
// @mui
import { styled } from '@mui/material/styles';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import { Box, Stack, AppBar, Toolbar, IconButton } from '@mui/material';
import { Link } from 'react-router-dom';
// utils
import { bgBlur } from '../../../utils/cssStyles';
// components
import Iconify from '../../../components/iconify';
//
import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover';
import { ContextData } from '../../../Context/Context';





// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

const HEADER_MOBILE = 64;

const HEADER_DESKTOP = 92;

const StyledRoot = styled(AppBar)(({ theme }) => ({
  ...bgBlur({ color: theme.palette.background.default }),
  boxShadow: 'none',
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${NAV_WIDTH + 1}px)`,
  },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

Header.propTypes = {
  onOpenNav: PropTypes.func,
};

export default function Header({ onOpenNav }) {

    
  const {PersonKeys} = useContext(ContextData)
   

  return (
    <StyledRoot>
      <StyledToolbar>
        <IconButton
          onClick={onOpenNav}
          sx={{
            mr: 1,
            color: 'text.primary',
            display: { lg: 'none' },
          }}
        >
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>

        <Searchbar />
        <Box sx={{ flexGrow: 1 }} />
          <Link style={{textDecoration:"none"}}   to="/home" >
        <p style={{color:'black',display:"flex",marginRight:'20px',cursor: 'pointer'}}><AppRegistrationIcon
          sx={{color:'black'}}
        />
        Home
        </p>

          </Link>
        
    
        <Stack
          direction="row"
          alignItems="center"
          spacing={{
            xs: 0.5,
            sm: 1,
          }}
        >
         
         {/*  <LanguagePopover /> */}

           
          {
            PersonKeys ===  null  &&   
             <Link to='/login'>
            <p title='Login' style={{color:'blue',display:"flex",marginRight:'4px',cursor: 'pointer'}}><ExitToAppIcon
            sx={{color:'blue',fontSize:'1.7em'}}
          />
          </p>
            </Link>
          }
        


         
        {/*   {
            PersonKeys !== null &&   <NotificationsPopover />
          }
         */}
          {
            PersonKeys !== null  &&   <AccountPopover />
          }
        
        </Stack>
      </StyledToolbar>
    </StyledRoot>
  );
}
