/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */

import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem, Avatar, IconButton, Popover } from '@mui/material';
// mocks_
import account from '../../../_mock/account';
import { ContextData } from '../../../Context/Context';
import baseUrl from '../../../BaseURL';

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Home',
    icon: 'eva:home-fill',
  },
  {
    label: 'Dashboard',
    icon: 'eva:person-fill',
  },
 /*  {
    label: 'Settings',
    icon: 'eva:settings-2-fill',
  }, */
];

// ----------------------------------------------------------------------

export default function AccountPopover() {



  const [open, setOpen] = useState(null);
  const {Person,setPersonKeys,PersonKeys,setPerson,UserToken} = useContext(ContextData)
  const navigate = useNavigate();
  
  const handleOpen = (event) => {
    setOpen(event.currentTarget);

    async function FetchData(){
        
      if(PersonKeys.trim() === null || PersonKeys.trim() === undefined || PersonKeys.trim() === "")  return console.log("Empty Key Point")
        try {
            
          const response = await axios.get(`${baseUrl}/auth/get_student_data/${UserToken}`,{
            withCredentials: true,
             headers: {
              'Accept' : 'application/json',
              'Content-Type': 'application/json',
              'authorization': `Bearer ${PersonKeys}`
            }
            } 
            ) 
        //    console.log(PersonKeys)
           setPerson(response.data)
       } catch (err) {
         if(err) console.log(err.message)
       }
    
      }
      
      FetchData() 



  };

  const handleClose = (label) => {
    setOpen(null);
     
    if(label === "Home")  navigate('/home', { replace: true })

    if(label === "Dashboard")  navigate('/dashboard/userprofile', { replace: true })
    
  };

  const handleLogout = async()=>{

        
         try {

             const response = await  axios.get(`${baseUrl}/client/clientlogout`,
              {
                withCredentials: true,
                headers: {
                  'Accept' : 'application/json',
                  'authorization': `Bearer ${PersonKeys !== null && PersonKeys}`
                },
            
              })
             setPersonKeys(null)
             setPerson({})
             navigate('/dashboard/categories', { replace: true })
           //  console.log(response)
         } catch (error) {
              console.log(error)
         }

  } 


  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar src={account.photoURL} alt="photoURL" />
      </IconButton>
    
      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {Person !== undefined && Person.fname}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {Person !== undefined && Person.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem key={option.label} onClick={()=>handleClose(option.label)}>
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          Logout
        </MenuItem>
      </Popover>
    </>
  );
}
