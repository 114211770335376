/* eslint-disable react/prop-types */
// @mui
import { Grid } from '@mui/material';
import CoursesCard from './CoursesCard';


// ----------------------------------------------------------------------


export default function ProductList({data, ...other }) {
  return (
    <Grid container spacing={3} {...other}>
      {Object.keys(data).map((product) => (
        <Grid key={product} item xs={12} sm={6} md={3}>
        <CoursesCard product={data[product]} /> 
        </Grid>
      ))}  
    </Grid>
  );
}
