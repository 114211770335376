import React from 'react'

function InfoPicCard() {
  return (
      <div className='sec-pic-card-con'>

         <div className='sec-info-pic-con'>
            <div className='pic-box-1 box--'/>
            <div className='pic-box-2 box--'/>
           <div className='pic-box-3 box--'/> 
         </div>

         <div className='pic-text-info-con'>
             

             <p>
             MMT’s institute offers a chance for students who are willing to give their time for learning,in order  to help them to brighten their future. Perks given carry you as far as you push it, the basics are taught and enforced, but ultimately it’s up to the student to utilize the skills wisely. 
             </p>


         </div>

      </div>
  )
}

export default InfoPicCard