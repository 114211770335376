import React from 'react'
import InnerTogglerHeader2 from './InnerTogglerHeader2'

function CourseMTextHeader2() {
  return (
    <div style={{justifyContent:'center',alignItems:'center'}} className='inner-TextViewHeader'>
             <div className='InnerTogglerHeader2-Con'>
            <InnerTogglerHeader2/>

             </div>
         </div>
  )
}

export default CourseMTextHeader2