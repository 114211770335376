/* eslint-disable arrow-body-style */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import baseUrl from '../../../../../../BaseURL';
import { ContextData } from '../../../../../../Context/Context';






export default function AccTable(props) {

    const {producttokens} = props
    const [TableData,setTableData] = React.useState(null)
    const {AdministratorTokens,ReloadData} = React.useContext(ContextData)

     


     
    React.useEffect(()=>{



        async function FetchData(){
            try { 
                
               const response = await axios.get(`${baseUrl}/auth/get_instructor_class_data/${producttokens}`,{
                withCredentials: true,
                headers: {
                  'Accept' : 'application/json',
                  'authorization': `Bearer ${AdministratorTokens !== null && AdministratorTokens}`
                }})
             //  console.log(response.data[0])
               setTableData(response.data)
            } catch (error) {
               console.log(error.message)
            }

        }

        FetchData()



},[ReloadData])





  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Instructor Name</TableCell>
            <TableCell align="center">Profession</TableCell>
            <TableCell align="center">Experience</TableCell>
            <TableCell align="center">Picture</TableCell>
          </TableRow>
        </TableHead>
         <TableBody>
            {
              TableData !== null &&  TableData.map((Listnum,index) =>{
                   return(
                    <TableRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {Listnum.instructorName}
                    </TableCell>
                    <TableCell align="center">{Listnum.profession}</TableCell>
                    <TableCell align="center"> {Listnum.experience}</TableCell>
                    <TableCell align="center"><img style={{width:"5em",height:'5em',borderRadius:"100%"}} src={`${baseUrl}/product_image/assets/ProductImages/${Listnum.ins_picture}`} alt='instructor'/></TableCell>
                   
                  </TableRow>
                   )
                   
                   
                })
            }
          
        </TableBody> 
      </Table>
    </TableContainer>
  );
}