/* eslint-disable react/prop-types */
import * as React from 'react';
import List from '@mui/material/List';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import WifiIcon from '@mui/icons-material/Wifi';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import CheckDialog from '../../CheckDialog';
import baseUrl from '../../../../BaseURL';
import { ContextData } from '../../../../Context/Context';
import PreloaderFunc from '../../../../PreloaderFunc';
import MaskText from './MaskText';


export default function Physical(props) {



  const {id,locationtype,productname} = useParams()
  const [PhysicalData,setPhysicalData] = React.useState(null) 
  const {PersonKeys} = React.useContext(ContextData)

   
  React.useEffect(()=>{



      async function FetchData(){

          try { 
              
             const response = await axios.get(`${baseUrl}/client/get_physical_class_data/${id}`,{
              withCredentials: true,
              headers: {
                'Accept' : 'application/json',
                'authorization': `Bearer ${PersonKeys !== null && PersonKeys}`
              },
             })
           //  console.log(response.data[0])
             setPhysicalData(response.data)
          } catch (error) {
             console.log(error.message)
          }

      }

    FetchData()



// eslint-disable-next-line react-hooks/exhaustive-deps
},[id])



  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };



  return (

    <List
      sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
      subheader={<ListSubheader>{props.showtext === "true" ? "The program is available" : ""}</ListSubheader>}
      className='list-con-for-prev-course'
     >

       
          
         {
          PhysicalData !== null && 
          PhysicalData.map((item,index) =>(
              <div key={index}>
                 <ListItem>

             <ListItemIcon>
               <WifiIcon sx={{color:'rgb(92, 12, 12)'}} />
             </ListItemIcon>
             <ListItemText id="switch-list-label-wifi">
       
       
            <ClickAwayListener onClickAway={handleTooltipClose}>
             <div>
               <Tooltip
                 PopperProps={{
                   disablePortal: true,
                 }}
                 onClose={handleTooltipClose}
                 open={open}
                 disableFocusListener
                 disableHoverListener
                 disableTouchListener
                 title={props.title}
               >
              <Button onClick={()=>handleTooltipOpen()}>{props.type}</Button>
              </Tooltip>
             </div>
             </ClickAwayListener>
                
             <ListItemText style={{display:"flex",whiteSpace:"nowrap"}}><b style={{marginLeft:'1em',fontSize:'15px',display:"flex",justifyContent:'center',alignItems:"center",flexDirection:"row",whiteSpace:"nowrap"}}><span style={{marginRight:"6px"}}>Fee:</span>{item.fee ? <MaskText value={item.fee}/> : ""}</b></ListItemText>
                <ListItemText><b style={{marginLeft:'1em',fontSize:'15px'}}>C.H : {item.credit_hours ? `${item.credit_hours} hr Per lecture` : ""}</b></ListItemText>
                <ListItemText><b style={{marginLeft:'1em',fontSize:'15px'}}>Total Credit Hours: {item.total_credit_hours ? `${item.total_credit_hours}hrs` : ""}</b></ListItemText> 
                <ListItemText><b style={{marginLeft:'1em',fontSize:'15px'}}>Duration: {item.duration ? item.duration : ""}</b></ListItemText>
                </ListItemText> 
                </ListItem>
            
               <CheckDialog
                ProductType={locationtype}
                amount={item.fee}
                ProductName={productname}
                ProductToken={id}
              
                />
            
            </div>

            ))
         }

          {
            PhysicalData === null && <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}> <PreloaderFunc/></div>
          }
       

    </List>
  );
}