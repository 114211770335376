// component
import PostAddIcon from '@mui/icons-material/PostAdd';
import PersonIcon from '@mui/icons-material/Person';
import CastForEducationIcon from '@mui/icons-material/CastForEducation';
import ApprovalIcon from '@mui/icons-material/Approval';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import PaymentsIcon from '@mui/icons-material/Payments';
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [ 
  {
    title: 'Products',
    path: '/cpanel/app',
    icon: icon('ic_analytics'),
  },
  {
    title: 'Post Product',
    path: '/cpanel/post_product',
    icon: <PostAddIcon/>,
  },
  {
    title: 'Students',
    path: '/cpanel/student',
    icon: <PersonIcon/>,
  },
  {
    title: 'Instructors',
    path: '/cpanel/intructor',
    icon: <CastForEducationIcon/>,
  },
  {
    title: 'Certificte',
    path: '/cpanel/certificate',
    icon: <ApprovalIcon/>,
  },
  {
    title: 'Workshop Attendee',
    path: '/cpanel/WorkshopAccounts',
    icon: <AccountBoxIcon/>,
  },
  {
    title: 'Assign',
    path: '/cpanel/assign',
    icon: <DoneAllIcon/>,
  },
  {
    title: 'validate Student',
    path: '/cpanel/validate',
    icon: <DoneAllIcon/>,
  },
  {
    title: 'Progress',
    path: '/cpanel/progress',
    icon: icon('ic_analytics'),
  },
  {
    title: 'Payment',
    path: '/cpanel/payment',
    icon: <PaymentsIcon/>,
  },
  {
    title: 'Contact Us',
    path: '/cpanel/contact-us',
    icon: <CastForEducationIcon/>,
  },
  {
    title: 'user',
    path: '/cpanel/user',
    icon: icon('ic_user'),
  },
  {
    title: 'blog',
    path: '/cpanel/blog',
    icon: icon('ic_blog'),
  },
];

export default navConfig;
