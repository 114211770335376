import React from 'react'
import ListedReadyForValidation from '../sections/@dashboard/ValidateStudent/ListedReadyForValidation'

function ValidateCartificate() {
  return (
    <div className='post_product-con'>
      
     <ListedReadyForValidation/>

    </div>
  )
}

export default ValidateCartificate