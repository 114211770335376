import PropTypes from 'prop-types';
import { useContext, useEffect } from 'react';
import { useLocation ,Link} from 'react-router-dom';
// @mui
import { styled, alpha } from '@mui/material/styles';
import { Box, Drawer, Typography, Avatar, Stack } from '@mui/material';
// mock
import account from '../../../_mock/account';
// hooks
import useResponsive from '../../../hooks/useResponsive';
// components

import Scrollbar from '../../../components/scrollbar';
import NavSection from '../../../components/nav-section';
import { ContextData } from '../../../Context/Context';



// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

const StyledAccount = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

// ----------------------------------------------------------------------

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default function Nav({ openNav, onCloseNav }) {
  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'lg');
  const {Person,PersonKeys} = useContext(ContextData)

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <Box sx={{height:'auto'}}>
      <Stack alignItems="center" spacing={1} sx={{ pt: 5, borderRadius: 2, position: 'relative' }}>
      <img style={{width:"80%",height:'5em'}} src="/assets/illustrations/logocrop.png" alt="MastermindTech"/> 
      </Stack>
      </Box>
     <Link style={{textDecoration:'none'}} to="/dashboard/userprofile">
      <Box elevation={12} sx={{ mb: 5, mx: 2.5 , background:`linear-gradient(30deg,rgb(54, 1, 61),
    rgb(0, 10, 53))`,borderRadius:'4px'}}> 
          <StyledAccount>
            <Avatar src={account.photoURL} alt="photoURL" />

            <Box sx={{ ml: 2 }}>
              <Typography variant="subtitle2" sx={{ color: 'white' }}>
                {/* {account.displayName} */}
               <span style={{fontSize:'1.5em',whiteSpace:'nowrap'}} className='fontStyle'>View Dashboard</span>
              </Typography>

              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {
                  Person.fname === undefined  ? "Loading..." :
                  <>  {PersonKeys !== null && `Logged in ${Person.fname}`}</>
                }
              </Typography>
            </Box>
          </StyledAccount> 
      </Box>
       
       </Link>

      <NavSection/>

      <Box sx={{ flexGrow: 1 }} />

    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 }, 
        width: { lg: NAV_WIDTH },
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              bgcolor: 'background.default',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: { width: NAV_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
