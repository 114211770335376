/* eslint-disable react/button-has-type */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React from 'react';
import FileDownload from "js-file-download";
import { useParams } from 'react-router-dom';
import axios from 'axios';
import baseUrl from '../../../../../../BaseURL';
import { ContextData } from '../../../../../../Context/Context';


const DescriptionDetails = (props) => {


    const {AdministratorTokens} = React.useContext(ContextData)
    const {productname} = useParams()

     const GetOutline = async(id)=>{
        try {
        const response = await axios.get(`${baseUrl}/pub/downloadoutline/${id}`,{
         withCredentials: true,
         headers: {
           'Accept' : 'application/json',
          'authorization': `Bearer ${AdministratorTokens !== null && AdministratorTokens}`
         },
         responseType: "blob",
        })
        FileDownload(response.data,`MMT_outline_file`);
  } catch (error) {
     console.log(error)
  }
}




  return (

         <p
         style={{background:"white",color:"black",padding:"10px",whiteSpace:'pre-line'}}>
            {props.item.descriptn}
            <button style={{color:"white",backgroundColor:'red',padding:'5px',margin:'7px',boxShadow:"0px 0px 10px rbga(0,0,0,0.495)",fontWeight:800,borderRadius:'7px'}}  onClick={()=>GetOutline(props.item.outline_file)} className='dowbtn'>Download outline</button>
         </p>
          ) 
       
};

export default DescriptionDetails;
   