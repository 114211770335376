/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState,useContext } from "react";
import {
  TextField,
  Button,
  FormControl,
  FormHelperText,
} from "@mui/material";
// eslint-disable-next-line no-unused-vars
import { io } from "socket.io-client";
import axios from "axios";
import AlertBox from "../../../../../layouts/dashboard/header/AlertBox";
import { ContextData } from "../../../../../Context/Context";
import baseUrl from "../../../../../BaseURL";



const SubmitAsstTask = (props) => {

  const {moduleTile,moduleNumber,ProdcutToken,moduleToken} = props
  const [descrp, setdescrp] = useState("");
  const [File, setFile] = useState(null);
  
 
 
  
  const {setLoader,AlertStatus,setAlertStatus,PersonKeys,Person,ReloadData,setReloadData}= useContext(ContextData)


  // Send to the server to retrieve 
 // const socket = io(baseUrl)

  


  const handleSubmit = (event) => {

    event.preventDefault();
    setAlertStatus(null)

    // Validate the inputs before submitting
    if (
     
      descrp.trim() === "" ||
      File === null
    ) {
      alert("Please fill in all the fields.");
      return;
    }
  
 
    const formData = new FormData();
   
     setLoader(true)

     formData.append("descrp",descrp)
     formData.append("st_token",Person.st_tokens)
     formData.append("videoFile",File)
     formData.append("module_token",moduleToken)
     formData.append("productToken",ProdcutToken)
     formData.append("moduleTile",moduleTile)
     formData.append("moduleNumber",moduleNumber)

    
      axios.post(`${baseUrl}/auth/client_submit_assessment`,formData,{
        withCredentials: true,
        headers: {
          'Accept' : 'application/json',
          'Content-Type': 'application/octet-stream',
          'Content-Length': 40 * 1024 * 1024,
          'authorization': `Bearer ${PersonKeys !== null && PersonKeys}`
        }}).then(res=>{
  

            if(res.data.status){

              setAlertStatus({
              status:"success",
              textStatus: res.data.textStatus
            }) 

            if(!res.data.status){

                setAlertStatus({
                status:"error",
                textStatus: res.data.textStatus
              }) 
            }

            // update the data after being uploaded 
            setReloadData(!ReloadData)

             // reset the form
             setdescrp("")
             setLoader(false)
             return

            }
       

           setLoader(false)
          
       }).catch(err=>{
  
             if(err) console.log(err)
             setAlertStatus({
                status:"error",
                textStatus: err.message
             })
             setLoader(false)
       })



       // Track the submitted assesment count


      const data ={
        moduleToken,
        ProdcutToken,
        st_tokens:Person.st_tokens
    }    

    axios.post(`${baseUrl}/auth/count_submitted_assmt_activity`,data,{
       withCredentials: true,
       headers: {
         'Accept' : 'application/json',
         'authorization': `Bearer ${PersonKeys !== null && PersonKeys}`
       }}).catch(err=>{
 
            if(err) console.log(err)
             console.log(err.message)
      })



   // let server know about the update
  // socket.emit("update_progress_table","update");



  };


  const handleFileChange = (event) => {
    const filedata = event.target.files[0];

    setAlertStatus({
      status:"",
      textStatus: ""
   })
    // Check if a file is selected
    if (filedata) {

      if (!filedata.type.startsWith('video/')) {
        setAlertStatus({
          status: "error",
          textStatus: "Please upload a valid video file."
        });
        return;
      }
     
      if (filedata.size > 40 * 1024 * 1024) {
        setAlertStatus({
          status:"error",
          textStatus: "Video size exceeds the maximum allowed limit (30MB)."
       })
        return;
      }
      setFile(filedata)    
    }
  };



  return (
   
        <form onSubmit={handleSubmit}>
             {
              AlertStatus !== null  && 
              <AlertBox 
               Status={AlertStatus.status}
               textStatus={AlertStatus.textStatus}
               />
             }
             
             
          <FormControl fullWidth margin="normal" error={descrp.trim() === ""}>
            <TextField
              label="Explain"
              value={descrp}
              onChange={(e) => setdescrp(e.target.value)}
              required
              id="filled-multiline-flexible"
              multiline
              maxRows={10}
              variant="filled"
            />
            {descrp.trim() === "" && (
              <FormHelperText error>Please explain the given task or add description</FormHelperText>
            )}
          </FormControl>
          <FormControl fullWidth margin="normal" error={File === null}>
            <input
              type="file"
              accept="video/*"
              onChange={handleFileChange}
              required
            />
            {File === null && (
              <FormHelperText error>Please upload video (max file-size 40MB).</FormHelperText>
            )}
          </FormControl>
          <Button variant="contained" color="primary" type="submit">
            Submit
          </Button>
        </form>
    
  );
};

export default SubmitAsstTask;
