/* eslint-disable react-hooks/exhaustive-deps */
import { Helmet } from 'react-helmet-async';
import { useState,useContext,useEffect} from 'react';
import { Container, Typography ,Stack} from '@mui/material';
// eslint-disable-next-line no-unused-vars
import { io } from 'socket.io-client';

import axios from 'axios';
// components
import { CoursestSort, CoursesList } from '../sections/@dashboard/Courses';
// mock
import { ContextData } from '../Context/Context';
import baseUrl from '../BaseURL';

// ----------------------------------------------------------------------

export default function CoursesPage() {

  const {CourseData,setCourseData,setLoader,PersonKeys} = useContext( ContextData)
  const [ReloadData,setReloadData] = useState(true)
  // const socket = io(baseUrl)


 
/* 
  useEffect(()=>{


    socket.on("reupdate",()=>{
      setReloadData(!ReloadData)
     })


  },[socket]) */


  useEffect(()=>{

    setLoader(true) 

    async function GetData(){   
      try {
           const response = await  axios.get(`${baseUrl}/client/get_all_posted_products_client`,{
            withCredentials: true,
            headers: {
              'Accept' : 'application/json',
             'authorization': `Bearer ${PersonKeys!==null&&PersonKeys}`
            },
           })
           setCourseData(response.data)
         //  console.log(ProductDataList) 
           setLoader(false)
            
      } catch (error) {
          console.log(error.message)
          setLoader(false)
      }
    } 
    GetData()

},[ReloadData]) 

    
  return (
    <>
      <Helmet>
        <title> MastermindTech </title>
      </Helmet>
       
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
        <span style={{color:" rgb(0, 10, 53)"}}> MastermindTech Courses/Programs </span>
        
        </Typography>
           

        <Stack direction="row" flexWrap="wrap-reverse" alignItems="center" justifyContent="flex-end" sx={{ mb: 5 }}>
          <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
           
            <CoursestSort />
          </Stack>
        </Stack>

        <CoursesList
         data={CourseData !== null && CourseData}
        />
       
  
      </Container>
    </>
  );
}
