/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useState,useEffect} from 'react';
import { Outlet } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import Confetti from 'react-confetti'
  
 
//
import Header from './header';
import Nav from './nav';
import Loading from './header/Loading';
import { ContextData } from '../../Context/Context';
import Verify from './Verify';
import ComfirmPay from './header/ComfirmPay';




// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const StyledRoot = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const Main = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const [open, setOpen] = useState(false);
  const {FadeLoad,setFedLoad,Loader,FadeConfetti,FadePayAlert,setFadePayAlert} = useContext(ContextData)

   useEffect(() =>{
         
    const timer =  setTimeout(()=>{
      setFedLoad(false)    
      clearTimeout(timer)
     },2000)
    
   },[])

   useEffect(() =>{
         
    const timer =  setTimeout(()=>{
      setFadePayAlert(false)    
      clearTimeout(timer)
     },9000)
    
   },[FadePayAlert])



  return (
    <StyledRoot>
       {
        FadeLoad && <Loading/> 
       }
       {
        Loader && <Loading/> 
       }

       {

      FadeConfetti &&
        <Confetti
        width={window.innerWidth || 100}
        height={window.innerHeight || 200}
        numberOfPieces={1000}
        gravity={0.2}
         />
       }

       {
         <Verify/> 
       }

       {
        FadePayAlert &&  <ComfirmPay/>
       }
      
      <Header onOpenNav={() => setOpen(true)} />
       
      <Nav openNav={open} onCloseNav={() => setOpen(false)} />

       
      <Main>
        <Outlet />
      </Main>
    </StyledRoot>
  );
}
