import React from 'react'
import AddStudent from '../sections/@dashboard/Assign/AddStudent'

function Assigned() {
  return (
    <div className='post_product-con'>
      <AddStudent/>
    </div>
  )
}  

export default Assigned  