/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState,useContext, useEffect } from "react";
import {
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  FormHelperText,
} from "@mui/material";
import axios from "axios";
import AlertBox from "../../../layouts/dashboard/header/AlertBox";
import { ContextData } from "../../../../Context/Context";
import baseUrl from "../../../../BaseURL";



const EditForm = (props) => {
   
   
  const [productName,setProductName] = useState(""|| props.data.product_name);
  const [productToken,setproductToken] = useState(props.data.product_token);
  const [ModuleToken,setModuleToken] = useState(props.data.ModuleToken)
  const [SearchUser,setSearchUser] = useState("")
  const [ProductDataList,setProductDataList] = useState(null)
  const [langstatus,setlangstatus] = useState("")
  const [ProductDuration,setProductDuration] = useState("")
  
 // const [productType, setProductType] = useState(""|| props.data.product_type);
 // const [productLocation, setProductLocation] = useState(""||props.data.product_location);
 // const [productPicture, setProductPicture] = useState(null);
 


  const {setLoader,AlertStatus,setAlertStatus,AdministratorTokens,ReloadAssignments,setReloadAssignments}= useContext(ContextData)
  
 
     useEffect(()=>{

      async function GetData(){   

        try {
             const response = await  axios.get(`${baseUrl}/auth/get_student_data_byemail/${SearchUser}`,{
              withCredentials: true,
              headers: {
                'Accept' : 'application/json',
                'authorization': `Bearer ${AdministratorTokens!==null&&AdministratorTokens}`
              }})
             setProductDataList(response.data)
         
        } catch (error) {
            console.log(error.message)
            setLoader(false)
        }
      }

      GetData()

     /*  if(SearchUser === ""){
        setProductDataList(null)
      } */

     },[SearchUser])
  
  const handleSubmit = (event) => {
    event.preventDefault();
    setAlertStatus(null)
    // Validate the inputs before submitting
    if (
      productName.trim() === "" || langstatus.trim() === "") {
      alert("Please fill in all the fields.");
      return;
    }
    
    // Form submission logic here
    // You can send the form data to your backend or perform any other action
      setLoader(true)
     // formData.append("product_location",productLocation);
     // formData.append("product_type",productType);

      const data = {
        productName,
        productToken,
        userToken: ProductDataList !== null && ProductDataList.st_tokens,
        langstatus,
        email: ProductDataList !== null && ProductDataList.email,
        ProductType: props.data.product_type,
        ProductDuration,
        ModuleToken
      }
   
     axios.post(`${baseUrl}/auth/record_after_payment`,data,{

      withCredentials: true,
      headers: {
        'Accept' : 'application/json',
        'authorization': `Bearer ${AdministratorTokens !== null && AdministratorTokens}`
      }}).then(res=>{

        // console.log(res.data)
          if(res.data.status){
            setAlertStatus({
             status:"success",
             textStatus: res.data.textStatus
          })
          setLoader(false)
          }

          if(!res.data.status){
            setAlertStatus({
             status:"error",
             textStatus: res.data.textStatus
          })
          setLoader(false)
          }

          setProductDataList(null)
          setlangstatus("")
          setSearchUser("")
          setReloadAssignments(!ReloadAssignments)
          setLoader(false)

     }).catch(err=>{

           if(err) console.log(err)
           setAlertStatus({
              status:"error",
              textStatus: err.message
           })
           setLoader(false)
     })
  };



  return (
   
        <form onSubmit={handleSubmit}>

              {
              AlertStatus !== null  && 
              <AlertBox 
               Status={AlertStatus.status}
               textStatus={AlertStatus.textStatus}
               />
              }
             
 
          <FormControl fullWidth margin="normal" error={productName.trim() === ""}>
            <TextField
              label="Product Name"
              value={productName}
              onChange={(e) => setProductName(e.target.value)}
              required
              disabled
            />
            {productName.trim() === "" && (
              <FormHelperText error>Please enter a product name.</FormHelperText>
            )}
          </FormControl>


          <FormControl fullWidth margin="normal" error={langstatus.trim() === ""}>
            <Select
              label="Language of Instruction"
              value={langstatus}
              onChange={(e) => setlangstatus(e.target.value)}
              required
              >
              <MenuItem value="English">English</MenuItem>
              <MenuItem value="Hausa">Hausa</MenuItem>
              <MenuItem value="Arabic">Arabic</MenuItem>
            </Select>
            {langstatus.trim() === "" && (
             <FormHelperText error>Please Enter Language of Instruction.</FormHelperText>
            )}
          </FormControl>


          <FormControl fullWidth margin="normal" error={ProductDuration.trim() === ""}>
            <TextField
              type="number"
              label="Duration in week count"
              value={ProductDuration}
              onChange={(e) => setProductDuration(e.target.value)}
              required
            />
            {ProductDuration.trim() === "" && (
              <FormHelperText error>Enter Duration in weeks count (eg. 1 month = 4 weeks).</FormHelperText>
            )}
          </FormControl>
         

          <FormControl fullWidth margin="normal" error={SearchUser.trim() === ""}>
            <TextField
              label="Search Email"
              value={SearchUser}
              onChange={(e) => setSearchUser(e.target.value)}
              required
            />
            {SearchUser.trim() === "" && (
              <FormHelperText error>Search for user email.</FormHelperText>
            )}
          </FormControl>
         
            <div>
             
               {
                SearchUser === "" ? "No Data" : 
                ProductDataList !== null &&
               <>
                  <p><b>Fname:</b> {ProductDataList !== null && ProductDataList.fname}</p>
                  <p><b>Lname:</b> {ProductDataList !== null && ProductDataList.lname}</p>
                  <p><b>Email:</b> {ProductDataList !== null && ProductDataList.email}</p>
                  <p><b>country:</b> {ProductDataList !== null && ProductDataList.country}</p>
                  <p><b>Mobile Number:</b> {ProductDataList !== null && ProductDataList.mobileNumber}</p>

                 </>
               }
              
            </div>

             {

          ProductDataList === null ?

            <Button disabled variant="contained" color="primary" type="submit">
            ADD {props.data.product_type}
            </Button>
             :
            <Button   variant="contained" color="primary" type="submit">
             ADD {props.data.product_type}
            </Button>

            }
        </form>
    
  );
};

export default EditForm;
