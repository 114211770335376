/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import * as React from 'react';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

const DemoPaper = styled(Paper)(({ theme }) => ({
  width: 520,
  height: 320,
  padding: theme.spacing(2),
  ...theme.typography.body2,
  textAlign: 'justify',
  whiteSpace:"pre-line"
}));

export default function Description(props) {
  return (
    <div>
        <h3 style={{textAlign:"center"}}>Description</h3>
    <Stack sx={{marginBottom:"4em"}} direction="row" spacing={2}>
       <p style={{whiteSpace:"pre-line"}}>{props.data.descrp}</p>
    </Stack>

    </div>
  );
}