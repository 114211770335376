/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState } from 'react';
import { Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { ContextData } from '../../../Context/Context';
import baseUrl from '../../../BaseURL';

function TotalPhysical() {
    const [physicalRegistrations, setPhysicalRegistrations] = useState(0);
  const {AdministratorTokens} = useContext(ContextData)
  const {producttokens} = useParams()


  React.useEffect(()=>{



    async function FetchData(){

        try { 
            
           const response = await axios.get(`${baseUrl}/auth/countphysicalregistrations/${producttokens}`,{
            withCredentials: true,
            headers: {
              'Accept' : 'application/json',
              'authorization': `Bearer ${AdministratorTokens !== null && AdministratorTokens}`
            },
           })
         // console.log(response.data[0])
         setPhysicalRegistrations(response.data.Total)

        } catch (error) {
           console.log(error.message)
        }


    }

  FetchData()



},[])





  return (
     <Typography variant="h3">{physicalRegistrations}</Typography>
  )
}

export default TotalPhysical;
