/* eslint-disable react/jsx-key */
/* eslint-disable arrow-body-style */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState,useContext,useEffect } from "react";
import {
  Box,
} from "@mui/material";
import axios from "axios";
import { ContextData } from "../../../../Context/Context";
import baseUrl from "../../../../BaseURL";
import Acc from "./Accordion";
import AssignList from "./AssignList/AssignList";


const AddStudent = () => {
  

  const {setLoader,DeleteProductStatus,AdministratorTokens, ReloadAssignments }= useContext(ContextData)
  const [ProductDataList,setProductDataList] = useState(null)
  


  useEffect(()=>{
    setLoader(true) 
    async function GetData(){   
      try {
           const response = await  axios.get(`${baseUrl}/auth/get_all_posted_products`,{
            withCredentials: true,
            headers: {
              'Accept' : 'application/json',
              'authorization': `Bearer ${AdministratorTokens!==null&&AdministratorTokens}`
            }})
           setProductDataList(response.data)
         //  console.log(ProductDataList)
           setLoader(false)
        
      } catch (error) {
          console.log(error.message)
          setLoader(false)
      }
    }
    GetData()

},[DeleteProductStatus,ReloadAssignments])

  return (
    <Box display="flex" justifyContent="flex-start" width="100%">

       <Box maxWidth="70%" height="100%" padding="1rem">
       <AssignList/>
       </Box>


      {/* Right section with accordion */}

     <Box maxWidth="30%" sx={{overflowY:'auto'}} height="50rem" padding="1rem">

            {
             
             ProductDataList !== null &&  Object.keys(ProductDataList).map((data,index)=>{
               //    console.log(ProductDataList) 
            return(
              <Acc
               data={ProductDataList[data]}
               keys={index}
              />
            )

            })
           } 

      </Box> 

   
    </Box>
  );
};

export default AddStudent;
