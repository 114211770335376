import React, { useContext } from 'react'
import PhrasePicSlider from './PhrasePicSlider'
import { ContextData } from '../../../Context/Context'

function Phrase() {


   const {ChangeSliderText} = useContext(ContextData)

   const TextList = [
       
        {
          Text: "MastermindTech: Igniting innovation, empowering software brilliance."
        },
        {
          Text: "MastermindTech Software Institute: Unleash Your Coding Potential!"
        },
        {
          Text: "Elevate Your Skills at MastermindTech Software Institute."
        },
        {
          Text: "Get Industry-Ready with MastermindTech Software Institute's Cutting-Edge Programs."
        }


   ]
  
  
  
   

  return (
    <>


   <div className='phrase-one-con'>

    <div className='phrase-con'>
       <h2>{TextList[ChangeSliderText].Text}</h2>
    </div>

    <div className='phrase-pic-con'>
     <PhrasePicSlider/>
    </div>

   </div>


    </>
  )
}

export default Phrase