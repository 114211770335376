import { useState,useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
// @mui
import {  Stack, IconButton, InputAdornment, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../components/iconify';
import { ContextData } from '../../Context/Context';
import baseUrl from '../../BaseURL';



// ----------------------------------------------------------------------


export default function LoginForm() {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
 
  const [errors, setErrors] = useState({
       status:""
  });
  const [sethandleError] = useState({
      status:'',
      textStatus:''
  })
  const {setWorkshopUserTokens,setLoader} = useContext(ContextData)
  const [formData, setFormData] = useState({
    password: '',
    email: '',
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
  };
// Fetch User data


  
  const handleClick = () => {
     
    if(formData.email.trim() === ""){

      setErrors({
         status:"Email Field cannot be empty"
      })
    }else if(formData.password.trim() === ""){
      setErrors({
        status:"Password Field cannot be empty"
     })
    }else{

      setErrors({status:""})
      sethandleError({
        status:"",
        textStatus:""
      })
      setLoader(true)

      axios.post(`${baseUrl}/client/userworkshop_login`,{
        userpassword: formData.password,
        email: formData.email
      },
      {
        withCredentials: true,
         headers: {
          'Accept' : 'application/json',
          'Content-Type': 'application/json'
          // 'authorization': `Bearer ${AccessToken}`
        }
      
        }  ).then(res=>{

         if(!res.data.status){
            
             setErrors({
              status: res.data.statusText
             })
             setLoader(false)

         }
           
          if(res.data.status){
              
             //  console.log(res.data.userToken)
              setWorkshopUserTokens(res.data.accessToken)
             // setUserToken(res.data.Usertoken)
             // console.log(PersonKeys)
            
                   setLoader(false)
                   // Call function
                   navigate('/workshop/user_account', { replace: true });

          }
           
      }).catch(err=>{
          if(err) console.log(err.message)
          sethandleError({
            status:"error",
            textStatus:err.message
          })
          setLoader(false)
      })

    }

   
  };


  return (
    <>
      
    
      <Stack spacing={3}>
      <span style={{color:"red"}}> {errors.status === "Email or Password incorrect" && errors.status}</span>
        <TextField 
        value={formData.Email}
        name="email" 
        label="Email address"
        onChange={handleInputChange}
        />
        <span style={{color:"red"}}> {errors.status === "Email Field cannot be empty" && errors.status}</span>
        <TextField
          name="password"
          label="Password"
          value={formData.Password}
          onChange={handleInputChange}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <span style={{color:"red"}}>{errors.status === "Password Field cannot be empty" && errors.status}</span>
        
      </Stack>


      <LoadingButton fullWidth className='btncolor fontStyle' size="large" type="submit" variant="contained" onClick={handleClick}>
        Login
      </LoadingButton>
    
   
    </>
  );
}
