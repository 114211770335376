/* eslint-disable no-unused-vars */
import * as React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';


export default function SocialPages2() {
  const [dense, setDense] = React.useState(false);
  const [secondary, setSecondary] = React.useState(false);

  return (
    <Box sx={{ flexGrow: 1, maxWidth: 752 }}>
      
      <List dense={dense}>

                <ListItem>
                  <ListItemAvatar>
                    <Avatar sx={{backgroundColor:'red'}}>
                      <YouTubeIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="+234 913 383 3261"
                    secondary={secondary ? 'Secondary text' : null}
                  />
                </ListItem>

                <ListItem>
                  <ListItemAvatar>
                    <Avatar sx={{background:`blue`}}>
                      <TwitterIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="info@mmt-ng.com"
                    secondary={secondary ? 'Secondary text' : null}
                  />
                </ListItem>
                

                <ListItem>
                  <ListItemAvatar>
                    <Avatar sx={{backgroundColor:'blue'}}>
                      <LinkedInIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="+234 913 383 3261"
                    secondary={secondary ? 'Secondary text' : null}
                  />
                </ListItem>
            


            </List>
    </Box>
  )
}