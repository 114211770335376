import * as React from 'react';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CodeOffIcon from '@mui/icons-material/CodeOff';
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import { Link } from 'react-router-dom';


export default function FooterNav() {



  return (
    <div className='FooterNav-Con'>

    <List
      sx={{ width: '100%', maxWidth: 360, bgcolor: '' }}
      className="FooterNavCard-con"
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader component="div" id="nested-list-subheader">
            <div style={{display:'flex',alignItems:"center"}}>
            <DisplaySettingsIcon
            sx={{color:'black',fontSize:'2em'}}
           /> 
           <div>Navigate</div>
            </div>
        </ListSubheader>
      }
    >
   
      <Link className='link-btn' to={"/dashboard/products"}>
      <ListItemButton>
        <ListItemIcon>
          <CodeOffIcon
            sx={{color:'white'}}
           />
        </ListItemIcon>
        <ListItemText primary="Programs" />
        
      </ListItemButton>
        </Link>


        <Link className='link-btn' to={"/dashboard/categories"}>
      <ListItemButton>
        <ListItemIcon>
          <CodeOffIcon
            sx={{color:'white'}}
           />
        </ListItemIcon>
        <ListItemText primary="Categories" />
      </ListItemButton>
        </Link>



     
    </List>

    </div>
  );
}