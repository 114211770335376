/* eslint-disable react/prop-types */
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ContextData } from "../../Context/Context";



export default function WorkshopAlert(props) {




  const {setOpenWorkshopAlert,openWorkshopAlert}= React.useContext(ContextData)
  


  const handleClose = () => {
    setOpenWorkshopAlert(false);
  };

  return (
    <div >
      <Dialog
        open={openWorkshopAlert}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle  id="alert-dialog-title" >
        You have been successfully checked for the workshop
        </DialogTitle>
        <DialogContent
         className='gen-bg'
        >
          <DialogContentText id="alert-dialog-description"
           className='gen-bg'
          >
          <div className='signup-alert-con'> 
              <p><b>Attendance ID:</b> {props.attendanceid}</p>
           </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Done
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
