/* eslint-disable react/prop-types */
import React from 'react'
import ProductPreview from './ProductPreview';
import InstructorPreview from './Instructor/InstructorPreview';

function CourseDisplayer(props) {

  return (
    <div>

         <div className='PreviewCon'>
              <ProductPreview
               CourseName = {props.CourseName}
              />
              <InstructorPreview/>

          </div>
    </div>
  )
}

export default CourseDisplayer