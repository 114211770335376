/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from 'react'



function ViewAssessment(props) {






  return (
 
      <div style={{width:"100%"}} className=''>
      
        
        <div  style={{width:"20em",display:"flex",justifyContent:"center",alignItems:"center"}} className='videoCon'>

        <video style={{height:"100%",width:"100%"}} controls controlsList="nodownload" className='vi-con-inner'>
       <source  src={`${props.data}`} type="video/mp4"/>
       <track kind="captions" label="English" src="" srcLang="en" default/>
       </video>

        </div>
             
    </div>

  )
}

export default ViewAssessment