import PropTypes from 'prop-types';
// @mui
import { Box, Card, Link, Typography, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import baseUrl from '../../../../BaseURL';




// ----------------------------------------------------------------------
const StyledProductImg = styled('img')({
  top: 10,
  width: '100%',
  height: '100%',
  objectFit: 'contain',
  position: 'absolute',
  padding: 20
});


// ----------------------------------------------------------------------
ProductCard.propTypes = {
  product: PropTypes.object,
};



export default function ProductCard({ product }) {
  const list = {
     ProductName: product.product_name,
     fee: product.fee,  
     productType: product.product_type,
     productPicture: product.product_picture
    } ;

  
 
  return (
    <Card elevation={12} sx={{boxShadow:'3px 2px 10px rgba(0, 10, 53,0.6)'}}>
      <Box sx={{ pt: '100%', position: 'relative' }}>
  

        <StyledProductImg  alt={list.ProductName} src={`${baseUrl}/product_image/assets/ProductImages/${list.productPicture}`} />
      </Box>

      <Stack spacing={2} sx={{ p: 3 }}>
        <Link color="inherit" underline="hover">
          <Typography variant="subtitle2" noWrap>
          <span style={{color:" rgb(0, 10, 53)"}}>{list.ProductName}</span>
          </Typography>
        </Link>
       
        <Stack direction="row" alignItems="center" justifyContent="space-between">
         {/*   <CoursesPreview
            CourseName = {name}
           /> */}
          <Typography variant="subtitle1">
            <Typography
              component="span"
              variant="body1"
              sx={{
                color: 'text.disabled',
              }}
            >
            {list.productType}
            </Typography>
           
          </Typography>
        </Stack>
      </Stack>
    </Card>
  );
}
