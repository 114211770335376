/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from 'axios';
import baseUrl from '../../../../../../BaseURL';
import { ContextData } from '../../../../../../Context/Context';
import DescriptionDetails from './DescriptionDetails';


const DesAccordion = (props) => {



  const {producttokens} = props
  const [TableData,setTableData] = React.useState(null)
  const {AdministratorTokens,ReloadData} = React.useContext(ContextData)
   


  React.useEffect(()=>{

    async function FetchData(){
        try { 
            
           const response = await axios.get(`${baseUrl}/auth/get_description_data/${producttokens}`,{
            withCredentials: true,
            headers: {
              'Accept' : 'application/json',
              'authorization': `Bearer ${AdministratorTokens !== null && AdministratorTokens}`
            }})
        //   console.log(response.data[0])
           setTableData(response.data)
        } catch (error) {
           console.log(error.message)
        }

    }

    FetchData()

},[producttokens,ReloadData])




  return (
    <Accordion style={{ marginTop: '20px' }} className='btncolor'>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h5">Description</Typography>
      </AccordionSummary>
      <AccordionDetails>
      { TableData !== null &&  TableData.map((item,index) =>(
       
          <DescriptionDetails
          item={item}
          key={index}
          />

          ) 
           )}
      </AccordionDetails>
    </Accordion> 
  );
};

export default DesAccordion;
   