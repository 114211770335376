import * as React from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export default function SliderTabs() {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <Box sx={{ bgcolor: 'background.paper', width: "100%" }}>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="inherit"
          variant="fullWidth"
          sx={{background:"rgb(41, 2, 57)"}}
        >
          <Tab label="Our Offline Programs" {...a11yProps(0)} />
          <Tab label="Our Online Programs" {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
        <dl>
        <dt style={{fontWeight:700}}>MMT Offline Programs Be Like </dt>
        <dd>MastermindTech Offline programs offer a comprehensive range of programs designed to equip aspiring software professionals with the necessary skills and knowledge to thrive in the industry. With a strong emphasis on practical training, MastermindTech ensures that students gain hands-on experience and real-world exposure during their time in the programs. The institute boasts a team of highly experienced instructors who bring their industry expertise and insights into the classroom, creating a dynamic learning environment.</dd>
        <dt style={{fontWeight:700}}>Moreover</dt>
        <dd>One of the standout features of MastermindTech's programs is their focus on industry-relevant skills. The institute keeps a close eye on the latest trends and technologies in the software industry, ensuring that their curriculum is up-to-date and aligned with industry demands. From programming languages like Python and Java to web development, mobile app development, and data analytics, MastermindTech covers a wide array of subjects to cater to different career paths within the software field. By blending theoretical knowledge with hands-on projects and practical exercises, the institute prepares its students to tackle real-world challenges and excel in their chosen domains. With a strong track record of successful alumni who have gone on to work for leading tech companies, MastermindTech Offline Software Institute has established itself as a reliable destination for individuals looking to kickstart their software careers.</dd>
        </dl>
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
        <dl>
        <dt style={{fontWeight:700}}>MMT Online Programs Be Like </dt>
        <dd>MastermindTech Online programs offers a diverse range of programs designed to provide individuals with the flexibility and convenience of learning software skills from anywhere in the world. Through their online platform, the institute delivers high-quality educational content and interactive learning experiences to students seeking to pursue a career in software development. With a team of experienced instructors who are well-versed in online teaching methodologies, MastermindTech ensures that students receive comprehensive training and support throughout their online programs.</dd>
        <dt style={{fontWeight:700}}>Moreover</dt>
        <dd>One of the key advantages of MastermindTech's online programs is their accessibility. By eliminating the barriers of geographical location and time constraints, the institute opens up opportunities for individuals who may not have access to traditional offline institutes. The online programs cover a wide range of subjects, including programming languages, web development, software engineering, and data science, among others. Through a combination of video lectures, interactive assignments, and virtual labs, students can acquire practical skills and knowledge that are directly applicable to real-world scenarios. MastermindTech Online Software Institute's commitment to delivering high-quality education online has garnered positive reviews from students worldwide, making it a preferred choice for those seeking flexibility and convenience in their software education journey.</dd>
        </dl>
        </TabPanel>
        
      </SwipeableViews>
    </Box>
  );
}