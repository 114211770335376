/* eslint-disable react/prop-types */
import React, { useState,useContext } from "react";
import {
  TextField,
  Button,
  FormControl,
  FormHelperText,
} from "@mui/material";
import axios from "axios";
// eslint-disable-next-line no-unused-vars
import { io } from 'socket.io-client';
import AlertBox from "../../../../layouts/dashboard/header/AlertBox";
import { ContextData } from "../../../../../Context/Context";
import baseUrl from '../../../../../BaseURL';
 

const SendRecjMssge = (props) => {
  
   
  
 
  const [Description, setDescription] = useState("");

  const {setLoader,AlertStatus,setAlertStatus,AdministratorTokens,ReloadData,setReloadData}= useContext(ContextData)
  


  // Socket io
  // const socket = io(baseUrl)



  
  
  const handleSubmit = (event) => {

    event.preventDefault();
    setAlertStatus(null)
    // Validate the inputs before submitting
    if (
      Description.trim() === ""
    ) {
      alert("Please fill in all the fields.");
      return;
    }
    
    // Form submission logic here
    // You can send the form data to your backend or perform any other action
      setLoader(true)

      const data = {
        productToken: props.data.productToken,
        Description,
        st_token: props.data.st_token,
        module_token : props.data.module_token,
      }
      
     axios.put(`${baseUrl}/auth/update_rjct_description`,data,{
      withCredentials: true,
      headers: {
        'Accept' : 'application/json',
         'authorization': `Bearer ${AdministratorTokens !== null && AdministratorTokens}`
      },
     }).then(res=>{

           if(res.data.status){

             setAlertStatus({
              status:"success",
              textStatus: res.data.textStatus
           })
         //  socket.emit("update_progress_table","update");
           }
       setReloadData(!ReloadData)
       setLoader(false)

        
     }).catch(err=>{

           if(err) console.log(err)
           setAlertStatus({
              status:"error",
              textStatus: err.message
           })
           setLoader(false)
     })
  };



  return (
   
        <form onSubmit={handleSubmit}>

             {
              AlertStatus !== null  && 
              <AlertBox 
               Status={AlertStatus.status}
               textStatus={AlertStatus.textStatus}
               />
              }
           
          <FormControl fullWidth margin="normal" error={Description.trim() === ""}>
            <TextField
              label="Description"
              value={Description}
              onChange={(e) => setDescription(e.target.value)}
              required
              id="filled-multiline-flexible"
              multiline
              maxRows={10}
              variant="filled"
              
            />
            {Description.trim() === "" && (
              <FormHelperText error>Please enter the Description</FormHelperText>
            )}
          </FormControl>

      
          
          <Button variant="contained" color="primary" type="submit">
            Send
          </Button>

        </form>
    
  );
};

export default SendRecjMssge;
