/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import * as React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Edit } from '@mui/icons-material';
// eslint-disable-next-line no-unused-vars
import { io } from 'socket.io-client';
import ViewAssessment from './ViewAssessment';
import SubmitAsstTask from './SubmitAsstTask';
import { ContextData } from "../../../../../Context/Context";
import baseUrl from '../../../../../BaseURL';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {


  const { children, onClose, ...other } = props;


  
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function Assessment(props) {

  const [open, setOpen] = React.useState(false)
  const {PersonKeys,Person,setUnderReviewStatus}= React.useContext(ContextData)
  const {moduleTile,moduleNumber,ProdcutToken} = props

  

   // Send to the server to retrieve 
  // const socket = io(baseUrl)
   const [ReloadData1,setReloadData1] = React.useState(true)

/*   React.useEffect(()=>{


    socket.on("reupdate_progress",(arg,callback)=>{  
      setReloadData1(!ReloadData1)
     })


  },[socket]) */
    

  React.useEffect(()=>{ 

    async function FetchData(){
        try {  
           const response = await axios.get(`${baseUrl}/auth/under_review/${Person.st_tokens}/${props.status === "Submit Assessment Task" && props.moduleToken}`,{
            withCredentials: true,
            headers: {
              'Accept' : 'application/json',
              'authorization': `Bearer ${PersonKeys !== null && PersonKeys}`
            }})
            setUnderReviewStatus(response.data)   
         //  console.log(UnderReviewStatus)    
                    
        } catch (error) {
           console.log(error.message)
        }
    }  
    FetchData()     
},[ReloadData1])



  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>

       
       <Button style={{color:"white",width:"240px",whiteSpace:"nowrap",marginBottom:"10px",backgroundColor: props.title === "Assmnt" ? "rgb(32, 4, 4)":"rgb(12, 12, 38)"}} variant="outlined" onClick={handleClickOpen}>
       
       { props.status === "Assessment Task" && props.status }
       {props.title === "Assmnt" && <Edit/> }
       { props.status === "Submit Assessment Task" && props.status } 

      </Button>

        <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
          >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
        {props.status} {""}
        </BootstrapDialogTitle>

        <DialogContent dividers>
         
         {
         props.title === "Assmnt" &&

         <>
         <ViewAssessment
          data={props.data}  
         />
         
         <div>
            <h3>Description:</h3>
            <p style={{whiteSpace:"pre-line"}}>{props.assignDes}</p>
         </div>
         </>
         
         }

        {
           props.title === "btn" &&
           <SubmitAsstTask
           data={props.data}  
           moduleToken={props.moduleToken}
           moduleTile={moduleTile}
           moduleNumber={moduleNumber}
           ProdcutToken={ProdcutToken}
        
          />
         }
      
        
        </DialogContent>

        <DialogActions>

          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>

        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}

