import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Button from '@mui/material/Button';
import Typography from '@material-ui/core/Typography';
import ShowClass from './Class/ShowClass';
import { ContextData } from '../../../Context/Context';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: 'rgb(41, 2, 57)',
    padding: theme.spacing(3),
  },
  avatar: {
    width: theme.spacing(8),
    height: theme.spacing(8),
  },
  header: {
    marginBottom: theme.spacing(3),
    color: '#fff',
  },
  sectionContainer: {
    marginTop: theme.spacing(4),
  },
  sectionTitle: {
    marginBottom: theme.spacing(2),
    color: '#fff',
  },
  courseItem: {
    marginBottom: theme.spacing(2),
  },
  programItem: {
    marginBottom: theme.spacing(2),
  },
  accordionSummary: {
    backgroundColor: 'rgba(41, 2, 57, 0.8)',
    color: '#fff',
  },
  accordionDetails: {
    backgroundColor: '#fff',
    color: '#333',
  },
}));

const UserPro = () => {

  const classes = useStyles();

  const {setChangeFetchCompleted,ChangeFetchCompleted} = useContext(ContextData)


   // Onclick 
   const  Onclick = () => {

   
      if(ChangeFetchCompleted === 0){
            setChangeFetchCompleted(1)
      }

      if(ChangeFetchCompleted === 1){
          setChangeFetchCompleted(0)
    }

   


   }




   




  return (
  
      <div className='main-userpro-inner-con'>
      
      <Grid container direction="column" alignItems="center" spacing={3}>
        <Grid item>
          <Avatar
            alt="User Avatar"
            src="/path/to/user-avatar.png"
            className={classes.avatar}
          />
        </Grid>
        <Grid item>
          <Typography variant="h5" className={classes.header}>
            MastermindTech
          </Typography>
        </Grid>
        <Grid item container direction="column" className={classes.sectionContainer}>
         <h1  style={{textAlign:"center",fontFamily:"sans-serif"}}>  
            {
              ChangeFetchCompleted  === 0 ? <span>&#9664; Assigned Program / Course / Workshop &#9654;</span> : <span>&#9664; Completed Program / Course / Workshop &#9654;</span>
            }
            </h1>

          <div className='main-user-pro-con'>
          <div>

         <Button onClick={Onclick}  autoFocus style={{color:"white",whiteSpace:"nowrap",marginBottom:"10px",backgroundColor:"rgb(12, 12, 38)"}} >
            
            {
              ChangeFetchCompleted  === 1 ? <span>&#9664; Assigned Program/Course</span> : "Completed Program/Course"
            }
          </Button>

        </div>

            {
          ChangeFetchCompleted === 0 &&   
          <ShowClass
          value={0}
          />
            }

            {
              ChangeFetchCompleted === 1 &&
              <ShowClass
              value={1}
              />
             }

       
                
         </div>  
        </Grid>
      </Grid>

      </div>
    
  );
};

export default UserPro;