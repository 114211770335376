/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
// eslint-disable-next-line no-unused-vars
import { io } from 'socket.io-client';
import { ContextData } from "../../../../../Context/Context";
import baseUrl from '../../../../../BaseURL';
import Description from './Description';
import DisPlayVideo from './DisPlayVideo';
import ViewRejectAssessment from './ViewRejectAssessment';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {


  const { children, onClose, ...other } = props;


  
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function ViewAssessment(props) {

  const [open, setOpen] = React.useState(false)
  const {AdministratorTokens,setLoader }= React.useContext(ContextData)
 

  // Socket io
 // const socket = io(baseUrl)
  const [ReloadData,setReloadData] = React.useState(true)

/*   React.useEffect(()=>{


    socket.on("reupdate_progress",(arg,callback)=>{
      setReloadData(!ReloadData)
     })


  },[socket]) */

 
  async function FetchData(){

      try {  
         const response = await axios.get(`${baseUrl}/auth/under_review/${props.data.st_token}/${props.data.module_token}`,{
          withCredentials: true,
          headers: {
            'Accept' : 'application/json',
            'authorization': `Bearer ${AdministratorTokens !== null && AdministratorTokens}`
          }})
       
      } catch (error) {
         console.log(error.message)
      }
  }  

 // Send resquest to update some columns
  async function UpdateAssesmentColumn(){
      try {  
         const response = await axios.get(`${baseUrl}/auth/assessment_column_update/${props.data.st_token}/${props.data.module_token}/${props.product_token}`,{
          withCredentials: true,
          headers: {
            'Accept' : 'application/json',
            'authorization': `Bearer ${AdministratorTokens !== null && AdministratorTokens}`
          }})
       
      } catch (error) {
         console.log(error.message)
      }
  }  


  
  React.useEffect(()=>{ 

    FetchData() 
    UpdateAssesmentColumn()    
},[ReloadData])
   





// Handle approve

const HandleApprove = ()=>{
   
      const data = {
        module_token: props.data.module_token,
        st_token:  props.data.st_token,
        product_token: props.product_token
      }

  async function FetchData(){
    setLoader(true) 
    try {  
       const response = await axios.put(`${baseUrl}/auth/approved_assmnt`,data,{
        withCredentials: true,
        headers: {
          'Accept' : 'application/json',
          'authorization': `Bearer ${AdministratorTokens !== null && AdministratorTokens}`
        }})
      
          if(response.data.status){
            setLoader(false) 
         //   socket.emit("update_progress_table","update");
          }

    } catch (error) {
       console.log(error.message)
    }
}  
FetchData()     

}



  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>


      <Button onClick={handleClickOpen} style={{color:"white",width:"auto",whiteSpace:"nowrap",marginBottom:"10px", backgroundColor: "rgb(20, 4, 62)"}} variant="outlined"> View Assessment
        </Button> 

        <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
          >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
        {props.status} {""}
        </BootstrapDialogTitle>

        <DialogContent dividers>
         
           <div style={{width:"100%",justifyContent:"center",alignItems:"center"}}>
           <DisPlayVideo
            data={props.data}
           />
           </div>
                  
         <Description
           data={props.data}
         />

        </DialogContent>

        <DialogActions>

          {
           ( props.data.asst_status === 1 || props.data.asst_status === 2 ) &&
           <span title='Approve the student if successfully done well'>

        <Button onClick={HandleApprove} style={{color:"white",width:"auto",whiteSpace:"nowrap",marginBottom:"10px",backgroundColor: "rgb(2, 59, 47)",marginRight:"1em"}} variant="outlined"> Approve
         </Button>
           </span>
          }

          

          {
            props.data.asst_status === 3 &&
        <span title='You can reject to re-approve the student work but the student most re-submit again'>
        <Button disabled  style={{color:"white",width:"auto",whiteSpace:"nowrap",marginBottom:"10px",backgroundColor: "rgba(2, 59, 47, 0.689)",marginRight:"1em"}} variant="outlined"> Approved
         </Button>
         </span>
          }
     
     <span title='Reject student assessment if there is corrections for his/her betterment'>
         <ViewRejectAssessment
           data={props.data} 
           productStatus={props.productStatus}
         />
     </span>
          

          {/* <Button autoFocus onClick={handleClose}>
            Cancel
          </Button> */}


        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}

