/* eslint-disable react/prop-types */
import React from 'react'



function DisPlayVideo(props) {



  return (
 
      <div style={{width:"100%",display:"flex",justifyContent:"center"}} className=''>
      
        
        <div style={{width:"20em",display:"flex",justifyContent:"center",alignItems:"center"}} className='videoCon'>

        <video style={{height:"100%",width:"100%"}} controls controlsList="nodownload" className='vi-con-inner'>
       <source  src={`http://localhost:7000/task/assets/assessment/${props.data.asst_video}`} type="video/mp4"/>
       <track kind="captions" label="English" src="" srcLang="en" default/>
       </video>
      

        </div>

    </div>

  )
}

export default DisPlayVideo