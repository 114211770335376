/* eslint-disable no-unused-vars */
import React, { useState,useContext } from "react";
import {
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  FormHelperText,
} from "@mui/material";
import { io } from "socket.io-client";
import { useParams } from "react-router-dom";
import axios from "axios";
import AlertBox from "../../../../../layouts/dashboard/header/AlertBox";
import { ContextData } from "../../../../../../Context/Context";
import baseUrl from "../../../../../../BaseURL";



const UplaodModuleForm = (props) => {

  const [VideoTitle, setVideoTitle] = useState("");
  const [VideoModule, setVideoModule] = useState("");
  const [descrp, setdescrp] = useState("");
  const [Language, setLanguage] = useState("");
  const [File, setFile] = useState(null);
  const {producttokens} = useParams()
  
//  const socket = io(baseUrl)
  
  const {setLoader,AlertStatus,setAlertStatus,AdministratorTokens,ReloadData,setReloadData}= useContext(ContextData)
  const uploadedbyusertoken= "ewoiioi9000"

  

 /*   useEffect(()=>{
     

  },[socket]) */


  
  const handleSubmit = (event) => {

    event.preventDefault();
    setAlertStatus(null)

    // Validate the inputs before submitting
    if (
      VideoTitle.trim() === "" ||
      VideoModule.trim() === "" ||
      descrp.trim() === "" ||
      Language.trim() === ""
    ) {
      alert("Please fill in all the fields.");
      return;
    }
  
   
     
    const formData = new FormData();
     // formData.append("File", chunk);
     setLoader(true)

      formData.append("VideoTitle",VideoTitle)
      formData.append("VideoModule",VideoModule)
      formData.append("descrp",descrp)
      formData.append("Language",Language)
      formData.append("uploaded_by_user_token",uploadedbyusertoken)
      formData.append("producttokens",producttokens)
      formData.append("videoFile",File)


      axios.post(`${baseUrl}/auth/video`,formData,{
        withCredentials: true,
        headers: {
          'Accept' : 'application/json',
          'Content-Type': 'application/octet-stream',
          'authorization': `Bearer ${AdministratorTokens !== null && AdministratorTokens}`
        }}).then(res=>{
   

            if(res.data.status){

              setAlertStatus({
              status:"success",
              textStatus: res.data.textStatus
            }) 

            // update the data after being uploaded 
              setReloadData(!ReloadData)
           

             // reset the form
             setVideoTitle("")
             setVideoModule("")
             setdescrp("")
             setLanguage("") 
             setLoader(false)
             return

            }
       

           setLoader(false)
         
         
          
       }).catch(err=>{
  
             if(err) console.log(err)
             setAlertStatus({
                status:"error",
                textStatus: err.message
             })
             setLoader(false)
       })


  };


  const handleFileChange = (event) => {
    const filedata = event.target.files[0];

    setAlertStatus({
      status:"",
      textStatus: ""
   })
    // Check if a file is selected
    if (filedata) {
      // Check if the file is an image (you can add more image types if needed)
    /*   if (!filedata.type.startsWith("video/")) {
        alert("Please select file.");
        return;
      }  */
      // Check if the file size is within the limit (10MB)
      if (filedata.size > 400 * 1024 * 1024) {
        setAlertStatus({
          status:"error",
          textStatus: "Video size exceeds the maximum allowed limit (400MB)."
       })
        return;
      }
      setFile(filedata)    
    }
  };



  return (
   
        <form onSubmit={handleSubmit}>
             {
              AlertStatus !== null  && 
              <AlertBox 
               Status={AlertStatus.status}
               textStatus={AlertStatus.textStatus}
               />
             }
             
          
          <FormControl fullWidth margin="normal" error={VideoTitle.trim() === ""}>
            <TextField
              label="Video Title"
              value={VideoTitle}
              onChange={(e) => setVideoTitle(e.target.value)}
              required
            />
            {VideoTitle.trim() === "" && (
              <FormHelperText error>Please enter a video title.</FormHelperText>
            )}
          </FormControl>
          <FormControl fullWidth margin="normal" error={VideoModule.trim() === ""}>
            <TextField
              label="Video module name and number"
              value={VideoModule}
              onChange={(e) => setVideoModule(e.target.value)}
              required
            />
            {VideoModule.trim() === "" && (
              <FormHelperText error>Please enter a video module name and number.</FormHelperText>
            )}
          </FormControl>
         
          <FormControl fullWidth margin="normal" error={Language.trim() === ""}>
            <Select
              label="Language"
              value={Language}
              onChange={(e) => setLanguage(e.target.value)}
              required
            >
              <MenuItem value="English">English</MenuItem>
              <MenuItem value="Hausa">Hausa</MenuItem>
              <MenuItem value="Arabic">Arabic</MenuItem>
            </Select>
            {Language.trim() === "" && (
              <FormHelperText error>Please select a video language type.</FormHelperText>
            )}
          </FormControl>
          <FormControl fullWidth margin="normal" error={descrp.trim() === ""}>
            <TextField
              label="Description"
              value={descrp}
              onChange={(e) => setdescrp(e.target.value)}
              required
              id="filled-multiline-flexible"
              multiline
              maxRows={10}
              variant="filled"
            />
            {descrp.trim() === "" && (
              <FormHelperText error>Please enter the description</FormHelperText>
            )}
          </FormControl>
          <FormControl fullWidth margin="normal" error={File === null}>
            <input
              type="file"
              accept="video/"
              onChange={handleFileChange}
              required
            />
            {File === null && (
              <FormHelperText error>Please upload video (max 400MB).</FormHelperText>
            )}
          </FormControl>
          <Button variant="contained" color="primary" type="submit">
            Post
          </Button>
        </form>
    
  );
};

export default UplaodModuleForm;
