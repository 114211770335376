/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import { Avatar, Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Typography, IconButton,Button} from '@mui/material';
import { Search as SearchIcon, ArrowBack as ArrowBackIcon, ArrowForward as ArrowForwardIcon } from '@mui/icons-material';
import axios from 'axios';
import VerifiedIcon from '@mui/icons-material/Verified';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import baseUrl from '../../BaseURL';
import { ContextData } from '../../Context/Context';
import EditStudentPop from './PopDialogue/Student/EditStudentPop';


const UsersTable = () => {
  const [searchText, setSearchText] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [StudentData,setStudentData] = useState(null)
  const [CountData,setCountData] = useState(0)
  const rowsPerPage = 10;
   const {setLoader,AdministratorTokens} = useContext(ContextData)

  useEffect(()=>{
      

      async function FetchData(){
                setLoader(true)  

            try {
              const response = await axios.get(`${baseUrl}/auth/get_all_register_student`,{
                withCredentials: true,
                headers: {
                  'Accept' : 'application/json',
                  'authorization': `Bearer ${AdministratorTokens !== null && AdministratorTokens}`
                }}) 
              setCountData(Object.keys(response.data))
              setStudentData(response.data)
              setLoader(false)
             
            } catch (err) {
               if(err) console.log(err.message)
               setLoader(false)
            }

           
      }FetchData()


  },[])

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };



  
    const List =  Object.keys(StudentData !== null && StudentData).map(data=> StudentData[data])
    const filteredUsers = List.filter(user => {
    const lowerCaseSearchText = searchText.toLowerCase().trim();
   

    return (   
       `${user.fname} ${user.lname}`.toLowerCase().includes(lowerCaseSearchText) ||
      user.mobileNumber.toLowerCase().includes(lowerCaseSearchText) ||
      user.country.toLowerCase().includes(lowerCaseSearchText) ||
      user.email.toLowerCase().includes(lowerCaseSearchText) ||
      user.state_of_origin.toLowerCase().includes(lowerCaseSearchText)
      // Add more fields for search if needed (e.g., state, phone number)
    );
  });

  const totalPages = Math.ceil(filteredUsers.length / rowsPerPage);
  const indexOfLastUser = currentPage * rowsPerPage;
  const indexOfFirstUser = indexOfLastUser - rowsPerPage;
  const usersToShow = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

  const handleNextPage = () => {
    setCurrentPage(prevPage => prevPage + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage(prevPage => prevPage - 1);
  };

  const HanldeRemove = async (sttokens) =>{

    
    try {
      const response = await axios.delete(`${baseUrl}/auth/delete_student/${sttokens}`,{
        withCredentials: true,
        headers: {
          'Accept' : 'application/json',
          'authorization': `Bearer ${AdministratorTokens !== null && AdministratorTokens}`
        }})  

       } catch (err) {
       if(err) console.log(err.message)
       setLoader(false)
       }


  }



  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Students Table
      </Typography>
      <TextField
        label="Search"
        variant="outlined"
        value={searchText}
        onChange={handleSearchChange}
        InputProps={{
          endAdornment: (
            <IconButton>
              <SearchIcon />
            </IconButton>
          ),
        }}
        fullWidth
        margin="normal"
      />
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align='center'>Avatar</TableCell>
              <TableCell align='center'>Full Name</TableCell>
              <TableCell align='center'>Country</TableCell>
              <TableCell align='center'>State</TableCell>
              <TableCell align='center'>Email</TableCell>
              <TableCell align='center'>Number</TableCell>
              <TableCell align='center'>Verified</TableCell>
              <TableCell align='center'>Edit</TableCell>
              <TableCell align='center'>Remove user</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {usersToShow.map((user) => (
              <TableRow key={user.id}>
                <TableCell>
                  <Avatar sx={{boxShadow:'2px 2px 10px rgba(0,0,0,0.364)'}} alt={user.fname} src={`https://example.com/avatars/${user.id}.png`} />
                </TableCell>
                <TableCell align='center'>{user.fname} {user.lname}</TableCell>
                <TableCell align='center'>{user.country}</TableCell>
                <TableCell align='center'>{user.state_of_origin}</TableCell>
                <TableCell align='center'>{user.email}</TableCell>
                <TableCell align='center'>{user.mobileNumber}</TableCell>
                <TableCell align='center'>{user.varify ? <VerifiedIcon sx={{color:"green", fontSize:"2em"}}/> : <NewReleasesIcon sx={{color:"red", fontSize:"2em"}}/>}</TableCell>
           
                  <TableCell>

                   <EditStudentPop
                     data={user}
                   />
                  </TableCell>
 
                  <TableCell>
                <Button className='Hover-' onClick={()=> HanldeRemove(user.st_tokens)} elevation={10} sx={{whiteSpace: 'nowrap' , fontSize: '14px', backgroundColor:'rgb(199, 1, 1)', margin:'9px 0px'}}  size="large" variant="contained">
                   Remove
                 </Button>
                  </TableCell>
                  
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {totalPages > 1 && (
        <div>
          {currentPage > 1 && (
            <IconButton onClick={handlePrevPage}>
              <ArrowBackIcon />
            </IconButton>
          )}
          <span>Page {currentPage} of {totalPages}</span>
          {currentPage < totalPages && (
            <IconButton onClick={handleNextPage}>
              <ArrowForwardIcon />
            </IconButton>
          )}
        </div>
      )}
    </Container>
  );
};

export default UsersTable;
