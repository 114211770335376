/* eslint-disable react-hooks/exhaustive-deps */
import { Helmet } from 'react-helmet-async';
import { useState,useContext,useEffect} from 'react';
import { Container, Typography ,Stack} from '@mui/material';

import axios from 'axios';
// mock
import { ContextData } from '../Context/Context';
import baseUrl from '../BaseURL';
// components
import { ProgramSort, ProgramList } from '../sections/@dashboard/Programs';


// ----------------------------------------------------------------------

export default function CoursesPage() {

  

  const {setLoader,PersonKeys,ReloadData} = useContext(ContextData)
  const [ProgramData,setProgramData] = useState(null)


  useEffect(()=>{

    setLoader(true) 
    const ProductType = "Program"
    async function GetData(){   
      try {
           const response = await  axios.get(`${baseUrl}/client/get_all_posted_products_courses_programs_workshops/${ProductType}`,{
            withCredentials: true,
            headers: {
              'Accept' : 'application/json',
             'authorization': `Bearer ${PersonKeys!==null&&PersonKeys}`
            },
           })
           setProgramData(response.data)
         //  console.log(ProductDataList) 
           setLoader(false)
         
      } catch (error) {
          console.log(error.message)
          setLoader(false)
      }
    } 
    GetData()

},[ReloadData]) 

    
  return (

    <>
    
      <Helmet>
        <title> MastermindTech </title>
      </Helmet>
       
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
        <span style={{color:" rgb(0, 10, 53)"}}> MastermindTech available Programs </span>
        
        </Typography>
           

        <Stack direction="row" flexWrap="wrap-reverse" alignItems="center" justifyContent="flex-end" sx={{ mb: 5 }}>
          <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
            
            <ProgramSort />
          </Stack>
        </Stack>
        <ProgramList  data={ProgramData !== null && ProgramData} />
       
  
      </Container>
    </>
  );
}
