import React from 'react'

function Loading() {
  return (
    <div className='loadingCon '>

        <div className='inner-LoadingCon inner-LoadingCon-cpanel'>
              <img src='/assets/icons/loading.svg'  alt='loading...'/>
        </div>


    </div>
  ) 
}

export default Loading