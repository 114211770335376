/* eslint-disable react/jsx-key */
/* eslint-disable arrow-body-style */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState,useContext,useEffect } from "react";
import {
  Box,
} from "@mui/material";
import axios from "axios";
import { io } from 'socket.io-client';
import { ContextData } from "../../../../Context/Context";
import baseUrl from "../../../../BaseURL";
import Acc from "./Accordion";
import SubmitForm from "./SubmitForm";


const PostForm = () => {


  const {setLoader,DeleteProductStatus,AdministratorTokens,ReloadPostedProducts}= useContext(ContextData)
  const [ProductDataList,setProductDataList] = useState(null)

 
   // reload sockets.io when the ther
  const [ReloadData,setReloadData] = useState(true)
 // const socket = io(baseUrl)


 

/*   useEffect(()=>{


    socket.on("reupdate",(arg,callback)=>{
      setReloadData(!ReloadData)
     })


  },[socket])
 */
  


  useEffect(()=>{
    setLoader(true) 
    async function GetData(){   
      try {
           const response = await  axios.get(`${baseUrl}/auth/get_all_posted_products`,{
            withCredentials: true,
            headers: {
              'Accept' : 'application/json',
              'authorization': `Bearer ${AdministratorTokens!==null&&AdministratorTokens}`
            }})
           setProductDataList(response.data)
         //  console.log(ProductDataList)
           setLoader(false)
        
      } catch (error) {
          console.log(error.message)
          setLoader(false)
      }
    }
    GetData()

},[DeleteProductStatus,ReloadPostedProducts])

  return (
    <Box display="flex" justifyContent="flex-start" width="100%">
      <Box maxWidth="50%" height="100%" padding="1rem">
       <SubmitForm/>
      </Box>
      
      {/* Right section with accordion */}


      
    
      <Box maxWidth="50%" sx={{overflowY:'auto'}} height="50rem" padding="1rem">

            {
             
             ProductDataList !== null &&  Object.keys(ProductDataList).map((data,index)=>{
               //    console.log(ProductDataList) 
            return(
              <Acc
               data={ProductDataList[data]}
               keys={index}
              />
            )

            })
           } 

      </Box>

   
    </Box>
  );
};

export default PostForm;
