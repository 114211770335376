/* eslint-disable react/prop-types */
import React, { useState,useContext } from "react";
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  IconButton,
  Tooltip,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { ContextData } from "../../../../Context/Context";

import DeleteDialog from "./DeleteDialog";
import EditDialog from "./EditDialog";


const Acc = (props) => {


 

  const [accordionExpanded, setAccordionExpanded] = useState(false);
  const {popdelete,setpopdelete}= useContext(ContextData)
  

  const handleDelete = () => {
    setpopdelete(!popdelete)
  };

  const handleUpdate = () => {
    // Add update functionality here
    console.log("Update button clicked!");
  };



  return (
  

           <Accordion
              expanded={accordionExpanded}
              onChange={() => setAccordionExpanded(!accordionExpanded)}
            >
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography>{props.data.product_name}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box display="flex" alignItems="center">
                  <Typography>Product Type: {props.data.product_type}</Typography>
                  <Tooltip title="Delete Item from the system">
                  <IconButton onClick={handleDelete}>
                    <DeleteDialog
                    data={props.data}
                    />
                  </IconButton>
                  </Tooltip>
                  <Tooltip title={`Edit ${props.data.product_name} ${props.data.product_type} `}>
                  <IconButton onClick={handleUpdate}>
                   <EditDialog
                    data={props.data}
                   />
                  </IconButton>

                  </Tooltip>
                </Box>
              </AccordionDetails>


          {/*  {
             
             ProductDataList !== null &&  Object.keys(ProductDataList).map((data,index)=>{
                    
            return(
              
            )

            })
           } */}
    
            </Accordion>
  );
};

export default Acc;
