/* eslint-disable react/prop-types */
import * as React from 'react';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Stack from '@mui/material/Stack';

export default function AlertBox(props) {

    // status type
    // error
    // warning
    // info
    // success 
  return (
    <div className='alertbox-con'>

    <Stack sx={{ width: '100%',boxShadow:`0px 2px 10px rgba(0,0,0,0.845)` }} spacing={2}>
      <Alert severity={props.Status}>
        <AlertTitle>{props.Status}</AlertTitle>
         {props.textStatus}
      </Alert>
    </Stack> 

    </div>
  );
}