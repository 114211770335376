/* eslint-disable react/prop-types */
import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Edit } from '@mui/icons-material';
import SubmitText from './SubmitText';
import ViewAssessment from './ViewAssessment';
/* import SubmitForm from './SubmitForm';
import { ContextData } from "../../../../Context/Context";
import EditForm from './EditForm'; */

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function GiveAssessment(props) {

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>

      <Button sx={{color:"white",width:"240px",whiteSpace:"nowrap",marginBottom:"10px",backgroundColor: props.title === "Assmnt" ? "rgb(32, 4, 4)":"rgb(12, 12, 38)"}} variant="outlined" onClick={handleClickOpen}>
       {props.status} Task 
       {
        props.title === "Assmnt" && <Edit/>
       }
      </Button>

        <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
          >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
        {props.status} {""}
        </BootstrapDialogTitle>

        <DialogContent dividers>


          {

           props.title === "Assmnt" &&

           <SubmitText
             data={props.data}
           />
          
          }
        
            <div style={{width:"50%"}}>
             {
              
              props.title === "viewAssmnt" &&

              <ViewAssessment
                data={props.data}
                des={props.des}
              />

             }

            </div>
        
        </DialogContent>

        <DialogActions>

          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>

        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}

