import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

import { ToastContainer} from 'react-toastify';
import './App.css';
import './AMediaQueries.css';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import { StyledChart } from './components/chart';
import ScrollToTop from './components/scroll-to-top';
import Context from './Context/Context';



// ----------------------------------------------------------------------

export default function App() {
  return (
    
    <HelmetProvider>
      <BrowserRouter>
      <Context>
        <ThemeProvider>
       
        <ScrollToTop />
          <StyledChart />
          <ToastContainer position="top-center"/>
          <Router />
     
        </ThemeProvider>
        </Context>
      </BrowserRouter>
    </HelmetProvider>
  );
}
