/* eslint-disable no-unused-vars */
import * as React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import TelegramIcon from '@mui/icons-material/Telegram';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';


export default function SocialPages() {
  const [dense, setDense] = React.useState(false);
  const [secondary, setSecondary] = React.useState(false);

  return (
    <Box sx={{ flexGrow: 1, maxWidth: 752 }}>
      
      <List dense={dense}>

                <ListItem>
                  <ListItemAvatar>
                    <Avatar sx={{backgroundColor:'#229ED9'}}>
                      <TelegramIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="+234 913 383 3261"
                    secondary={secondary ? 'Secondary text' : null}
                  />
                </ListItem>

                <ListItem>
                  <ListItemAvatar>
                    <Avatar sx={{background:`blue`}}>
                      <FacebookIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="info@mmt-ng.com"
                    secondary={secondary ? 'Secondary text' : null}
                  />
                </ListItem>
                

                <ListItem>
                  <ListItemAvatar>
                    <Avatar sx={{backgroundColor:'#E1306C'}}>
                      <InstagramIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="+234 913 383 3261"
                    secondary={secondary ? 'Secondary text' : null}
                  />
                </ListItem>
            


            </List>
    </Box>
  )
}